import {
  CMS_DELETE_STOP_FAILURE,
  CMS_DELETE_STOP_REQUEST,
  CMS_DELETE_STOP_SUCCESS,
  CMS_GET_STOPS_FAILURE,
  CMS_GET_STOPS_REQUEST,
  CMS_GET_STOPS_SUCCESS,
  CMS_SAVE_STOP_FAILURE,
  CMS_SAVE_STOP_REQUEST,
  CMS_SAVE_STOP_SUCCESS,
  CMS_SET_EDITED_STOPS,
} from '../../constants/actionTypes'
import { IEditedItem, IStop } from '../../interfaces'
import { AxiosError, AxiosResponse } from 'axios'
import cmsAxios from './cmsNet'
import { ThunkDispatch } from 'redux-thunk'
import { AppState } from '../../reducers/rootReducer'
import { AnyAction } from 'redux'
import config from '../../config'
import { addNewError, setUiIsLoading } from '../ui'
const CMS_API_URL = config.CMS_API_URL

export const getStopsRequest = () => ({
  type: CMS_GET_STOPS_REQUEST,
})

export const getStopsSuccess = (stops: IStop[]) => ({
  type: CMS_GET_STOPS_SUCCESS,
  stops,
})

export const getStopsFailure = (error: AxiosError) => ({
  type: CMS_GET_STOPS_FAILURE,
  error,
})

export const getStops = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(getStopsRequest())
  dispatch(setUiIsLoading(true))

  return cmsAxios
    .get(`${CMS_API_URL}/stops`)
    .then((res: AxiosResponse<IStop[]>) => {
      dispatch(setUiIsLoading(false))
      return dispatch(getStopsSuccess(res.data))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(setUiIsLoading(false))
      return dispatch(getStopsFailure(err))
    })
}

export const getStopsIfNeeded = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>,
  getState: () => AppState
) => {
  const { cms } = getState()

  if (!cms.stops.isCached.cmsGetStops) {
    return dispatch(getStops())
  }
}

export const cmsSetEditedStops = (stops: IEditedItem<IStop>[] | null) => ({
  type: CMS_SET_EDITED_STOPS,
  stops,
})

export const cmsSaveStopRequest = () => ({
  type: CMS_SAVE_STOP_REQUEST,
})

export const cmsSaveStopSuccess = (stop: IStop) => ({
  type: CMS_SAVE_STOP_SUCCESS,
  stop,
})

export const cmsSaveStopFailure = (error: AxiosError) => ({
  type: CMS_SAVE_STOP_FAILURE,
  error,
})

export const cmsSaveStop = (stop: IStop, isNew: boolean) => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(cmsSaveStopRequest())
  dispatch(setUiIsLoading(true))

  const method = isNew ? 'post' : 'put'
  const url = isNew ? `${CMS_API_URL}/stops` : `${CMS_API_URL}/stops/${stop.id}`

  return cmsAxios[method](url, stop)
    .then((res: AxiosResponse<IStop>) => {
      dispatch(setUiIsLoading(false))

      if (!res.data.id) {
        throw new Error('Error saving stop')
      }

      return dispatch(cmsSaveStopSuccess(res.data))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(setUiIsLoading(false))
      return dispatch(cmsSaveStopFailure(err))
    })
}

export const cmsDeleteStopRequest = () => ({
  type: CMS_DELETE_STOP_REQUEST,
})

export const cmsDeleteStopSuccess = (stopId: string) => ({
  type: CMS_DELETE_STOP_SUCCESS,
  stopId,
})

export const cmsDeleteStopFailure = (error: AxiosError) => ({
  type: CMS_DELETE_STOP_FAILURE,
  error,
})

export const cmsDeleteStop = (stopId: string) => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(cmsDeleteStopRequest())
  dispatch(setUiIsLoading(true))

  return cmsAxios
    .delete(`${CMS_API_URL}/stops/${stopId}`)
    .then(() => {
      dispatch(cmsDeleteStopSuccess(stopId))
      dispatch(setUiIsLoading(false))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(cmsDeleteStopFailure(err))
      dispatch(setUiIsLoading(false))
      throw err
    })
}
