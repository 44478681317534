import axios, { AxiosError, AxiosResponse } from 'axios'
import {
  GET_STOPS_FOR_TOUR_REQUEST,
  GET_STOPS_FOR_TOUR_SUCCESS,
  GET_STOPS_FOR_TOUR_FAILURE,
  GET_KEYWORDS_FAILURE,
  GET_KEYWORDS_SUCCESS,
  GET_KEYWORDS_REQUEST,
  GET_STOPS_BY_KEYWORD_FAILURE,
  GET_STOPS_BY_KEYWORD_SUCCESS,
  GET_STOPS_BY_KEYWORD_REQUEST,
} from '../constants/actionTypes'
import { IStopWithRecord } from '../interfaces'
import { ThunkDispatch } from 'redux-thunk'
import { AppState } from '../reducers/rootReducer'
import { AnyAction } from 'redux'
import { addNewError } from './ui'

const API_URL = process.env.REACT_APP_API_URL || ''

// GET STOPS FOR TOUR
export const getStopsForTourRequest = () => ({
  type: GET_STOPS_FOR_TOUR_REQUEST,
})

export const getStopsForTourSuccess = ({
  stops,
  tourId,
}: {
  stops: IStopWithRecord[]
  tourId: string
}) => ({
  type: GET_STOPS_FOR_TOUR_SUCCESS,
  stops,
  tourId,
})

export const getStopsForTourFailure = (error: AxiosError) => ({
  type: GET_STOPS_FOR_TOUR_FAILURE,
  error,
})

export const getStopsForTour = (tourId: string) => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(getStopsForTourRequest())

  axios
    .get(`${API_URL}/tours/${tourId}/stops`)
    .then((res: AxiosResponse<IStopWithRecord[]>) => {
      dispatch(getStopsForTourSuccess({ stops: res.data, tourId }))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(getStopsForTourFailure(err))
    })
}

export const getStopsForTourIfNeeded = (tourId: string) => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>,
  getState: () => AppState
) => {
  const { entities } = getState()

  if (
    !entities.stops.isCached.getStopsForTour ||
    !entities.stops.isCached.getStopsForTour[tourId]
  ) {
    dispatch(getStopsForTour(tourId))
  }
}

// GET KEYWORDS
export const getKeywordsRequest = () => ({
  type: GET_KEYWORDS_REQUEST,
})

export const getKeywordsSuccess = ({ keywords }: { keywords: string[] }) => ({
  type: GET_KEYWORDS_SUCCESS,
  keywords,
})

export const getKeywordsFailure = (error: AxiosError) => ({
  type: GET_KEYWORDS_FAILURE,
  error,
})

export const getKeywords = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(getKeywordsRequest())

  axios
    .get(`${API_URL}/keywords`)
    .then((res: AxiosResponse<string[]>) => {
      const keywords = res.data.sort().filter(k => k)
      dispatch(getKeywordsSuccess({ keywords }))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(getKeywordsFailure(err))
    })
}

// GET STOPS BY KEYWORD
export const getStopsByKeywordRequest = () => ({
  type: GET_STOPS_BY_KEYWORD_REQUEST,
})

export const getStopsByKeywordSuccess = ({
  stops,
  keyword,
}: {
  stops: IStopWithRecord[]
  keyword: string
}) => ({
  type: GET_STOPS_BY_KEYWORD_SUCCESS,
  stops,
  keyword,
})

export const getStopsByKeywordFailure = (error: AxiosError) => ({
  type: GET_STOPS_BY_KEYWORD_FAILURE,
  error,
})

export const getStopsByKeyword = (keyword: string) => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(getStopsByKeywordRequest())

  axios
    .get(`${API_URL}/stops/searchKeyword`, { params: { keyword } })
    .then((res: AxiosResponse<IStopWithRecord[]>) => {
      dispatch(getStopsByKeywordSuccess({ stops: res.data, keyword }))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(getStopsByKeywordFailure(err))
    })
}

export const getStopsByKeywordIfNeeded = (keyword: string) => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>,
  getState: () => AppState
) => {
  const { entities } = getState()

  if (
    !entities.stops.isCached.getStopsByKeyword ||
    !entities.stops.isCached.getStopsByKeyword[keyword]
  ) {
    dispatch(getStopsByKeyword(keyword))
  }
}
