import React, { ChangeEvent, useEffect, useMemo } from 'react'
import { AppState } from '../../reducers/rootReducer'
import { getRecordsIfNeeded } from '../../actions/records'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { IRecord } from '../../interfaces'
import { Box, Heading } from 'rebass'
import { Link } from 'react-router-dom'
import { TextField } from '@material-ui/core'
import { setCmsRecordsSearchTerm } from '../../actions/ui'
import { event, EventAction, EventCategory } from '../../tracking'
import debounce from 'lodash/debounce'

const debouncedTrack = debounce(searchTerm => {
  event({
    category: EventCategory.Record,
    action: EventAction.SearchTermsEntered,
    label: searchTerm,
  })
}, 1000)

interface IRecordsLandingPageProps extends RouteComponentProps {
  getRecords: () => void
  recordsById: Record<string, IRecord>
  setSearchTerm: (searchTerm: string) => void
  searchTerm: string
  recordsAreLoading: boolean
}
const RecordsLandingPage: React.FC<IRecordsLandingPageProps> = ({
  getRecords,
  recordsById,
  setSearchTerm,
  searchTerm,
  recordsAreLoading,
}) => {
  useEffect(() => {
    // This will get records only if we don't currently have them or if the
    // cache is invalidated.
    getRecords()
  }, [getRecords])

  const recordsArray = useMemo(() => {
    return Object.values(recordsById)
  }, [recordsById])

  const formattedRecordsArray = recordsArray
    .filter(r => {
      // const belongsToTour = r.Tours.length > 0
      const hasLatLong = !!r.Coordinates

      const isChosenInSearch = searchTerm
        ? r.Title.toLowerCase().includes(searchTerm.toLowerCase())
        : true

      return hasLatLong && isChosenInSearch
    })
    .sort((a, b) => (a.Title.toLowerCase() < b.Title.toLowerCase() ? -1 : 1))

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value

    debouncedTrack(searchTerm)

    setSearchTerm(searchTerm)
  }

  if (recordsAreLoading) {
    return <Box m={3}>Loading...</Box>
  }

  return (
    <Box p={3}>
      <Heading fontSize={[4]} mb={4}>
        Records
      </Heading>

      <Box mb={4}>
        <TextField
          margin="dense"
          variant="outlined"
          fullWidth={true}
          value={searchTerm}
          onChange={handleSearchChange}
          placeholder="Search by title..."
        />
      </Box>

      {formattedRecordsArray.length > 0 ? (
        formattedRecordsArray.map(r => (
          <Box key={r.id} mb={3}>
            <Link to={`/records/${r.slug}`}>{r.Title}</Link>
          </Box>
        ))
      ) : (
        <Box>No records found...</Box>
      )}
    </Box>
  )
}

const mapStateToProps = (state: AppState) => ({
  recordsById: state.entities.records.byId,
  recordsAreLoading: state.entities.records.isLoading,
  searchTerm: state.ui.cmsRecordsSearchTerm,
})

const mapDispatchToProps = {
  getRecords: getRecordsIfNeeded,
  setSearchTerm: setCmsRecordsSearchTerm,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RecordsLandingPage))
