import { AnyAction } from 'redux'
import { IFeaturedAndSocialState } from '../interfaces'
import {
  GET_FEATURED_TOURS_FAILURE,
  GET_FEATURED_TOURS_REQUEST,
  GET_FEATURED_TOURS_SUCCESS,
  GET_SOCIAL_LINKS_FAILURE,
  GET_SOCIAL_LINKS_REQUEST,
  GET_SOCIAL_LINKS_SUCCESS,
  SAVE_FEATURED_TOURS_FAILURE,
  SAVE_FEATURED_TOURS_REQUEST,
  SAVE_FEATURED_TOURS_SUCCESS,
  SAVE_SOCIAL_LINKS_FAILURE,
  SAVE_SOCIAL_LINKS_REQUEST,
  SAVE_SOCIAL_LINKS_SUCCESS,
} from '../constants/actionTypes'

const initialState: IFeaturedAndSocialState = {
  featuredTours: { featuredTours: [] },
  socialLinks: null,
  isLoading: false,
  isCached: {},
}

export const featuredAndSocialReducer = (
  state = initialState,
  action: AnyAction
): IFeaturedAndSocialState => {
  switch (action.type) {
    // FEATURED TOURS
    case GET_FEATURED_TOURS_REQUEST:
      return { ...state, isLoading: true }
    case GET_FEATURED_TOURS_SUCCESS:
      return {
        ...state,
        featuredTours: action.featuredTours,
        isLoading: false,
        isCached: { ...state.isCached, getFeaturedTours: true },
      }
    case GET_FEATURED_TOURS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case SAVE_FEATURED_TOURS_REQUEST:
      return { ...state, isLoading: true }
    case SAVE_FEATURED_TOURS_SUCCESS:
      return {
        ...state,
        featuredTours: action.featuredTours,
        isLoading: false,
      }
    case SAVE_FEATURED_TOURS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    // SOCIAL LINKS
    case GET_SOCIAL_LINKS_REQUEST:
      return { ...state, isLoading: true }
    case GET_SOCIAL_LINKS_SUCCESS:
      return {
        ...state,
        socialLinks: action.socialLinks,
        isLoading: false,
        isCached: { ...state.isCached, getSocialLinks: true },
      }
    case GET_SOCIAL_LINKS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case SAVE_SOCIAL_LINKS_REQUEST:
      return { ...state, isLoading: true }
    case SAVE_SOCIAL_LINKS_SUCCESS:
      return {
        ...state,
        socialLinks: action.socialLinks,
        isLoading: false,
      }
    case SAVE_SOCIAL_LINKS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
