import React, { useState } from 'react'
import { Box, Flex, Heading, Text } from 'rebass'
import { RouteComponentProps, withRouter } from 'react-router'

import { ITour, IStop } from '../../../interfaces'
import { event, EventAction, EventCategory } from '../../../tracking'

import Legal from './Legal'

import withViewMore from '../../common/withViewMore'
import Button from '../../common/Button'
import NSModal from '../../common/NSModal'

interface ITourStopProps extends RouteComponentProps {
  tour: ITour
  stop: IStop
  onNext: () => void
  onLegalAccepted: () => void
  legalWasAccepted: () => boolean
}
const TourStop: React.FC<ITourStopProps> = ({
  tour,
  stop,
  onNext,
  onLegalAccepted,
  legalWasAccepted,
}) => {
  const currentStopIndex = tour.stopIds.indexOf(stop.id)
  const isFirstStop = currentStopIndex === 0
  const isLastStop = currentStopIndex === tour.stopIds.length - 1

  // if first stop, don't show legal
  // if not first stop, show legal if it was not accepted previously
  const walkingModalInitialState = !isFirstStop && !legalWasAccepted()
  const [showLegalModal, setShowLegalModal] = useState(walkingModalInitialState)

  const handleNextStopClick = () => {
    onNext()
  }

  if (!isFirstStop && !showLegalModal && !legalWasAccepted()) {
    setShowLegalModal(true)
  }

  const handleCitationOpen = () => {
    event({
      category: EventCategory.Stop,
      action: EventAction.CitationCollapseOpened,
      label: stop.siteName,
    })
  }

  const handleIsModalOpen = (bool: boolean) => () => {
    setShowLegalModal(bool)
  }

  const handleLegalAccepted = () => {
    setShowLegalModal(false)
    onLegalAccepted()
  }

  const Copy = () => (
    <Box>
      <Text as="p" dangerouslySetInnerHTML={{ __html: stop.narrativeText }} />
    </Box>
  )
  const CopyWithViewMore = withViewMore(Copy, handleCitationOpen)

  return (
    <>
      {showLegalModal ? (
        <NSModal onClose={handleIsModalOpen(false)}>
          <Legal onAccept={handleLegalAccepted} />
        </NSModal>
      ) : (
        <Flex flexDirection="column" p={[3]} width={[1]}>
          <Heading mt={[3]} fontSize={[4]}>
            {stop.siteName}
          </Heading>

          <Text mb={[3]} fontSize={0} color="lightGrey">
            Stop {currentStopIndex + 1} of {tour.stopIds.length}
          </Text>

          <CopyWithViewMore />

          {!isLastStop && (
            <Button
              alignSelf="center"
              mt={[4]}
              mb={[2]}
              variant="primary"
              onClick={handleNextStopClick}
            >
              Go To Next Stop
            </Button>
          )}
        </Flex>
      )}
    </>
  )
}

export default withRouter(TourStop)
