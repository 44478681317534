import React, { ChangeEvent, useEffect } from 'react'
import Button from '../../common/Button'
import { Box, Flex, Heading } from 'rebass'
import { IRecord } from '../../../interfaces'
import { MenuItem, OutlinedInput, Select, TextField } from '@material-ui/core'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { AppState } from '../../../reducers/rootReducer'
import {
  cmsGetRecordsIfNeeded,
  cmsSetSelectedRecordtype,
} from '../../../actions/cms'
import sortBy from 'lodash/sortBy'
import Loading from '../../common/Loading'
import { IOption } from '../../../helpers/compileSelectOptions'
import { setCmsRecordsSearchTerm } from '../../../actions/ui'

interface IAdminRecords extends RouteComponentProps {
  recordsById: Record<string, IRecord>
  getRecords: () => void
  isLoading: boolean
  options: Partial<Record<keyof IRecord, IOption[]>> | null
  selectedRecordType?: string
  setSelectedRecordType: (recordType: string) => void
  setSearchTerm: (searchTerm: string) => void
  searchTerm: string
}
const AdminRecords: React.FC<IAdminRecords> = ({
  recordsById,
  history,
  getRecords,
  isLoading,
  options,
  selectedRecordType,
  setSelectedRecordType,
  setSearchTerm,
  searchTerm,
}) => {
  useEffect(() => {
    getRecords()
  }, [getRecords])

  const records = sortBy(Object.values(recordsById), 'Title').filter(r => {
    const isChosenInDropdown =
      selectedRecordType === 'All' ? true : selectedRecordType === r.Type
    // All this falsy checking shouldn't be necessary due to TS enforcement,
    // but putting this here to fix MDB-177, which I was unable to reproduce.
    const isChosenInSearch = searchTerm
      ? r.Title &&
        r.Title.toLowerCase().includes(searchTerm && searchTerm.toLowerCase())
      : true

    return isChosenInDropdown && isChosenInSearch
  })

  const handleSelectedTypeChange = (e: ChangeEvent<{ value: unknown }>) => {
    setSelectedRecordType(e.target.value as string)
  }

  const handleAddRecord = () => {
    history.push('/admin/records/new')
  }

  const handleEdit = (id: string) => () => {
    history.push('/admin/records/edit/' + id)
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value)
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <Flex justifyContent="space-between">
        <Button variant="primary" onClick={handleAddRecord}>
          Add Record
        </Button>

        {options && options.Type && (
          <Box width={150}>
            <Select
              margin="dense"
              fullWidth={true}
              value={selectedRecordType || ''}
              input={<OutlinedInput labelWidth={0} />}
              onChange={handleSelectedTypeChange}
            >
              <MenuItem value="All">All</MenuItem>
              {options.Type.map(type => (
                <MenuItem value={type.value} key={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
      </Flex>

      <Heading mt={4} mb={3} fontSize={4}>
        Records
      </Heading>

      <TextField
        margin="dense"
        variant="outlined"
        fullWidth={true}
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Search by title..."
      />

      {records.map(r => (
        <Box py={3} key={r.id}>
          <Heading fontSize={2} fontWeight={600} mb={2}>
            {r.Title}
          </Heading>
          <Button onClick={handleEdit(r.id)} variant="outline" color="blue">
            Edit
          </Button>
        </Box>
      ))}
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  recordsById: state.cms.records.byId,
  isLoading: state.cms.records.isLoading,
  options: state.cms.records.options,
  selectedRecordType: state.cms.records.selectedRecordType,
  searchTerm: state.ui.cmsRecordsSearchTerm,
})

const mapDispatchToProps = {
  getRecords: cmsGetRecordsIfNeeded,
  setSelectedRecordType: cmsSetSelectedRecordtype,
  setSearchTerm: setCmsRecordsSearchTerm,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminRecords))
