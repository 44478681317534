import {
  GET_RECORDS_FAILURE,
  GET_RECORDS_REQUEST,
  GET_RECORDS_SUCCESS,
} from '../constants/actionTypes'
import { IRecord } from '../interfaces'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { ThunkDispatch } from 'redux-thunk'
import { AppState } from '../reducers/rootReducer'
import { AnyAction } from 'redux'
import config from '../config'
import { addNewError } from './ui'

const API_URL = config.API_URL

//export const getRecordRequest = () => ({
//type: 'GET_RECORD_REQUEST'
//})

//export const getRecordSuccess = (record: IRecord) => ({
//type: 'GET_RECORD_SUCCESS',
//record
//})

//export const getRecordFailure = (error: AxiosError) => ({
//type: 'GET_RECORD_FAILURE',
//error
//})

//export const getRecord = (recordId: string) => (
//dispatch: ThunkDispatch<AppState, null, AnyAction>
//) => {
//dispatch(getRecordRequest())

//axios
//.get(`${API_URL}/record/${recordId}`)
//.then((res: AxiosResponse<IRecord>) => {
//const record = res.data || null
//dispatch(getRecordSuccess(record))
//})
//.catch((err: AxiosError) => {
//dispatch(addNewError(err))
//dispatch(getRecordFailure(err))
//throw err
//})
//}

export const getRecordsRequest = () => ({
  type: GET_RECORDS_REQUEST,
})

export const getRecordsSuccess = (records: IRecord[]) => ({
  type: GET_RECORDS_SUCCESS,
  records,
})

export const getRecordsFailure = (error: AxiosError) => ({
  type: GET_RECORDS_FAILURE,
  error,
})

export const getRecords = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(getRecordsRequest())

  axios
    .get(`${API_URL}/records`)
    .then((res: AxiosResponse<IRecord[]>) => {
      const records = res.data || []
      dispatch(getRecordsSuccess(records))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(getRecordsFailure(err))
      throw err
    })
}

export const getRecordsIfNeeded = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>,
  getState: () => AppState
) => {
  const { entities } = getState()

  if (!entities.records.isCached.getRecords) {
    dispatch(getRecords())
  }
}
