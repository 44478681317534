import { AnyAction } from 'redux'
import { IQRCodesState } from '../../interfaces'
import {
  CMS_DELETE_QR_CODE_FAILURE,
  CMS_DELETE_QR_CODE_REQUEST,
  CMS_DELETE_QR_CODE_SUCCESS,
  CMS_GET_QR_CODES_FAILURE,
  CMS_GET_QR_CODES_REQUEST,
  CMS_GET_QR_CODES_SUCCESS,
  CMS_SAVE_QR_CODE_FAILURE,
  CMS_SAVE_QR_CODE_REQUEST,
  CMS_SAVE_QR_CODE_SUCCESS,
} from '../../constants/actionTypes'
import normalizeEntities from '../../helpers/normalizeEntities'

const initialState: IQRCodesState = {
  byId: {},
  byStopId: {},
  allIds: [],
  isLoading: false,
  isCached: {},
}

export const cmsQRCodesReducer = (
  state = initialState,
  action: AnyAction
): IQRCodesState => {
  switch (action.type) {
    case CMS_GET_QR_CODES_REQUEST:
      return { ...state, isLoading: true }
    case CMS_GET_QR_CODES_SUCCESS:
      return {
        ...state,
        ...normalizeEntities(action.qrCodes),
        isLoading: false,
        isCached: { ...state.isCached, cmsGetQRCodes: true },
      }
    case CMS_GET_QR_CODES_FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    case CMS_SAVE_QR_CODE_REQUEST:
      return { ...state, isLoading: true }
    case CMS_SAVE_QR_CODE_SUCCESS:
      const qrCodesAfterPost = [...Object.values(state.byId), action.qrCode]
      return {
        ...state,
        ...normalizeEntities(qrCodesAfterPost),
        isLoading: false,
      }
    case CMS_SAVE_QR_CODE_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CMS_DELETE_QR_CODE_REQUEST:
      return { ...state, isLoading: true }
    case CMS_DELETE_QR_CODE_SUCCESS:
      const qrCodesAfterDelete = [...Object.values(state.byId)].filter(
        r => r.id !== action.qrCode.id
      )
      return {
        ...state,
        ...normalizeEntities(qrCodesAfterDelete),
        isLoading: false,
      }
    case CMS_DELETE_QR_CODE_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
