import React, { ChangeEvent, useState } from 'react'
import {
  IAudio,
  IEditedItem,
  IMedia,
  IRecord,
  IStop,
  MediaType,
} from '../../../interfaces'
import { Box, Flex, Heading } from 'rebass'
import { TextField } from '@material-ui/core'
import Button from '../../common/Button'
import MediaList from '../common/MediaList'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {
  changeMediaField,
  extractMediaFromRecord,
} from '../../../helpers/mediaRecordHelpers'
import RecordPicker from '../tours/RecordPicker'
import RequiredIndicator from '../common/RequiredIndicator'

interface IStopEditor {
  editedStop: IEditedItem<IStop>
  record: IRecord | null
  onStopEdit: (x: IEditedItem<IStop>) => void
  onStopDelete: (x: IEditedItem<IStop>) => void
}
const StopEditor: React.FC<IStopEditor> = ({
  editedStop,
  record,
  onStopEdit,
  onStopDelete,
}) => {
  // recordPickerConfig contains the function that will be called when the record
  // is chosen.
  const [recordPickerConfig, setRecordPickerConfig] = useState<null | {
    callback: (record: IRecord) => void
    type: MediaType
  }>(null)

  const stop = editedStop.item

  const handleDelete = () => {
    onStopDelete(editedStop)
  }

  const updateStop = (field: Partial<IStop>) => {
    const updatedStop = { ...editedStop, item: Object.assign(stop, field) }
    onStopEdit(updatedStop)
  }

  const changeField = (fieldName: keyof IStop) => (
    e: ChangeEvent<{ value: unknown }>
  ) => {
    updateStop({ [fieldName]: e.target.value as string })
  }

  const changeQuillField = (fieldName: keyof IStop) => (content: string) => {
    updateStop({ [fieldName]: content })
  }

  const changeImageField = (fieldName: keyof IStop) => (image: IMedia[]) => {
    updateStop({ [fieldName]: image })
  }

  const addToImageList = (image: IMedia[]) => {
    if (stop) {
      const newList = [...(stop.images || []), ...image]
      changeImageField('images')(newList)
    }
  }

  const changeAudioField = (fieldName: keyof IStop) => (audio: IAudio[]) => {
    updateStop({ [fieldName]: audio[0] })
  }

  const handleAddMedia = (
    type: MediaType,
    mediaExtractor: (record: IRecord) => IMedia[],
    changeFunction: (media: IMedia[]) => void
  ) => () => {
    setRecordPickerConfig({
      callback: changeMediaField(mediaExtractor, changeFunction),
      type,
    })
  }

  const handleChooseRecord = () => {
    setRecordPickerConfig({
      callback: (record: IRecord) => {
        updateStop({ recordId: record.id })
      },
      type: 'All',
    })
  }

  return (
    <>
      {!!recordPickerConfig && (
        <RecordPicker
          typeToPick={recordPickerConfig.type}
          onClose={() => setRecordPickerConfig(null)}
          onChooseRecord={recordPickerConfig.callback}
        />
      )}

      <Box pt={2} pb={5}>
        <Flex justifyContent="space-between">
          <Box width={[2 / 3, 1 / 2]}>
            <Flex>
              <Heading fontSize={2} fontWeight={600}>
                Name
              </Heading>
              <RequiredIndicator />
            </Flex>

            <TextField
              margin="dense"
              variant="outlined"
              fullWidth={true}
              value={stop.siteName || ''}
              onChange={changeField('siteName')}
            />

            <Flex>
              <Heading fontSize={2} fontWeight={600}>
                URL Slug
              </Heading>
              <RequiredIndicator />
            </Flex>

            <TextField
              margin="dense"
              variant="outlined"
              fullWidth={true}
              value={stop.slug || ''}
              onChange={changeField('slug')}
            />
          </Box>

          <Button
            ml={3}
            bg="red"
            variant="primary"
            onClick={handleDelete}
            flex="0 0 auto"
            confirm={true}
          >
            Delete Stop
          </Button>
        </Flex>

        <Flex mt={4} mb={1} alignItems="center">
          <Heading fontSize={2} fontWeight={600}>
            Images
          </Heading>
          <Box ml={3} fontSize={0} sx={{ fontStyle: 'italic' }}>
            The first image will be used as the cover image
          </Box>
        </Flex>
        <MediaList
          type="Image"
          media={stop.images || []}
          onChange={changeImageField('images')}
          onAddMedia={handleAddMedia(
            'Image',
            extractMediaFromRecord('Image'),
            addToImageList
          )}
        />

        <Flex mt={4} mb={2}>
          <Heading fontSize={2} fontWeight={600}>
            Audio
          </Heading>
          <RequiredIndicator />
        </Flex>

        <MediaList
          type="Audio"
          media={stop.audio ? [stop.audio] : []}
          onChange={changeAudioField('audio')}
          onAddMedia={handleAddMedia(
            'Audio',
            extractMediaFromRecord('Audio'),
            changeAudioField('audio')
          )}
        />

        <Box>
          <Flex mt={4} mb={1}>
            <Heading fontSize={2} fontWeight={600}>
              Narrative Text
            </Heading>
            <RequiredIndicator />
          </Flex>

          <ReactQuill
            value={stop.narrativeText || ''}
            onChange={changeQuillField('narrativeText')}
          />
        </Box>

        <Flex mt={4} mb={1}>
          <Heading fontSize={2} fontWeight={600}>
            Record
          </Heading>
          <RequiredIndicator />
        </Flex>

        {record && <Box mb={2}>{record.Title}</Box>}
        <Button variant="primary" onClick={handleChooseRecord} flex="0 0 auto">
          Choose Record
        </Button>

        {/* NOT AN MVP FEATURE */}
        {/*<Heading fontSize={2} fontWeight={600} mt={3} mb={1}>*/}
        {/*  Content*/}
        {/*</Heading>*/}
        {/*<ContentEditor*/}
        {/*  content={stop.content || []}*/}
        {/*  onContentEdit={handleSetContent}*/}
        {/*/>*/}
      </Box>
    </>
  )
}

export default StopEditor
