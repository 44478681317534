import { IStop } from '../interfaces'

const createPopupElement = (
  nextStop: IStop,
  clickHandler: (id: string) => void
): Node => {
  const container = document.createElement('div')
  container.style.textAlign = 'center'
  container.style.width = '120px'
  container.style.margin = '-10px -10px -15px' // To fill popup completely

  // Build image and container
  const imageContainer = document.createElement('div')
  // imageContainer.style.height = '120px'
  imageContainer.style.width = '100%'
  imageContainer.style.backgroundColor = '#333'

  const image = document.createElement('img')
  const imageSrc = nextStop.images[0] ? nextStop.images[0].path : ''
  image.setAttribute('src', imageSrc)
  image.style.maxHeight = '100%'
  image.style.maxWidth = '100%'
  image.style.margin = 'auto'
  image.style.display = 'block'

  // imageContainer.appendChild(image)

  // Build content and container
  const contentContainer = document.createElement('div')
  contentContainer.style.width = '100%'
  contentContainer.style.padding = '10px'
  contentContainer.style.boxSizing = 'border-box'

  const siteName = document.createElement('h2')
  siteName.innerText = nextStop.siteName
  siteName.style.marginTop = '0'

  const button = document.createElement('button')
  button.style.backgroundColor = '#1F4695'
  button.style.width = '100px'
  button.style.height = '28px'
  button.style.borderRadius = '2px'
  button.style.color = 'white'
  button.style.fontFamily = 'Open Sans'
  button.style.fontWeight = 'bold'
  button.style.fontSize = '10px'
  button.innerText = 'Arrived'
  button.addEventListener('click', () => {
    clickHandler(nextStop.slug)
  })

  contentContainer.appendChild(siteName)
  contentContainer.appendChild(button)

  // Append containers to outer container
  container.appendChild(imageContainer)
  container.appendChild(contentContainer)

  return container
}

export default createPopupElement
