import {
  IEditedItem,
  IRecord,
  IUploadedFile,
  IUploadResult,
} from '../interfaces'
import { isRecord } from '../components/cms/helpers/validators'
import firebaseFileUpload from './firebaseFileUpload'

const addMediaToRecord = (
  record: Partial<IRecord>,
  uploadResults: IUploadResult[]
) => {
  const newRecord = { ...record }

  uploadResults.forEach(({ type, path }) => {
    switch (type) {
      case 'Image':
        newRecord.images = [
          ...(newRecord.images || []),
          { path, caption: record.Description || '' },
        ]
        break
      case 'Audio':
        newRecord.audios = [...(newRecord.audios || []), { path }]
        break
      case 'Video':
        newRecord.videos = [...(newRecord.videos || []), { path }]
    }
  })

  return newRecord
}

const setDescToCaption = (record: IRecord) => {
  if (!record.images || record.images.length === 0) {
    return record
  }

  const newImages = [...record.images]
  const firstImage = newImages[0]

  if (firstImage !== undefined) {
    firstImage.caption = record.Description || ''
    newImages[0] = firstImage
  }

  return { ...record, images: newImages }
}

export default (
  editedRecord: IEditedItem<IRecord> | null,
  mediaToUpload: IUploadedFile[],
  saveRecord: (x: IRecord, y: boolean) => Promise<void>
): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    if (!editedRecord) {
      reject()
      return
    }

    const recordToSave = editedRecord.item
    const isNew = editedRecord.meta.isNew

    // If there are any files to be uploaded
    if (mediaToUpload.length) {
      // Create an array of promises representing each upload
      const arrayOfMediaResults: Promise<IUploadResult>[] = mediaToUpload.map(
        m => firebaseFileUpload(m)
      )

      try {
        // Upload all media and get back a list of results that include the type and path
        const uploadResults: IUploadResult[] = await Promise.all(
          arrayOfMediaResults
        )

        // Add the uploaded media with paths to their respective arrays on the record
        const recordWithMedia = addMediaToRecord(recordToSave, uploadResults)

        // Validate and save the edited record
        if (isRecord(recordWithMedia)) {
          saveRecord(recordWithMedia, isNew).then(() => {
            resolve()
          })
        }
      } catch (err) {
        reject('Error uploading media: ' + err)
        return
      }
    } else {
      // Validate and save the edited record
      if (isRecord(recordToSave)) {
        // First, double-check that the first image has Description as its caption
        const recordWithCaption = setDescToCaption(recordToSave)

        saveRecord(recordWithCaption, isNew).then(() => {
          resolve()
        })
      }
    }
  })
}
