import React, { useEffect } from 'react'
import Button from '../../common/Button'
import { Box, Flex, Heading, Image } from 'rebass'
import { ITour } from '../../../interfaces'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { AppState } from '../../../reducers/rootReducer'
import sortBy from 'lodash/sortBy'
import Loading from '../../common/Loading'
import { cmsGetToursIfNeeded } from '../../../actions/cms'

interface IAdminTours extends RouteComponentProps {
  toursById: Record<string, ITour>
  getTours: () => void
  isLoading: boolean
}
const AdminTours: React.FC<IAdminTours> = ({
  toursById,
  getTours,
  isLoading,
  history,
}) => {
  useEffect(() => {
    getTours()
  }, [getTours])

  const tours = sortBy(Object.values(toursById), 'title')

  const handleAddTour = () => {
    history.push('/admin/tours/new')
  }

  const handleEdit = (id: string) => () => {
    history.push('/admin/tours/edit/' + id)
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <Button variant="primary" onClick={handleAddTour}>
        Add Tour
      </Button>

      <Heading mt={4} mb={3} fontSize={4}>
        Tours
      </Heading>

      <Flex flexWrap="wrap">
        {tours.map(r => {
          let imagePath =
            (r.images && r.images[0] && r.images[0].path) ||
            require('../../../assets/images/nashvilleVector.svg') // Placeholder

          return (
            <Box py={3} key={r.id} width={[1, 1 / 2, 1 / 3, 1 / 3]}>
              <Flex
                alignItems="center"
                justifyContent="center"
                style={{ width: 200, height: 200, background: '#333' }}
              >
                <Image
                  src={imagePath}
                  style={{
                    height: 'auto',
                    maxHeight: 200,
                    width: 'auto',
                    maxWidth: 200,
                  }}
                />
              </Flex>
              <Heading fontSize={2} fontWeight={600} mt={3} mb={2}>
                {r.title}
              </Heading>
              <Button onClick={handleEdit(r.id)} variant="outline" color="blue">
                Edit
              </Button>
            </Box>
          )
        })}
      </Flex>
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  toursById: state.cms.tours.byId,
  isLoading: state.cms.tours.isLoading,
})

const mapDispatchToProps = {
  getTours: cmsGetToursIfNeeded,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminTours))
