import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Flex, Heading, Image, Text, Box } from 'rebass'

import { getAboutUsContent } from '../actions/aboutUs'
import { AppState } from '../reducers/rootReducer'
import { IAboutUsContent } from '../interfaces'

import mainIcon from '../assets/icons/NashvilleSites_Final-high-res.png'

//import mary_ellen_headshot from '../assets/images/headshots/thumbnail_Mary Ellen Pethel_Headshot.jpg'
//import jessica_headshot from '../assets/images/headshots/thumbnail_Jessica Reeves_Headshot.jpg'
//import hayden_headshot from '../assets/images/headshots/thumbnail_Hayden Tumlin_Headshot.jpg'
//import tim_headshot from '../assets/images/headshots/thumbnail_Tim Walker_Headshot.jpg'
//import marley_headshot from '../assets/images/headshots/thumbnail_Marley.jpg'
//import kayleigh_headshot from '../assets/images/headshots/thumbnail_Kayleigh.jpg'

const AboutUs: React.FC<{
  aboutUsContent: IAboutUsContent
  getAboutUsContent: () => void
}> = ({ aboutUsContent, getAboutUsContent }) => {
  useEffect(() => {
    getAboutUsContent()
  }, [getAboutUsContent])

  return (
    <Flex
      flexDirection="column"
      px={[3]}
      pb={[0, 4]}
      pt={[0, 4]}
      mt={[0, 4]}
      width={[1, 1]}
      alignItems={['center']}
      as="main"
    >
      <Box width={[1, 600]}>
        <Flex flexDirection={['column']} justifyContent={['center']}>
          <Image width={[105]} src={mainIcon} alignSelf={['center']} />
        </Flex>
        <Heading py={[3]}>About us</Heading>
        <Text mb={[3, 4]}>
          A major new program funded and sponsored by the MHC Foundation,
          Nashville Sites focuses on incorporating scholarly research with
          historic sites in Nashville with delivery available on all devices:
          mobile, tablet, and desktop. The tours are pre-planned and based on
          themes, but participants have the opportunity to customize their
          experience based on their interests—providing an individualized
          adventure-style experience.
        </Text>

        <Heading
          color={['#555']}
          fontSize={[2]}
          fontWeight={600}
          pb={[1]}
          mt={[0, 4]}
        >
          Mission
        </Heading>
        <Text mb={[3, 4]}>
          NashvilleSites.org is sponsored by the Metro Historical Commission
          Foundation. The Metropolitan Historical Commission Foundation is an
          organization focused on history based on place, education, community
          outreach, and modernizing the ways in which the public can navigate
          local history in a mobile and interactive world.
        </Text>
        <Heading
          color={['#555']}
          fontSize={[2]}
          fontWeight={600}
          pb={[1]}
          mt={[0, 4]}
        >
          Purpose
        </Heading>
        <Text mb={[3, 4]}>
          There are over 150 metro markers, over 50 more state and nationally
          designated and recognized historic markers, sites, buildings, and
          districts in Metro Nashville. Points to be included for this project
          range from the earliest known business to the Ryman Auditorium to
          historical churches to government buildings. NashvilleSites.org will
          make the offline world more interesting and relevant by augmenting
          Nashville’s history with multimedia information and other online
          resources. This project is designed to provide an expansive range of
          walking, and ultimately driving, tours that guide the user throughout
          the city and provide credible and accessible information, images, and
          audio. As a city known to promote both tradition and progress, tours
          are based on a wide range of themes that focus on Nashville’s history
          and culture: celebrations and conflicts, accomplished women and men,
          landmarks and hidden histories. Let the journey begin.
        </Text>

        <Heading>Staff</Heading>

        {aboutUsContent.staff
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .map(staff => {
            const imageSrc = staff && staff.image && staff.image.src

            return (
              <Box
                key={staff.titleLine}
                mb={'3'}
                sx={{
                  clear: 'both',
                  '&::after': {
                    clear: 'both',
                    content: "''",
                    display: 'block',
                  },
                }}
              >
                <Heading
                  color={['#555']}
                  fontSize={[3]}
                  fontWeight={600}
                  mt={[4]}
                  mb={3}
                >
                  {staff.titleLine}
                </Heading>

                <Image
                  height="auto"
                  width={['400px', '50%']}
                  maxHeight={'100%'}
                  mx={['auto', 1 / 2]}
                  pb={'4'}
                  pr={'4'}
                  sx={{ float: ['none', 'left'], display: 'block' }}
                  src={imageSrc}
                />

                <Text color={['#555']} pb={[0, 4]}>
                  {staff.bio}
                </Text>
              </Box>
            )
          })}

        <Box mb={3}>
          <Heading
            color={['#555']}
            fontSize={[2]}
            fontWeight={600}
            pb={[2]}
            mt={[4]}
          >
            Educational Curriculum Team
          </Heading>

          {aboutUsContent.curriculumTeam.map(teamMember => {
            return (
              <Box key={teamMember.titleLine}>
                <Text color={['#555']} pb={[0, 2]}>
                  {teamMember.titleLine}
                </Text>
              </Box>
            )
          })}
        </Box>

        <Box>
          <Heading fontSize={[2, 4]} fontWeight={600} pb={[1, 2]} mt={[4]}>
            Contact us
          </Heading>

          <Text color={['#555']} pb={[0, 2]}>
            <strong>Phone: </strong>
            <a
              href={
                /* TODO extract method */
                `tel:${aboutUsContent.contactInfo.phone.replace(
                  /[^0-9]+/g,
                  ''
                )}`
              }
            >
              {aboutUsContent.contactInfo.phone}
            </a>
          </Text>

          <Text color={['#555']} pb={[0, 4]}>
            <strong>Email: </strong>
            <a href="mailto:info@nashvillesites.org">info@nashvillesites.org</a>
          </Text>

          {aboutUsContent.contactInfo.address.map(line => {
            return (
              <Box key={line}>
                <Text fontWeight={[300, 600]} color={['#555']}>
                  {line}
                </Text>
              </Box>
            )
          })}
        </Box>

        <Text mt={[0, 4]} mb={[3]}>
          The Metropolitan Historical Commission Foundation is a 501(c)(3)
          non-profit friends group which assists the Metro Historical Commission
          in its efforts to identify, protect, study, and interpret the rich
          history of Nashville.
        </Text>
        <Text mt={[0, 4]} mb={[4]} pb={[4]}>
          Do you know of a landmark, person, or site that the commission should
          add to our markers? <a href="mailto:info@nashvillesites.org">Email</a>{' '}
          us to submit your marker proposal.
        </Text>
      </Box>
    </Flex>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    aboutUsContent: state.content.aboutUs,
  }
}

const mapDispatchToProps = {
  getAboutUsContent,
}

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs)
