import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { Heading, Text, Image, Flex, Box } from 'rebass'
import { connect } from 'react-redux'

import { event, EventAction, EventCategory } from '../tracking'

import { AppState } from '../reducers/rootReducer'
import { getRecords } from '../actions/records'
import { IRecord, IToursState, ITour } from '../interfaces'
import { getTours } from '../actions/tours'

import { SET_RECORD_ID, SET_LANDING_TOURS } from '../constants/actionTypes'

import store from '../store'

import Button from './common/Button'

import logoHorizontal from '../assets/icons/nashville-sites-logo-horizontal.png'

interface IQRLandingPageProps
  extends RouteComponentProps<{
    recordId: string
  }> {
  record?: IRecord
  tour: ITour
  tours: IToursState
  getRecords: () => void
  getTours: () => void
  recordId: string
}

// TOURS FOR DEV
//
// see below for example of record on multiple tours
// http://localhost:3000/landing/walking-tours/5a2f0e0c-b18e-431b-a9db-999e72842d83
//
// record on single tour
// http://localhost:3000/landing/walking-tours/be4bcda7-347d-47b3-ac6c-7249fdb578e0

const QRLandingPage: React.FC<IQRLandingPageProps> = props => {
  const { record, recordId, tours, tour, getRecords, getTours } = props

  const handleContinueClick = () => {
    event({
      category: EventCategory.LandingPage,
      action: EventAction.ContinueClicked,
      label: 'QR Landing Page',
    })

    const url =
      record && record.Tours.length && record.Tours.length > 1
        ? `/landing/walking-tours/${recordId}/select-tour`
        : `/landing/walking-tours/tours/${props.tour.slug}`

    props.history.push(url)
  }

  const handleSeeRecordClick = () => {
    //event({
    //category: EventCategory.LandingPage,
    //action: EventAction.ContinueClicked,
    //label: 'QR Landing Page',
    //})

    const url = `/records/${recordId}`

    props.history.push(url)
  }

  // for single tour records, button is enabled if we have a record and a tour
  // for multi tour records, button is enabled if we have a record and more than one tourId on the record
  const buttonEnabled =
    (!!record && record.Tours && record.Tours.length > 1) || !!tour

  useEffect(() => {
    getTours()
  }, [getTours]) // getTours never changes, so why track it?

  useEffect(() => {
    getRecords()
  }, [getRecords])

  useEffect(() => {
    store.dispatch({
      type: SET_RECORD_ID,
      recordId,
    })
  }, [recordId])

  useEffect(() => {
    store.dispatch({
      type: SET_LANDING_TOURS,
      tours: record && record.Tours,
    })
  }, [record])

  useEffect(() => {
    const tour =
      record && record.Tours.length === 1 && tours.byId[record.Tours[0]]

    // if this record is tied to a stop that is only on a single tour, we set
    // the tour object here
    //
    // otherwise (more than one tour), we set the tour object based on the tour
    // the user selects on a subsequent screen (not set here)
    store.dispatch({
      type: 'SET_SELECTED_TOUR',
      selectedTour: tour || null,
    })
  }, [record, tours])

  return (
    <Flex
      px={[3]}
      pt={[0, 4]}
      pb={[6]}
      mt={[0, 4]}
      mb={[4]}
      flex={['1 1 0']}
      as="main"
      //style={{ position: 'relative' }}
      flexDirection="column"
      justifyContent="center"
    >
      <Box>
        <Image
          display="block"
          mx={'auto'}
          width={[290]}
          maxWidth={'100%'}
          src={logoHorizontal}
        />

        <Heading mt={[4]} textAlign={'center'}>
          Our story, your tour!
        </Heading>

        <Text textAlign={'center'} mt={[4]} mx="auto" fontSize={[2, 3]}>
          Explore Nashville with free walking and driving tours.
        </Text>

        <Button
          mx="auto"
          mt={[5]}
          height="64px"
          display="block"
          width={['100%']}
          maxWidth={['300px']}
          onClick={handleContinueClick}
          disabled={!buttonEnabled}
        >
          {!buttonEnabled ? 'Loading' : 'More Info'}
        </Button>

        <Button
          variant="outline"
          mx="auto"
          mt={[5]}
          height="64px"
          display="block"
          width={['100%']}
          maxWidth={['300px']}
          onClick={handleSeeRecordClick}
          disabled={false}
        >
          {'See Record'}
        </Button>
      </Box>
    </Flex>
  )
}

const mapStateToProps = (state: AppState, routeProps: IQRLandingPageProps) => {
  const recordId = routeProps.match.params.recordId
  const record = state.entities.records.byId[recordId]

  return {
    recordId,
    records: state.entities.records.allIds,
    record: record,
    tours: state.entities.tours,
    tour: state.landingPages.selectedTour,
  }
}

const mapDispatchToProps = {
  getRecords,
  getTours,
}

export default connect(mapStateToProps, mapDispatchToProps)(QRLandingPage)
