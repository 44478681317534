import { IToursState } from '../interfaces'
import {
  GET_TOURS_REQUEST,
  GET_TOURS_SUCCESS,
  GET_TOURS_FAILURE,
} from '../constants/actionTypes'
import { normalizeEntitiesWithSlug } from '../helpers/normalizeEntities'
import { AnyAction } from 'redux'

const initialState: IToursState = {
  byId: {},
  bySlug: {},
  allIds: [],
  isLoading: false,
  isCached: {
    getTours: false,
  },
}

const toursReducer = (state = initialState, action: AnyAction): IToursState => {
  switch (action.type) {
    case GET_TOURS_REQUEST:
      return { ...state, isLoading: true }
    case GET_TOURS_SUCCESS:
      return {
        ...state,
        ...normalizeEntitiesWithSlug(action.tours),
        isLoading: false,
        isCached: { ...state.isCached, getTours: true },
      }
    case GET_TOURS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default toursReducer
