export enum Direction {
  Up = -1,
  Down = 1,
}
export default <X>(
  origArr: X[],
  fromIndex: number,
  direction: Direction
): X[] => {
  const newImages = [...origArr]
  let toIndex = fromIndex + direction

  // Don't allow moving off the list
  if (toIndex < 1) {
    toIndex = 0
  } else if (toIndex >= origArr.length) {
    toIndex = origArr.length - 1
  }

  const stopToMove = origArr[fromIndex]
  newImages.splice(fromIndex, 1)
  newImages.splice(toIndex, 0, stopToMove)

  return newImages
}
