import React, { useState } from 'react'
import { Box, Button as RebassButton, ButtonProps, Flex, Heading } from 'rebass'

interface IButtonProps extends ButtonProps {
  disabled?: boolean
  confirm?: boolean
}
const Button: React.FC<IButtonProps> = props => {
  const [deferredClickHandler, setDeferredClickHandler] = useState<
    (() => void) | null
  >(null)

  const { onClick, ...otherProps } = props

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    // If we have a click handler on this component
    if (props.onClick != null) {
      const deferredClick = props.onClick

      // Either confirm first and then click
      if (props.confirm) {
        setDeferredClickHandler(() => () => {
          // @ts-ignore
          deferredClick(e)
        })

        // Or click it immediately
      } else {
        // @ts-ignore
        props.onClick(e)
      }
    }
  }

  const handleConfirmYes = () => {
    deferredClickHandler && deferredClickHandler()
  }

  const handleConfirmCancel = () => {
    setDeferredClickHandler(null)
  }

  return (
    <>
      {deferredClickHandler ? (
        <Box
          onClick={handleConfirmCancel}
          sx={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 3,
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            px={4}
            py={3}
            sx={{
              width: 450,
              borderRadius: 2,
              backgroundColor: '#ffffff',
              boxShadow: '0 6px 12px 0 #666666',
            }}
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <Heading mb={2} fontSize={3} fontWeight={600}>
              Are you sure?
            </Heading>

            <Box mb={3}>This action cannot be undone.</Box>

            <Flex justifyContent="flex-end">
              <RebassButton
                bg="red"
                variant="primary"
                onClick={handleConfirmYes}
              >
                Yes
              </RebassButton>

              <RebassButton
                ml={3}
                onClick={handleConfirmCancel}
                variant="outline"
                color="blue"
              >
                Cancel
              </RebassButton>
            </Flex>
          </Box>
        </Box>
      ) : null}

      <RebassButton
        sx={{
          height: 40,
          cursor: props.disabled ? 'not-allowed' : 'pointer',
          opacity: props.disabled ? 0.5 : 'inherit',
        }}
        onClick={handleClick}
        {...otherProps}
      >
        {props.children}
      </RebassButton>
    </>
  )
}

export default Button
