import React, { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Box } from 'rebass'

import { IRecord, ITour } from '../../../interfaces'
import { IStopWithRecord } from '../../../interfaces'

import { AppState } from '../../../reducers/rootReducer'

import { getStopsForTourIfNeeded } from '../../../actions/stops'
import { getToursIfNeeded } from '../../../actions/tours'

import TourContainer from './TourContainer'

interface ITourContainerDataProps
  extends RouteComponentProps<{ tourSlug: string; stopSlug?: string }> {
  isMapOpen: boolean
  allTours: { [Key: string]: ITour }
  tour?: ITour
  stopsForTour?: IStopWithRecord[]
  stop?: IStopWithRecord
  record?: IRecord
  toursForStop?: ITour[]
  // brought in via route
  virtual: boolean
  isLanding: boolean
  getToursIfNeeded: () => void
  getStopsForTourIfNeeded: (x: string) => void
}
const TourContainerData: React.FC<ITourContainerDataProps> = props => {
  const {
    allTours,
    tour,
    stopsForTour,
    stop,
    isMapOpen,
    getToursIfNeeded: getTours,
    getStopsForTourIfNeeded: getStopsForTour,
    virtual,
    isLanding,
  } = props
  const { stopSlug } = props.match.params
  // const stopId = props.match.params.stopId
  // tour should come in from route via slug
  const tourId = tour && tour.id

  useEffect(() => {
    getTours()
  }, [getTours])

  useEffect(() => {
    // needs to be UUID
    if (tourId !== undefined) {
      getStopsForTour(tourId)
    }
  }, [tourId, stopSlug, getStopsForTour])

  const _tour = tour

  return _tour && stopsForTour && stopsForTour.length ? (
    <TourContainer
      allTours={allTours}
      tour={_tour}
      stopsForTour={stopsForTour}
      stop={stop}
      virtual={virtual}
      isLanding={isLanding}
      isMapOpen={isMapOpen}
    />
  ) : (
    <Box height="100%" p={3}>
      Loading...
    </Box>
  )
}

const mapStateToProps = (
  state: AppState,
  routeProps: RouteComponentProps<{ tourSlug: string; stopSlug: string }>
) => {
  const tourSlug = routeProps.match.params.tourSlug
  const tour = state.entities.tours.bySlug[tourSlug]
  const tourId = tour && tour.id
  const stopsForTour = state.entities.stops.byTourId[tourId]

  const stopSlug = routeProps.match.params.stopSlug
  const stop = state.entities.stops.bySlug[stopSlug]

  return {
    allTours: state.entities.tours.byId,
    tour,
    stop,
    stopsForTour,
    isMapOpen: state.ui.isMapOpen,
  }
}

const mapDispatchToProps = {
  getToursIfNeeded,
  getStopsForTourIfNeeded,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TourContainerData))
