import { IStopWithRecord } from '../interfaces'

const getNextStop = (
  currentStopId: string,
  tourStops: IStopWithRecord[]
): IStopWithRecord | null => {
  const thisStopIndex = tourStops.findIndex(
    stopInList => stopInList.id === currentStopId
  )

  // If current stop is not found in tourStops or if we are on the last stop,
  // return null
  if (thisStopIndex === -1 || thisStopIndex === tourStops.length - 1) {
    return null
  }

  return tourStops[thisStopIndex + 1]
}

export default getNextStop
