import { IRecord } from '../interfaces'
import uniqBy from 'lodash/uniqBy'

export interface IOption {
  value: string
  label: string
}
export const convertToOption = (v: string): IOption => ({ value: v, label: v })

// Take an array of anything and return an array of IOptions for React Select
export const convertFieldDataToOptions = (
  fieldData: string[] | undefined
): IOption[] => {
  return fieldData ? fieldData.filter(d => !!d).map(convertToOption) : []
}

// Take an object of entities and an array of field names and return an object
// keyed by field name and containing the combined contents of that field on
// every entity.
export const createOptionsObj = <E>(entities: Record<string, E>) => (
  fieldNames: Partial<keyof E>[]
): Partial<Record<keyof E, IOption[]>> => {
  const optionsObj: Partial<Record<keyof E, IOption[]>> = {}

  // For each field we want to collect
  fieldNames.forEach(fieldName => {
    // Create a variable to old the options
    let optionsForField: IOption[] = []

    // Loop over all of the entities (IRecord, ITour, etc.)
    for (const id in entities) {
      if (entities.hasOwnProperty(id)) {
        const entity = entities[id]

        // Find the field in that entity that we want to collect
        const fieldData = entity[fieldName]

        // Convert the data in that field to the desired format.
        const formattedFieldData = Array.isArray(fieldData)
          ? convertFieldDataToOptions(fieldData)
          : typeof fieldData === 'string'
          ? convertToOption(fieldData)
          : []

        // Add the contents of that field to the optionsForField array
        optionsForField = optionsForField.concat(formattedFieldData)
      }
    }

    // Save the collected options to the optionsObj
    optionsObj[fieldName] = uniqBy(optionsForField, 'value').sort((a, b) => {
      return a.value.toLowerCase().localeCompare(b.value.toLowerCase())
    })
  })

  return optionsObj
}

export const compileOptionsFromRecords = (
  records: Record<string, IRecord>
): Partial<Record<keyof IRecord, IOption[]>> => {
  return createOptionsObj(records)([
    'Source',
    'Contributor',
    'Keyword',
    'Subject',
    'Type',
  ])
}
