import { AnyAction } from 'redux'

import {
  GET_ABOUT_US_CONTENT_FAILURE,
  GET_ABOUT_US_CONTENT_SUCCESS,
  GET_ABOUT_US_CONTENT_REQUEST,
} from '../constants/actionTypes'

// TODO write an interface for this
const initialState = {
  aboutUs: {
    contactInfo: {
      phone: '',
      email: '',
      address: [],
    },
    staff: [],
    curriculumTeam: [],
  },
}

export default (state = initialState, action: AnyAction): any => {
  switch (action.type) {
    case GET_ABOUT_US_CONTENT_REQUEST:
      return { ...state }
    case GET_ABOUT_US_CONTENT_FAILURE:
      return { ...state }
    case GET_ABOUT_US_CONTENT_SUCCESS:
      return {
        ...state,
        aboutUs: {
          ...action.aboutUsContent,
        },
      }
    default:
      return state
  }
}
