import { AnyAction } from 'redux'
import { ISessionState } from '../../interfaces'
import { LOG_IN, LOG_OUT } from '../../constants/actionTypes'

// comes out of localStorage as null or a string
const tokenVal = window.localStorage.getItem('userToken')
let hasSession: boolean

// so if null, can't JSON.parse
if (tokenVal) {
  // still might be null after parsing tho
  hasSession = !!JSON.parse(tokenVal)
} else {
  hasSession = false
}

const initialSessionState: ISessionState = {
  userHasSession: hasSession,
}

export const userSessionReducer = (
  state = initialSessionState,
  action: AnyAction
): ISessionState => {
  switch (action.type) {
    case LOG_IN:
      return { ...state, userHasSession: true }
    case LOG_OUT:
      return { ...state, userHasSession: false }
    default:
      return state
  }
}
