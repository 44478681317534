import { AnyAction } from 'redux'
import { IStopsState } from '../../interfaces'
import {
  CMS_DELETE_STOP_FAILURE,
  CMS_DELETE_STOP_REQUEST,
  CMS_DELETE_STOP_SUCCESS,
  CMS_GET_STOPS_FAILURE,
  CMS_GET_STOPS_REQUEST,
  CMS_GET_STOPS_SUCCESS,
  CMS_SAVE_STOP_FAILURE,
  CMS_SAVE_STOP_REQUEST,
  CMS_SAVE_STOP_SUCCESS,
} from '../../constants/actionTypes'
import { normalizeEntitiesWithSlug } from '../../helpers/normalizeEntities'

const initialState: IStopsState = {
  byId: {},
  bySlug: {},
  byKeyword: {},
  allIds: [],
  isLoading: false,
  isCached: {},
  keywords: [],
}

export const cmsStopsReducer = (
  state = initialState,
  action: AnyAction
): IStopsState => {
  switch (action.type) {
    case CMS_GET_STOPS_REQUEST:
      return { ...state, isLoading: true }
    case CMS_GET_STOPS_SUCCESS:
      return {
        ...state,
        ...normalizeEntitiesWithSlug(action.stops),
        isLoading: false,
        isCached: { ...state.isCached, cmsGetStops: true },
      }
    case CMS_GET_STOPS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CMS_SAVE_STOP_REQUEST:
      return { ...state, isLoading: true }
    case CMS_SAVE_STOP_SUCCESS:
      const stopsAfterPost = [...Object.values(state.byId), action.stop]
      return {
        ...state,
        ...normalizeEntitiesWithSlug(stopsAfterPost),
        isLoading: false,
      }
    case CMS_SAVE_STOP_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CMS_DELETE_STOP_REQUEST:
      return { ...state, isLoading: true }
    case CMS_DELETE_STOP_SUCCESS:
      const stopsAfterDelete = [...Object.values(state.byId)].filter(
        r => r.id !== action.stopId
      )
      return {
        ...state,
        ...normalizeEntitiesWithSlug(stopsAfterDelete),
        isLoading: false,
      }
    case CMS_DELETE_STOP_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
