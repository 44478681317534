import React, { ChangeEvent, useEffect, useState } from 'react'
import { Box, Flex, Heading } from 'rebass'
import Button from '../common/Button'

import { TextField, Select, MenuItem, OutlinedInput } from '@material-ui/core'
import { AppState } from '../../reducers/rootReducer'
import {
  cmsGetFeaturedToursIfNeeded,
  cmsGetSocialLinksIfNeeded,
  cmsGetToursIfNeeded,
  cmsSaveFeaturedTours,
  cmsSaveSocialLinks,
} from '../../actions/cms'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { IFeaturedTours, ISocialLinks, ITour } from '../../interfaces'
import sortBy from 'lodash/sortBy'
import isEqual from 'lodash/isEqual'
import Loading from '../common/Loading'
import { IOption } from '../../helpers/compileSelectOptions'

const findSelectedTourOptions = (
  featuredTours: IFeaturedTours,
  toursById: Record<string, ITour>
) => {
  return featuredTours.featuredTours.map(fId => {
    const foundOption = toursById[fId] || {}
    return {
      value: foundOption.id || '',
      label: foundOption.title || '',
    }
  })
}

interface IAdminTours extends RouteComponentProps {
  toursById: Record<string, ITour>
  getTours: () => void
  getFeaturedTours: () => void
  getSocialLinks: () => void
  isLoading: boolean
  featuredTours: IFeaturedTours
  socialLinks: ISocialLinks | null
  saveFeaturedTours: (x: IFeaturedTours) => void
  saveSocialLinks: (x: ISocialLinks) => void
}
const AdminHome: React.FC<IAdminTours> = ({
  toursById,
  getTours,
  getFeaturedTours,
  getSocialLinks,
  isLoading,
  featuredTours,
  socialLinks,
  saveFeaturedTours,
  saveSocialLinks,
}) => {
  const [tourOptions, setTourOptions] = useState<IOption[]>([])
  const [selectedTourOptions, setSelectedTourOptions] = useState<IOption[]>([])
  const [facebookLink, setFacebookLink] = useState('')
  const [twitterLink, setTwitterLink] = useState('')
  const [instagramLink, setInstagramLink] = useState('')
  const [tiktokLink, setTikTokLink] = useState('')

  useEffect(() => {
    console.info(
      'cms',
      'AdminHome',
      'fetching tours, featured tours, and social links'
    )
    getTours()
    getFeaturedTours()
    getSocialLinks()
  }, [getFeaturedTours, getSocialLinks, getTours])

  useEffect(() => {
    const tours = sortBy(Object.values(toursById), 'title')
    const tourOptions = tours.map(tour => ({
      value: tour.id,
      label: tour.title,
    }))
    const selectedTourOptions = findSelectedTourOptions(
      featuredTours,
      toursById
    )

    setTourOptions(tourOptions)
    setSelectedTourOptions(selectedTourOptions)
  }, [toursById, featuredTours])

  useEffect(() => {
    if (socialLinks) {
      setFacebookLink(socialLinks.facebook)
      setTwitterLink(socialLinks.twitter)
      setInstagramLink(socialLinks.instagram)
      setTikTokLink(socialLinks.tiktok)
    }
  }, [socialLinks])

  // This silly code just tells us if any of the fields have changed
  const savedValues = {
    selectedTourOptions: findSelectedTourOptions(featuredTours, toursById),
    facebookLink: socialLinks && socialLinks.facebook,
    twitterLink: socialLinks && socialLinks.twitter,
    instagramLink: socialLinks && socialLinks.instagram,
    tiktokLink: socialLinks && socialLinks.tiktok,
  }
  const savedValuesHaveChanged = !isEqual(savedValues, {
    selectedTourOptions,
    facebookLink,
    twitterLink,
    instagramLink,
  })

  const handleTourOptionSelect = (i: number) => (
    e: ChangeEvent<{ value: unknown }>
  ) => {
    const selectedTourOption = tourOptions.find(o => o.value === e.target.value)

    if (selectedTourOption) {
      const newOptions = [...selectedTourOptions]
      newOptions[i] = selectedTourOption
      setSelectedTourOptions(newOptions)
    }
  }

  const handleAddFeaturedTour = () => {
    setSelectedTourOptions([...selectedTourOptions, { value: '', label: '' }])
  }

  const handleTourRemove = (i: number) => () => {
    const newOptions = [...selectedTourOptions]
    newOptions.splice(i, 1)
    setSelectedTourOptions(newOptions)
  }

  const handleSave = () => {
    const newFeaturedTours: IFeaturedTours = {
      featuredTours: selectedTourOptions.map(o => o.value).filter(o => o),
    }

    const newSocialLinks: ISocialLinks = {
      facebook: facebookLink,
      twitter: twitterLink,
      instagram: instagramLink,
      tiktok: tiktokLink,
    }

    saveFeaturedTours(newFeaturedTours)
    saveSocialLinks(newSocialLinks)
  }

  const handleSocialChange = (changeFunction: Function) => (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    changeFunction(e.target.value)
  }

  // This just reverts local state back to its initial values
  const handleCancel = () => {
    setSelectedTourOptions(savedValues.selectedTourOptions)
    setFacebookLink(savedValues.facebookLink || '')
    setTwitterLink(savedValues.twitterLink || '')
    setInstagramLink(savedValues.instagramLink || '')
    setTikTokLink(savedValues.tiktokLink || '')
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <Box sx={{ maxWidth: 600 }}>
      <Heading mt={5} mb={3} fontSize={4}>
        Featured Tours
      </Heading>

      {selectedTourOptions.map((option, i: number) => {
        return (
          <Flex mb={3} key={i}>
            <Box flex="1 1 auto">
              <Select
                fullWidth={true}
                margin="dense"
                value={option.value}
                placeholder="Select a tour..."
                onChange={handleTourOptionSelect(i)}
                input={<OutlinedInput labelWidth={0} />}
              >
                {tourOptions.map(o => (
                  <MenuItem key={o.value} value={o.value}>
                    {o.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <Button
              ml={3}
              color="red"
              variant="outline"
              onClick={handleTourRemove(i)}
            >
              Remove
            </Button>
          </Flex>
        )
      })}

      <Button mb={4} variant="primary" onClick={handleAddFeaturedTour}>
        Add Featured Tour
      </Button>

      <Heading mt={4} mb={3} fontSize={4}>
        Social Media
      </Heading>

      <Heading fontSize={2} fontWeight={600} mt={3} mb={1}>
        Facebook
      </Heading>
      <TextField
        margin="dense"
        variant="outlined"
        fullWidth={true}
        value={facebookLink}
        onChange={handleSocialChange(setFacebookLink)}
      />

      <Heading fontSize={2} fontWeight={600} mt={3} mb={1}>
        Twitter
      </Heading>
      <TextField
        margin="dense"
        variant="outlined"
        fullWidth={true}
        value={twitterLink}
        onChange={handleSocialChange(setTwitterLink)}
      />

      <Heading fontSize={2} fontWeight={600} mt={3} mb={1}>
        Instagram
      </Heading>
      <TextField
        margin="dense"
        variant="outlined"
        fullWidth={true}
        value={instagramLink}
        onChange={handleSocialChange(setInstagramLink)}
      />

      <Heading fontSize={2} fontWeight={600} mt={3} mb={1}>
        TikTok
      </Heading>
      <TextField
        margin="dense"
        variant="outlined"
        fullWidth={true}
        value={tiktokLink}
        onChange={handleSocialChange(setTikTokLink)}
      />

      {savedValuesHaveChanged && (
        <Flex mt={4}>
          <Button onClick={handleCancel} variant="outline" color="blue">
            Cancel
          </Button>
          <Button ml={3} onClick={handleSave}>
            Save
          </Button>
        </Flex>
      )}
    </Box>
  )
}

const mapStateToProps = (state: AppState) => ({
  toursById: state.cms.tours.byId,
  isLoading: state.cms.tours.isLoading || state.cms.featuredAndSocial.isLoading,
  featuredTours: state.cms.featuredAndSocial.featuredTours,
  socialLinks: state.cms.featuredAndSocial.socialLinks,
})

const mapDispatchToProps = {
  getTours: cmsGetToursIfNeeded,
  getFeaturedTours: cmsGetFeaturedToursIfNeeded,
  getSocialLinks: cmsGetSocialLinksIfNeeded,
  saveFeaturedTours: cmsSaveFeaturedTours,
  saveSocialLinks: cmsSaveSocialLinks,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdminHome))
