import axios, { AxiosError, AxiosResponse } from 'axios'
import {
  GET_TOURS_REQUEST,
  GET_TOURS_SUCCESS,
  GET_TOURS_FAILURE,
  SET_INITIAL_STOP,
} from '../constants/actionTypes'
import { ITour, IStopWithRecord } from '../interfaces'
import { ThunkDispatch } from 'redux-thunk'
import { AppState } from '../reducers/rootReducer'
import { AnyAction } from 'redux'
import config from '../config'
import { addNewError } from './ui'

const API_URL = config.API_URL

export const getToursRequest = () => ({
  type: GET_TOURS_REQUEST,
})

export const getToursSuccess = (tours: ITour[]) => ({
  type: GET_TOURS_SUCCESS,
  tours,
})

export const getToursFailure = (error: AxiosError) => ({
  type: GET_TOURS_FAILURE,
  error,
})

export const getTours = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(getToursRequest())

  axios
    .get(`${API_URL}/tours`)
    .then((res: AxiosResponse<ITour[]>) => {
      dispatch(getToursSuccess(res.data))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(getToursFailure(err))
    })
}

export const getToursIfNeeded = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>,
  getState: () => AppState
) => {
  const { entities } = getState()

  if (!entities.tours.isCached.getTours) {
    dispatch(getTours())
  }
}

export const selectCurrentStopForLandingPage = (
  recordId: string,
  tour: ITour
) => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>,
  getState: () => AppState
) => {
  const { entities } = getState()

  const recordStopsIds = entities.stops.allIds.filter(stopId => {
    return entities.stops.byId[stopId].recordId === recordId
  })

  const currentStop = recordStopsIds.reduce(
    (acc: IStopWithRecord | null, stopId): IStopWithRecord | null => {
      return entities.stops.byId[stopId].tourId === tour.id
        ? entities.stops.byId[stopId]
        : null
    },
    null
  )

  dispatch({
    type: SET_INITIAL_STOP,
    initialStop: currentStop,
  })
}
