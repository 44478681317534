import React from 'react'
import { Flex, Link } from 'rebass'
import { Link as RouterLink } from 'react-router-dom'
import { ITour } from '../../../interfaces'
import { RouteComponentProps, withRouter } from 'react-router'

interface IOtherToursProps extends RouteComponentProps {
  tours: ITour[]
}
const OtherTours: React.FC<IOtherToursProps> = props => {
  return (
    <Flex p={[3]} flexDirection="column" width={[1]}>
      {props.tours.map((tour, i) => {
        return (
          <Link mb={3} color="blue" key={i} as="div">
            <RouterLink to={`/tours/${tour.id}`}>{tour.title}</RouterLink>
          </Link>
        )
      })}
    </Flex>
  )
}

export default withRouter(OtherTours)
