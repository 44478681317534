import React, { useEffect } from 'react'
import { IEditedItem, IStop, ITour } from '../../../interfaces'
import { connect } from 'react-redux'
import { AppState } from '../../../reducers/rootReducer'
import Loading from '../../common/Loading'
import {
  cmsDeleteStop,
  cmsDeleteTour,
  cmsGetToursIfNeeded,
  cmsSaveEditedTour,
  cmsSetEditedStops,
  cmsSetEditedTour,
} from '../../../actions/cms'
import { RouteComponentProps, withRouter } from 'react-router'
import initNewTour from '../../../helpers/initNewTour'
import { Box } from 'rebass'
import AdminTour from './AdminTour'

interface IGetTourData extends RouteComponentProps<{ id: string }> {
  toursById: Record<string, ITour>
  editedTour: IEditedItem<ITour> | null
  getTours: () => void
  setEditedTour: (tour: IEditedItem<ITour> | null) => void
  setEditedStops: (stops: IEditedItem<IStop>[] | null) => void
  isLoading: boolean
  saveEditedTour: () => Promise<void>
  deleteTour: (record: Partial<ITour>) => Promise<void>
  deleteStop: (stopId: string) => Promise<void>
}
const GetTourData: React.FC<IGetTourData> = ({
  toursById,
  editedTour,
  getTours,
  setEditedTour,
  setEditedStops,
  location,
  match,
  isLoading,
  saveEditedTour,
  deleteTour,
  history,
  deleteStop,
}) => {
  const isNew = location.pathname.includes('new')
  const isEdit = location.pathname.includes('edit')
  const editedId = match.params.id

  useEffect(() => {
    // This will get records only if we don't currently have them or if the
    // cache is invalidated.
    getTours()
  }, [getTours])

  useEffect(() => {
    const tourToEdit = isNew ? initNewTour() : toursById[editedId]

    if (tourToEdit) {
      setEditedTour({ item: tourToEdit, meta: { isNew } })
    }

    return () => {
      setEditedTour(null)
      setEditedStops(null)
    }
  }, [isNew, editedId, toursById, setEditedTour, setEditedStops])

  const handleTourUpdate = (tour: Partial<ITour>) => {
    setEditedTour({ item: tour, meta: { isNew } })
  }

  const onDelete = async () => {
    if (isNew) {
      backToTours()
    } else if (isEdit && editedTour) {
      try {
        // Before we can delete the tour we have to delete all the stops on the tour.
        if (editedTour.item.stopIds) {
          const stopDeleteDispatches = editedTour.item.stopIds.map(stopId =>
            deleteStop(stopId)
          )

          await Promise.all([stopDeleteDispatches])
        }

        await deleteTour(editedTour.item)

        backToTours()
      } catch (err) {
        console.log('Error deleting tour:', err)
      }
    }
  }

  const onSave = () => {
    saveEditedTour()
      .then(() => {
        backToTours()
      })
      .catch(err => {
        console.error(err)
      })
  }

  const backToTours = () => {
    history.push('/admin/tours')
  }

  if (isLoading) {
    return <Loading />
  }

  return editedTour ? (
    <AdminTour
      tour={editedTour.item}
      onDelete={onDelete}
      onSave={onSave}
      onCancel={backToTours}
      onTourUpdate={handleTourUpdate}
      isLoading={isLoading}
    />
  ) : (
    <Box>No tour found...</Box>
  )
}

const mapStateToProps = (state: AppState) => ({
  toursById: state.cms.tours.byId,
  editedTour: state.cms.editedTour,
  isLoading:
    state.cms.tours.isLoading ||
    state.cms.stops.isLoading ||
    state.ui.isLoading,
})

const mapDispatchToProps = {
  setEditedTour: cmsSetEditedTour,
  setEditedStops: cmsSetEditedStops,
  getTours: cmsGetToursIfNeeded,
  saveEditedTour: cmsSaveEditedTour,
  deleteTour: cmsDeleteTour,
  deleteStop: cmsDeleteStop,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GetTourData))
