import React, { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { AppState } from '../../reducers/rootReducer'
import { getKeywords } from '../../actions/stops'
import Select from 'react-select'
import formatKeywords from '../../helpers/formatKeywords'
import { ISelectOption } from '../../interfaces'
import { Box } from 'rebass'
import { Search as SearchIcon } from '@material-ui/icons'
import { toggleSearch } from '../../actions/ui'
import { ValueType } from 'react-select/src/types'

interface ISearchBarProps extends RouteComponentProps {
  getKeywords: () => void
  toggleSearch: () => void
  keywords: string[]
}
const SearchBar: React.FC<ISearchBarProps> = props => {
  const { keywords, getKeywords } = props

  useEffect(() => {
    getKeywords()
  }, [getKeywords])

  const options: ISelectOption[] = formatKeywords(keywords)

  const handleKeywordSelect = (keyword: ValueType<ISelectOption>) => {
    if (keyword) {
      props.history.push(`/search/?keyword=${(keyword as ISelectOption).value}`)
    }
  }

  return (
    <>
      <Box flex="1 1 0" mr={[2]}>
        <Select
          options={options}
          placeholder="Search keywords..."
          onChange={handleKeywordSelect}
        />
      </Box>
      <SearchIcon
        fontSize="large"
        onClick={props.toggleSearch}
        cursor="pointer"
      />
    </>
  )
}

export default connect(
  (state: AppState) => ({
    keywords: state.entities.stops.keywords,
  }),
  {
    getKeywords,
    toggleSearch,
  }
)(withRouter(SearchBar))
