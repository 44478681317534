import React from 'react'
import { connect } from 'react-redux'
import { Menu, Search as SearchIcon, Place } from '@material-ui/icons'
import { Box, Flex, Image } from 'rebass'
import { RouteComponentProps, withRouter } from 'react-router'
import { openMap, toggleSideDrawer, toggleSearch } from '../../actions/ui'
import icon from '../../assets/icons/nashville-sites-icon.png'
import { AppState } from '../../reducers/rootReducer'
import { AxiosError } from 'axios'
import styled from 'styled-components'
import SearchBar from '../search/SearchBar'
import { event, EventAction, EventCategory } from '../../tracking'

const ErrorBanner = styled(Flex)`
  background-color: #d73b36;
  color: white;
`

interface IHeaderProps extends RouteComponentProps {
  toggleSideDrawer: () => void
  openMap: (isMapOpen: boolean) => void
  toggleSearch: () => void
  isStopMode: boolean
  isSearchOpen: boolean
  errors: (Error | AxiosError)[]
}
const Header: React.FC<IHeaderProps> = props => {
  const path = props.location.pathname
  const showIcon =
    path !== '/' && path !== '/about-us' && path !== '/our-sponsors'

  const handleMenuClick = () => {
    event({
      category: EventCategory.Nav,
      action: EventAction.NavOpened,
    })

    props.toggleSideDrawer()
  }

  const handleSearchIconClick = () => {
    event({
      category: EventCategory.Search,
      action: EventAction.SearchClicked,
    })

    props.toggleSearch()
  }

  return (
    <>
      <Flex
        bg="white"
        width={[1]}
        px={[3]}
        alignItems="center"
        justifyContent="space-between"
        flex={['0 0 60px']}
        as="header"
      >
        {props.isSearchOpen ? (
          <SearchBar />
        ) : (
          <>
            <Menu onClick={handleMenuClick} fontSize="large" />

            {showIcon && <Image src={icon} width={20} />}

            {props.isStopMode ? (
              <Place
                fontSize="large"
                onClick={() => {
                  props.openMap(true)
                }}
              />
            ) : (
              <SearchIcon
                fontSize="large"
                onClick={handleSearchIconClick}
                cursor="pointer"
              />
            )}
          </>
        )}
      </Flex>

      {props.errors.length ? (
        <ErrorBanner flexDirection="column" p={3} alignItems="center">
          {props.errors.map((e: Error | AxiosError, i: number) => (
            <Box key={i}>{e.message}</Box>
          ))}
        </ErrorBanner>
      ) : null}
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  isSearchOpen: state.ui.isSearchOpen,
  isStopMode: state.ui.isStopMode,
  errors: state.ui.errors,
})

const mapDispatchToProps = {
  toggleSideDrawer,
  openMap,
  toggleSearch,
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
)
