import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Box, Flex, Image } from 'rebass'
import { IImage } from '../../interfaces'
import Lightbox from 'react-image-lightbox'
import Slider from 'react-slick'
import { event, EventAction, EventCategory } from '../../tracking'
import { bodyFontStack } from '../../theme'

// NOTE: This component REQUIRES that the parent have an explicit height.
// The height of the images is ignored and forced to fit the parent's height.

const placeholderImage = [
  {
    path: 'https://via.placeholder.com/300x200?text=No+Images+Found',
    caption: 'Placeholder image.',
  },
]

interface IImageCarousel {
  images: IImage[]
  onClick?: () => void
}
const ImageCarousel: React.FC<IImageCarousel> = ({ images, onClick }) => {
  const [selectedPhotoInCarousel, setSelectedPhotoInCarousel] = useState<
    number
  >(0)
  const [selectedPhoto, setSelectedPhoto] = useState<number | null>(null)
  const [height, setHeight] = useState(300)
  const carouselContainerRef = useRef<HTMLDivElement | null>(null)

  const sliderRef = useRef<Slider | null>(null)

  useEffect(() => {
    const carouselContainer = carouselContainerRef.current

    if (carouselContainer) {
      const { height } = carouselContainer.getBoundingClientRect()
      setHeight(height)
    }
  }, [carouselContainerRef])

  const photos = useMemo(() => {
    return images.length > 0 ? images : placeholderImage
  }, [images])

  useEffect(() => {
    const sliderCurrent = sliderRef.current

    if (sliderCurrent !== null) {
      // Set the slider's interal state to 0
      sliderCurrent.slickGoTo(0)

      // Set the local slider index state to 0 too.
      // afterChange doesn't trigger from slickGoTo call
      setSelectedPhotoInCarousel(0)
    }
  }, [photos])

  const handleCarouselClick = (i: number) => () => {
    if (onClick) {
      onClick()
      return
    }

    event({
      category: EventCategory.Images,
      action: EventAction.LightboxOpened,
    })
    setSelectedPhoto(i)
  }

  const handleMovePrevRequest = () => {
    event({
      category: EventCategory.Images,
      action: EventAction.CarouselPaged,
    })

    if (selectedPhoto !== null) {
      setSelectedPhoto((selectedPhoto + photos.length - 1) % photos.length)
    }
  }

  const handleMoveNextRequest = () => {
    event({
      category: EventCategory.Images,
      action: EventAction.CarouselPaged,
    })

    if (selectedPhoto !== null) {
      setSelectedPhoto((selectedPhoto + 1) % photos.length)
    }
  }

  // noinspection JSUnusedGlobalSymbols
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    afterChange: (x: number) => {
      setSelectedPhotoInCarousel(x)
    },
  }

  return (
    <>
      {selectedPhoto !== null && (
        <Lightbox
          mainSrc={photos[selectedPhoto].path}
          nextSrc={photos[(selectedPhoto + 1) % photos.length].path}
          prevSrc={
            photos[(selectedPhoto + photos.length - 1) % photos.length].path
          }
          onMovePrevRequest={handleMovePrevRequest}
          onMoveNextRequest={handleMoveNextRequest}
          onCloseRequest={() => setSelectedPhoto(null)}
          imageCaption={`${selectedPhoto + 1} of ${photos.length} - ${
            photos[selectedPhoto].caption
          }`}
          reactModalStyle={{ content: { fontFamily: bodyFontStack } }}
        />
      )}

      <Flex
        ref={carouselContainerRef}
        height="100%"
        alignItems="center"
        sx={{ background: 'rgba(0, 0, 0, 0)', position: 'relative' }}
      >
        <Box width="100%" height={height}>
          <Slider {...sliderSettings} ref={sliderRef}>
            {photos.map((p, i) => (
              <Image
                key={i}
                src={p.path}
                onClick={handleCarouselClick(i)}
                mx="auto"
                maxHeight={height - 30}
                maxWidth="100%"
                sx={{
                  display: 'block !important',
                  width: 'auto !important',
                  height: 'auto !important',
                  cursor: 'pointer',
                }}
              />
            ))}
          </Slider>
          <Box
            height={30}
            sx={{
              textAlign: 'center',
              color: 'black',
              fontSize: 0,
              padding: 1,
              backgroundColor: 'white',
              fontFamily: bodyFontStack,
            }}
          >
            {`${selectedPhotoInCarousel + 1} of ${photos.length}`}
          </Box>
        </Box>
      </Flex>
    </>
  )
}

export default ImageCarousel
