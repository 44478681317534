import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import FileUploader from '../common/FileUploader'
import {
  IImage,
  IMedia,
  IRecord,
  isImage,
  IUploadedFile,
} from '../../../interfaces'
import { Box, Flex, Heading } from 'rebass'
import { TextField } from '@material-ui/core'

import Button from '../../common/Button'
import { isRecord } from '../helpers/validators'
import CreatableSelect from 'react-select/creatable'
import { ValueType } from 'react-select/src/types'
import {
  convertFieldDataToOptions,
  convertToOption,
  IOption,
} from '../../../helpers/compileSelectOptions'
import MediaList from '../common/MediaList'
import RequiredIndicator from '../common/RequiredIndicator'

const noop = () => {}

interface IAdminRecord {
  record: Partial<IRecord>
  onDelete: () => void
  onSave: () => void
  onCancel: () => void
  onRecordUpdate: (record: Partial<IRecord>) => void
  options: Partial<Record<keyof IRecord, IOption[]>> | null
  onMediaUpload: (uploadedFiles: IUploadedFile[]) => void
  setType?: string
}
const AdminRecord: React.FC<IAdminRecord> = ({
  record,
  onDelete,
  onSave,
  onCancel,
  onRecordUpdate,
  options,
  onMediaUpload,
  setType,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<IUploadedFile[]>([])
  const [lat, setLat] = useState(
    record.Coordinates ? record.Coordinates.lat : 0
  )
  const [lng, setLng] = useState(
    record.Coordinates ? record.Coordinates.lng : 0
  )
  const updateRecord = useCallback(
    (field: Partial<IRecord>) => {
      onRecordUpdate(Object.assign(record, field))
    },
    [onRecordUpdate, record]
  )

  useEffect(() => {
    if (lat || lng) {
      updateRecord({
        Coordinates: { lat, lng },
      })
    }
  }, [lat, lng, updateRecord])

  useEffect(() => {
    onMediaUpload(uploadedFiles)
  }, [uploadedFiles, onMediaUpload])

  // If setType, we hardcode that type to this record and don't allow changing
  const recordType = record.Type
  useEffect(() => {
    if (setType && recordType !== setType) {
      updateRecord({ Type: setType })
    }
  }, [setType, updateRecord, recordType])

  const handleUpload = (file: IUploadedFile) => {
    setUploadedFiles(prevState => [...prevState, file])
  }

  const handleFileRemove = (i: number) => () => {
    const newFileList = [...uploadedFiles]
    newFileList.splice(i, 1)
    setUploadedFiles(newFileList)
  }

  const changeStringField = (fieldName: keyof IRecord) => (
    e: ChangeEvent<{ value: unknown }>
  ) => {
    updateRecord({ [fieldName]: e.target.value as string })
  }

  const changeCreatableStringField = (fieldName: keyof IRecord) => (
    selectedOption: ValueType<{ value: string; label: string }>
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore - There is a bug where isArray can't narrow this type
    const selectedOptionValue = selectedOption ? selectedOption.value : ''

    if (selectedOptionValue) {
      updateRecord({
        [fieldName]: selectedOptionValue,
      })
    }
  }

  const changeArrayField = (fieldName: keyof IRecord) => (
    array: ValueType<{ value: string; label: string }>
  ) => {
    const newArray = Array.isArray(array) ? array.map(v => v.value) : []

    updateRecord({
      [fieldName]: newArray,
    })
  }

  const changeLat = (e: ChangeEvent<{ value: unknown }>) => {
    setLat(parseFloat(e.target.value as string))
  }

  const changeLng = (e: ChangeEvent<{ value: unknown }>) => {
    setLng(parseFloat(e.target.value as string))
  }

  const changeImagesField = (media: IMedia[] | IImage[] | undefined) => {
    if (media && media.every(isImage)) {
      // TypeScript can't figure out that if we get here we have an array of images.
      const images = media as IImage[]
      updateRecord({ images })
    }
  }

  const changeAudiosField = (audios: IMedia[]) => {
    updateRecord({ audios })
  }

  const changeVideosField = (videos: IMedia[]) => {
    updateRecord({ videos })
  }

  return (
    <>
      <Flex justifyContent="space-between" mb={4}>
        <FileUploader onUpload={handleUpload} />

        <Button
          ml={3}
          bg="red"
          variant="primary"
          onClick={onDelete}
          confirm={true}
        >
          Delete Record
        </Button>
      </Flex>

      {record.images && !!record.images.length && (
        <>
          <Heading fontSize={2} fontWeight={600}>
            Images
          </Heading>
          <MediaList
            type="Image"
            media={record.images || []}
            onChange={changeImagesField}
            onAddMedia={noop}
            hideAddButton={true}
          />
        </>
      )}

      {record.audios && !!record.audios.length ? (
        <>
          <Heading fontSize={2} fontWeight={600}>
            Audio
          </Heading>
          <MediaList
            type="Audio"
            media={record.audios}
            onChange={changeAudiosField}
            onAddMedia={noop}
            hideAddButton={true}
          />
        </>
      ) : null}

      {record.videos && !!record.videos.length ? (
        <>
          <Heading fontSize={2} fontWeight={600}>
            Video
          </Heading>
          <MediaList
            type="Video"
            media={record.videos}
            onChange={changeVideosField}
            onAddMedia={noop}
            hideAddButton={true}
          />
        </>
      ) : null}

      {uploadedFiles.length ? (
        <>
          <Heading fontSize={2} fontWeight={600} mt={3} mb={2}>
            Uploaded files:
          </Heading>

          {uploadedFiles.map((f, i) => {
            const file = f.file

            return (
              <Flex
                justifyContent="space-between"
                alignItems="center"
                key={i}
                width={[2 / 3, 1 / 2]}
                mb={1}
              >
                <Box fontSize={0}>{file.name || ''}</Box>

                <Button
                  ml={3}
                  color="red"
                  variant="outline"
                  onClick={handleFileRemove(i)}
                >
                  Remove
                </Button>
              </Flex>
            )
          })}
        </>
      ) : null}

      <Box mt={4}>
        <Box width={[2 / 3, 1 / 2]}>
          <Flex mt={4} mb={1}>
            <Heading fontSize={2} fontWeight={600}>
              Title
            </Heading>
            <RequiredIndicator />
          </Flex>

          <TextField
            margin="dense"
            variant="outlined"
            fullWidth={true}
            value={record.Title || ''}
            onChange={changeStringField('Title')}
          />
        </Box>
        <Box width={[2 / 3, 1 / 2]}>
          <Flex mt={4} mb={1}>
            <Heading fontSize={2} fontWeight={600}>
              Type
            </Heading>
            <RequiredIndicator />
          </Flex>

          {setType ? (
            <Box>{setType}</Box>
          ) : (
            <CreatableSelect
              isSearchable={true}
              value={convertToOption(record.Type || '')}
              options={options ? options.Type : []}
              onChange={changeCreatableStringField('Type')}
            />
          )}
        </Box>
        <Box width={[2 / 3, 1 / 2]}>
          <Heading fontSize={2} fontWeight={600} mt={4} mb={1}>
            Creator
          </Heading>
          <TextField
            margin="dense"
            variant="outlined"
            fullWidth={true}
            value={record.Creator || ''}
            onChange={changeStringField('Creator')}
          />
        </Box>
        <Box width={[2 / 3, 1 / 2]}>
          <Heading fontSize={2} fontWeight={600} mt={4} mb={1}>
            Author
          </Heading>
          <TextField
            margin="dense"
            variant="outlined"
            fullWidth={true}
            value={record.Author || ''}
            onChange={changeStringField('Author')}
          />
        </Box>
        <Box width={[2 / 3, 1 / 2]}>
          <Heading fontSize={2} fontWeight={600} mt={4} mb={1}>
            Address
          </Heading>
          <TextField
            margin="dense"
            variant="outlined"
            fullWidth={true}
            value={record.Address || ''}
            onChange={changeStringField('Address')}
          />
        </Box>
        <Box width={[2 / 3, 1 / 2]}>
          <Heading fontSize={2} fontWeight={600} mt={4} mb={1}>
            Coordinates (latitude, longitude)
          </Heading>
          <Flex>
            <Box mr={1}>
              <TextField
                type="number"
                margin="dense"
                variant="outlined"
                value={lat}
                onChange={changeLat}
              />
            </Box>
            <Box ml={1}>
              <TextField
                type="number"
                margin="dense"
                variant="outlined"
                value={lng}
                onChange={changeLng}
              />
            </Box>
          </Flex>
        </Box>
        <Box width={[2 / 3, 1 / 2]}>
          <Heading fontSize={2} fontWeight={600} mt={4} mb={1}>
            Description
          </Heading>
          <TextField
            multiline={true}
            rows={5}
            margin="dense"
            variant="outlined"
            fullWidth={true}
            value={record.Description || ''}
            onChange={changeStringField('Description')}
          />
        </Box>
        <Box width={[2 / 3, 1 / 2]}>
          <Heading fontSize={2} fontWeight={600} mt={4} mb={2}>
            Source
          </Heading>
          <CreatableSelect
            isMulti={true}
            isSearchable={true}
            value={convertFieldDataToOptions(record.Source)}
            options={options ? options.Source : []}
            onChange={changeArrayField('Source')}
          />
        </Box>
        <Box width={[2 / 3, 1 / 2]}>
          <Heading fontSize={2} fontWeight={600} mt={4} mb={1}>
            Date
          </Heading>
          <TextField
            margin="dense"
            variant="outlined"
            fullWidth={true}
            value={record.Date || ''}
            onChange={changeStringField('Date')}
          />
        </Box>
        <Box width={[2 / 3, 1 / 2]}>
          <Heading fontSize={2} fontWeight={600} mt={4} mb={2}>
            Contributor
          </Heading>
          <CreatableSelect
            isMulti={true}
            isSearchable={true}
            value={convertFieldDataToOptions(record.Contributor)}
            options={options ? options.Contributor : []}
            onChange={changeArrayField('Contributor')}
          />
        </Box>
        <Box width={[2 / 3, 1 / 2]}>
          <Heading fontSize={2} fontWeight={600} mt={4} mb={1}>
            Relation
          </Heading>
          <TextField
            margin="dense"
            variant="outlined"
            fullWidth={true}
            value={record.Relation || ''}
            onChange={changeStringField('Relation')}
          />
        </Box>
        <Box width={[2 / 3, 1 / 2]}>
          <Heading fontSize={2} fontWeight={600} mt={4} mb={1}>
            Coverage
          </Heading>
          <TextField
            margin="dense"
            variant="outlined"
            fullWidth={true}
            value={record.Coverage || ''}
            onChange={changeStringField('Coverage')}
          />
        </Box>
        <Box width={[2 / 3, 1 / 2]}>
          <Heading fontSize={2} fontWeight={600} mt={4} mb={1}>
            Rights
          </Heading>
          <TextField
            margin="dense"
            variant="outlined"
            fullWidth={true}
            value={record.Rights || ''}
            onChange={changeStringField('Rights')}
          />
        </Box>
        <Box width={[2 / 3, 1 / 2]}>
          <Heading fontSize={2} fontWeight={600} mt={4} mb={2}>
            Subject
          </Heading>
          <CreatableSelect
            isMulti={true}
            isSearchable={true}
            value={convertFieldDataToOptions(record.Subject)}
            options={options ? options.Subject : []}
            onChange={changeArrayField('Subject')}
          />
        </Box>
        <Box width={[2 / 3, 1 / 2]}>
          <Heading fontSize={2} fontWeight={600} mt={4} mb={2}>
            Keyword
          </Heading>
          <CreatableSelect
            isMulti={true}
            isSearchable={true}
            value={convertFieldDataToOptions(record.Keyword)}
            options={options ? options.Keyword : []}
            onChange={changeArrayField('Keyword')}
          />
        </Box>
        <Box width={[2 / 3, 1 / 2]}>
          <Heading fontSize={2} fontWeight={600} mt={4} mb={1}>
            Notes
          </Heading>
          <TextField
            multiline={true}
            rows={5}
            margin="dense"
            variant="outlined"
            fullWidth={true}
            value={record.Notes || ''}
            onChange={changeStringField('Notes')}
          />
        </Box>

        <Flex mt={4}>
          <Button onClick={onCancel} variant="outline" color="blue">
            Cancel
          </Button>
          <Button
            ml={3}
            variant="primary"
            onClick={onSave}
            disabled={!isRecord(record)}
          >
            Save
          </Button>
        </Flex>
      </Box>
    </>
  )
}

export default AdminRecord
