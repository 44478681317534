import config from '../../config'
import {
  CMS_DELETE_TOUR_FAILURE,
  CMS_DELETE_TOUR_REQUEST,
  CMS_DELETE_TOUR_SUCCESS,
  CMS_GET_TOURS_FAILURE,
  CMS_GET_TOURS_REQUEST,
  CMS_GET_TOURS_SUCCESS,
  CMS_SAVE_TOUR_FAILURE,
  CMS_SAVE_TOUR_REQUEST,
  CMS_SAVE_TOUR_SUCCESS,
  CMS_SET_EDITED_TOUR,
} from '../../constants/actionTypes'
import { IEditedItem, ITour } from '../../interfaces'
import { AxiosError, AxiosResponse } from 'axios'
import cmsAxios from './cmsNet'
import { ThunkDispatch } from 'redux-thunk'
import { AppState } from '../../reducers/rootReducer'
import { AnyAction } from 'redux'
import validateAndSaveTour from '../../helpers/validateAndSaveTour'
import { addNewError, setUiIsLoading } from '../ui'

const CMS_API_URL = config.CMS_API_URL

export const cmsGetToursRequest = () => ({
  type: CMS_GET_TOURS_REQUEST,
})

export const cmsGetToursSuccess = (tours: ITour[]) => ({
  type: CMS_GET_TOURS_SUCCESS,
  tours,
})

export const cmsGetToursFailure = (error: AxiosError) => ({
  type: CMS_GET_TOURS_FAILURE,
  error,
})

export const cmsGetTours = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(setUiIsLoading(true))
  dispatch(cmsGetToursRequest())

  cmsAxios
    .get(`${CMS_API_URL}/tours`)
    .then((res: AxiosResponse<ITour[]>) => {
      const tours = res.data || []
      dispatch(cmsGetToursSuccess(tours))
      dispatch(setUiIsLoading(false))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(cmsGetToursFailure(err))
      dispatch(setUiIsLoading(false))
    })
}

export const cmsGetToursIfNeeded = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>,
  getState: () => AppState
) => {
  const { cms } = getState()

  if (!cms.tours.isCached.cmsGetTours) {
    dispatch(cmsGetTours())
  }
}

export const cmsSetEditedTour = (tour: IEditedItem<ITour> | null) => ({
  type: CMS_SET_EDITED_TOUR,
  tour,
})

export const cmsSaveTourRequest = () => ({
  type: CMS_SAVE_TOUR_REQUEST,
})

export const cmsSaveTourSuccess = (tour: ITour) => ({
  type: CMS_SAVE_TOUR_SUCCESS,
  tour,
})

export const cmsSaveTourFailure = (error: AxiosError) => ({
  type: CMS_SAVE_TOUR_FAILURE,
  error,
})

export const cmsSaveTour = (tour: ITour, isNew: boolean) => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(cmsSaveTourRequest())
  dispatch(setUiIsLoading(true))

  const method = isNew ? 'post' : 'put'
  const url = isNew ? `${CMS_API_URL}/tours` : `${CMS_API_URL}/tours/${tour.id}`

  return cmsAxios[method](url, tour)
    .then((res: AxiosResponse<ITour>) => {
      dispatch(setUiIsLoading(false))

      if (!res.data.id) {
        throw new Error('Error saving tour')
      }

      return dispatch(cmsSaveTourSuccess(res.data))
    })
    .catch((err: AxiosError) => {
      dispatch(setUiIsLoading(false))
      dispatch(addNewError(err))
      return dispatch(cmsSaveTourFailure(err))
    })
}

export const cmsDeleteTourRequest = () => ({
  type: CMS_DELETE_TOUR_REQUEST,
})

export const cmsDeleteTourSuccess = (tour: Partial<ITour>) => ({
  type: CMS_DELETE_TOUR_SUCCESS,
  tour,
})

export const cmsDeleteTourFailure = (error: AxiosError) => ({
  type: CMS_DELETE_TOUR_FAILURE,
  error,
})

export const cmsDeleteTour = (tour: Partial<ITour>) => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(cmsDeleteTourRequest())
  dispatch(setUiIsLoading(true))

  return cmsAxios
    .delete(`${CMS_API_URL}/tours/${tour.id}`)
    .then(() => {
      dispatch(cmsDeleteTourSuccess(tour))
      dispatch(setUiIsLoading(false))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(cmsDeleteTourFailure(err))
      dispatch(setUiIsLoading(false))
      throw err
    })
}

export const cmsSaveEditedTour = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>,
  getState: () => AppState
) => {
  const editedStops = getState().cms.editedStops
  const editedTour = getState().cms.editedTour

  return validateAndSaveTour(editedStops, editedTour, dispatch)
}
