import { IUploadedFile, IUploadResult, MediaType } from '../interfaces'
import startCase from 'lodash/startCase'
import firebase from 'firebase/app'
import 'firebase/storage'
import uuid from 'uuid'

const getFileType = (meta: File): MediaType | null => {
  const mimeType = meta.type
  const mimeTypeArray = mimeType.split('/')
  const maybeFileType = startCase(mimeTypeArray[0])

  if (
    maybeFileType === 'Image' ||
    maybeFileType === 'Audio' ||
    maybeFileType === 'Video'
  ) {
    return maybeFileType
  } else {
    return null
  }
}

export default (
  uploadedFile: IUploadedFile,
  folder = 'media'
): Promise<IUploadResult> => {
  return new Promise<IUploadResult>((resolve, reject) => {
    const file = uploadedFile.file
    const type = getFileType(file)

    if (type) {
      const fileName = `${uuid.v4()}_${file.name || 'generated-file-name'}`
      const storageRef = firebase.storage().ref()
      const fileRef = storageRef.child(`${folder}/${fileName}`)

      fileRef
        .put(file)
        .then(res => {
          const {
            metadata: { bucket, fullPath },
          } = res
          // I don't know why this path needs to be encoded but it does
          const encodedFullPath = encodeURIComponent(fullPath)
          const path = `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${encodedFullPath}?alt=media`

          fileRef.getDownloadURL().then(url => {
            resolve({ type, path, url })
          })
        })
        .catch(err => {
          reject(err)
        })
    }
  })
}
