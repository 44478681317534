import React, { useEffect, useState } from 'react'
import { Box, Flex, Text } from 'rebass'

import styled from 'styled-components'
import Slider from 'rc-slider'
import { PlayArrow, Forward10, Replay10, Pause } from '@material-ui/icons'

//import moment from 'moment'
import * as moment from 'moment'
import 'moment-duration-format'
import momentDurationFormatSetup from 'moment-duration-format'

import { event, EventAction, EventCategory } from '../../tracking'

// @ts-ignore
momentDurationFormatSetup(moment)

const PlayerWrapper = styled(Flex)`
  height: 190px;
  background-color: #333333;
  color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
  flex-direction: column;
`

const SliderBox = styled(Box)`
  position: relative;
`

const PlayAudioHint = styled(Box)`
  height: 38px;
  font-size: 16px;
  color: #1f4695;
  line-height: 38px;
  width: 100%;
  text-align: center;
  background-color: #ebebeb;
`

const formatTime = (time: number) => {
  // @ts-ignore
  return moment.duration(time, 'seconds').format('m:ss', { trim: false })
}

interface IAudioUIProps {
  audioEl: HTMLAudioElement
}
const AudioUI: React.FC<IAudioUIProps> = ({ audioEl }) => {
  const [sliderPosition, setSliderPosition] = useState(0)
  const [downloadedPercent, setDownloadedPercent] = useState(0)

  useEffect(() => {
    audioEl.addEventListener(
      'timeupdate',
      () => {
        setSliderPosition((audioEl.currentTime / audioEl.duration) * 100)
      },
      false
    )

    const progressTracker = setInterval(() => {
      const buffered = audioEl.buffered

      if (buffered.length) {
        const loaded = (buffered.end(0) / audioEl.duration) * 100
        setDownloadedPercent(loaded)
      }
    }, 50)

    return () => clearInterval(progressTracker)
  }, [audioEl])

  const handleSliderChange = (position: number) => {
    setSliderPosition(position)
  }

  const handleSliderAfterChange = (position: number) => {
    audioEl.currentTime = Math.round(audioEl.duration * (position / 100))
  }

  const handlePlayClick = () => {
    event({
      category: EventCategory.Audio,
      action: EventAction.PlayToggle,
      label: audioEl.title,
    })

    audioEl.play()
  }
  const handlePauseClick = () => {
    event({
      category: EventCategory.Audio,
      action: EventAction.PauseToggle,
      label: audioEl.title,
    })

    audioEl.pause()
  }
  const handleReplay10Click = () => {
    event({
      category: EventCategory.Audio,
      action: EventAction.Back10Seconds,
      label: audioEl.title,
    })

    audioEl.currentTime = audioEl.currentTime - 10
  }
  const handleForward10Click = () => {
    event({
      category: EventCategory.Audio,
      action: EventAction.Forward10Seconds,
      label: audioEl.title,
    })

    audioEl.currentTime = audioEl.currentTime + 10
  }

  return (
    <PlayerWrapper>
      <PlayAudioHint>Play audio below</PlayAudioHint>

      <Flex
        mx="auto"
        my={3}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        width={[0.85, 0.75, 0.5]}
        p={3}
        sx={{ border: 'solid 1px white', borderRadius: '5px' }}
      >
        <Flex width={1} fontSize={0} justifyContent="space-between" mb={2}>
          <Text>{formatTime(audioEl.currentTime)}</Text>
          <Text>-{formatTime(audioEl.duration - audioEl.currentTime)}</Text>
        </Flex>
        <SliderBox width={1}>
          <Slider
            value={sliderPosition}
            onChange={handleSliderChange}
            onAfterChange={handleSliderAfterChange}
            trackStyle={{
              backgroundColor: 'white',
              height: 1,
              position: 'relative',
            }}
            handleStyle={{
              position: 'absolute',
              height: 8,
              width: 8,
              marginLeft: -2,
              marginTop: -5,
              backgroundColor: 'white',
              borderRadius: '100%',
            }}
            railStyle={{
              backgroundColor: '#666666',
              height: 1,
              position: 'relative',
              top: 1,
              width: `${downloadedPercent}%`,
            }}
          />
        </SliderBox>
        <Flex />
        <Flex
          justifyContent="space-between"
          width={0.55}
          alignItems="center"
          fontSize={7}
        >
          <Replay10 fontSize="large" onClick={handleReplay10Click} />

          {audioEl.paused ? (
            <PlayArrow fontSize="inherit" onClick={handlePlayClick} />
          ) : (
            <Pause fontSize="inherit" onClick={handlePauseClick} />
          )}

          <Forward10 fontSize="large" onClick={handleForward10Click} />
        </Flex>
      </Flex>
    </PlayerWrapper>
  )
}

export default AudioUI
