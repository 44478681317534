import { AnyAction } from 'redux'
import { IRecord, IRecordsState } from '../interfaces'
import {
  GET_RECORDS_FAILURE,
  GET_RECORDS_REQUEST,
  GET_RECORDS_SUCCESS,
} from '../constants/actionTypes'
//import normalizeEntities from '../helpers/normalizeEntities'
import { normalizeEntitiesWithSlug } from '../helpers/normalizeEntities'
import { compileOptionsFromRecords } from '../helpers/compileSelectOptions'

const initialState: IRecordsState = {
  byId: {},
  bySlug: {},
  byStopId: {},
  allIds: [],
  isLoading: false,
  isCached: {},
  options: null,
  selectedRecordType: 'All',
}

const recordsReducer = (
  state = initialState,
  action: AnyAction
): IRecordsState => {
  switch (action.type) {
    case GET_RECORDS_REQUEST:
      return { ...state, isLoading: true }
    case GET_RECORDS_SUCCESS:
      const normalizedEntities = normalizeEntitiesWithSlug(
        action.records as IRecord[]
      )
      return {
        ...state,
        ...normalizedEntities,
        byStopId: {
          ...state.byStopId,
          ...{ [action.stopId]: action.tours },
        },
        options: compileOptionsFromRecords(normalizedEntities.byId),
        isLoading: false,
        isCached: { ...state.isCached, getRecords: true },
      }
    case GET_RECORDS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default recordsReducer
