import React, { useEffect, useRef, useState } from 'react'
import mapboxgl, { Map } from 'mapbox-gl'
import { Box, Flex, Heading } from 'rebass'
import NSModal from '../common/NSModal'
import Button from '../common/Button'

const style = {
  position: 'absolute' as 'absolute', // TypeScript got confused
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 3,
}

interface IMapboxProps {
  nextStopLongLat: [number, number]
  popupElement: Node
  mapUrl: string
  zoomLevel: number
}
const Mapbox: React.FC<IMapboxProps> = ({
  nextStopLongLat,
  popupElement,
  mapUrl,
  zoomLevel,
}) => {
  const [showLocateError, setShowLocateError] = useState(false)
  const [map, setMap] = useState<Map | null>(null)
  const mapContainer = useRef(null)

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || ''

    setMap(
      new mapboxgl.Map({
        container: mapContainer.current || '',
        style: mapUrl,
        center: nextStopLongLat,
        zoom: zoomLevel,
      })
    )
  }, [nextStopLongLat, mapUrl, zoomLevel])

  useEffect(() => {
    if (map !== null) {
      // Add locate control and trigger it when map mounts
      const GeolocateControl = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        fitBoundsOptions: { maxZoom: 17 },
      })

      GeolocateControl.on('error', handleGeolocateError)
      map.addControl(GeolocateControl)

      const nav = new mapboxgl.NavigationControl({ showZoom: false })
      map.addControl(nav, 'top-right')

      map.on('load', () => {
        GeolocateControl.trigger()
      })

      // Add popup for next stop location
      new mapboxgl.Popup({ closeOnClick: false, closeButton: false })
        .setLngLat(nextStopLongLat)
        .setDOMContent(popupElement)
        .addTo(map)
    }
  }, [map, nextStopLongLat, popupElement])

  function handleGeolocateError(e: PositionError) {
    console.error('Geolocate error:', e)
    const didUserDenyGeolocation = e.code === 1

    if (didUserDenyGeolocation) {
      setShowLocateError(true)
    }
  }

  const handleCloseModal = () => {
    setShowLocateError(false)
  }

  return (
    <>
      {showLocateError && (
        <NSModal onClose={handleCloseModal}>
          <Heading fontSize={[4]}>Enable location services</Heading>

          <Box mt={3}>
            It looks like your location services are restricted. Please go to
            your device's settings and allow us to use your location for the
            best touring experience.
          </Box>

          <Box mt={4} sx={{ textAlign: 'center' }}>
            <Button
              width={[1, 150]}
              onClick={handleCloseModal}
              variant="outline"
              color="blue"
            >
              Ok
            </Button>
          </Box>
        </NSModal>
      )}

      <Flex flex={[1]} width={[1]} sx={{ position: 'relative' }}>
        <div ref={mapContainer} style={style} />
      </Flex>
    </>
  )
}

export default Mapbox
