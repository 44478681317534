import React from 'react'
import { Flex, Text } from 'rebass'
import Mapbox from './Mapbox'
import { IStopWithRecord } from '../../interfaces'
import createPopupElement from '../../helpers/createPopupElement'
import { connect } from 'react-redux'
import { openMap } from '../../actions/ui'
import { Close } from '@material-ui/icons'
import { event, EventAction, EventCategory } from '../../tracking'

interface IMapProps {
  nextStop: IStopWithRecord
  openMap: (isMapOpen: boolean) => void
  onNextStop: (nextStopId: string) => void
  mapUrl: string
  zoomLevel: number
}
const Map: React.FC<IMapProps> = props => {
  const { nextStop, onNextStop, mapUrl, zoomLevel } = props

  if (!nextStop.record.Coordinates) {
    return <div>Incompatible record type</div>
  }

  const nextStopLongLat: [number, number] = [
    nextStop.record.Coordinates.lng,
    nextStop.record.Coordinates.lat,
  ]
  const handlePopupClick = (nextStopId: string) => {
    event({
      category: EventCategory.Map,
      action: EventAction.ArriveButtonClicked,
      label: nextStop && nextStop.siteName,
    })
    props.openMap(false)
    onNextStop(nextStopId)
  }
  const popupElement = createPopupElement(nextStop, handlePopupClick)

  return (
    <Flex
      color="black"
      flexDirection={['column']}
      bg="lighterGrey"
      style={{
        zIndex: 4,
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <Flex
        p={3}
        bg={'white'}
        style={{ borderBottom: '1px solid lightgrey' }}
        flex={0}
        width={1}
        justifyContent={['space-between']}
        alignItems={['center']}
      >
        <Text fontSize={[4]}>Directions to {nextStop.siteName}</Text>

        <Close
          onClick={() => {
            props.openMap(false)
          }}
          fontSize="large"
        />
      </Flex>

      <Mapbox
        mapUrl={mapUrl}
        nextStopLongLat={nextStopLongLat}
        popupElement={popupElement}
        zoomLevel={zoomLevel}
      />
    </Flex>
  )
}

export default connect(null, {
  openMap,
})(Map)
