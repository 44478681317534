import React, { useEffect } from 'react'
import { IEditedItem, IQRCode } from '../../../interfaces'
import AdminQRCode from './AdminQRCode'
import { AppState } from '../../../reducers/rootReducer'
import {
  cmsDeleteQRCode,
  cmsGetQRCodesIfNeeded,
  cmsSaveQRCode,
  cmsSetEditedQRCode,
} from '../../../actions/cms'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import Loading from '../../common/Loading'
import initNewQRCode from '../../../helpers/initNewQRCode'
import { Box } from 'rebass'
import { isQRCode } from '../helpers/validators'

interface IGetQRCodeData extends RouteComponentProps<{ id: string }> {
  qrCodesById?: { [Key: string]: IQRCode }
  editedQRCode?: IEditedItem<IQRCode> | null
  getQRCodes: () => void
  setEditedQRCode: (qrCode: IEditedItem<IQRCode> | null) => void
  isLoading: boolean
  saveQRCode: (x: IQRCode, y: boolean) => Promise<void>
  deleteQRCode: (qrCode: Partial<IQRCode>) => Promise<void>
}
const GetQRCodeData: React.FC<IGetQRCodeData> = ({
  qrCodesById,
  editedQRCode,
  getQRCodes,
  setEditedQRCode,
  location,
  match,
  isLoading,
  saveQRCode,
  deleteQRCode,
  history,
}) => {
  const isNew = location.pathname.includes('new')
  const isEdit = location.pathname.includes('edit')
  const editedId = match.params.id

  useEffect(() => {
    // This will get qrCode only if we don't currently have them or if the
    // cache is invalidated.
    getQRCodes()
  }, [getQRCodes])

  useEffect(() => {
    if (qrCodesById) {
      const recordToEdit = isNew ? initNewQRCode() : qrCodesById[editedId]

      if (recordToEdit) {
        setEditedQRCode({ item: recordToEdit, meta: { isNew } })
      }

      return () => {
        setEditedQRCode(null)
      }
    }
  }, [isNew, editedId, qrCodesById, setEditedQRCode])

  const handleQRCodeUpdate = (record: Partial<IQRCode>) => {
    setEditedQRCode({ item: record, meta: { isNew } })
  }

  const onDelete = () => {
    if (isNew) {
      backToQRCodes()
    } else if (isEdit && editedQRCode) {
      deleteQRCode(editedQRCode.item).then(() => {
        backToQRCodes()
      })
    }
  }

  const onSave = () => {
    if (editedQRCode && isQRCode(editedQRCode.item)) {
      saveQRCode(editedQRCode.item, editedQRCode.meta.isNew).then(() => {
        backToQRCodes()
      })
    }
  }

  const backToQRCodes = () => {
    history.push('/admin/qr-codes')
  }

  if (isLoading) {
    return <Loading />
  }

  return editedQRCode && editedQRCode.item ? (
    <AdminQRCode
      qrCode={editedQRCode.item}
      onDelete={onDelete}
      onSave={onSave}
      onCancel={backToQRCodes}
      onQRCodeUpdate={handleQRCodeUpdate}
    >
      GetQRCodeData
    </AdminQRCode>
  ) : (
    <Box>No QR code found</Box>
  )
}

const mapStateToProps = (state: AppState) => ({
  qrCodesById: state.cms.qrCodes.byId,
  editedQRCode: state.cms.editedQRCode,
  isLoading: state.cms.qrCodes.isLoading,
})

const mapDispatchToProps = {
  setEditedQRCode: cmsSetEditedQRCode,
  getQRCodes: cmsGetQRCodesIfNeeded,
  saveQRCode: cmsSaveQRCode,
  deleteQRCode: cmsDeleteQRCode,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GetQRCodeData))
