import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { Heading, Text, Image, Flex, Box } from 'rebass'
import { connect } from 'react-redux'

import { AppState } from '../reducers/rootReducer'
import { ITour, IStop } from '../interfaces'
import { selectCurrentStopForLandingPage } from '../actions/tours'

import Button from './common/Button'

import logoHorizontal from '../assets/icons/nashville-sites-logo-horizontal.png'

interface IQRBackToStartProps extends RouteComponentProps<{}> {
  tour: ITour
  firstStop: IStop
  initialStop: IStop | null
  selectCurrentStopForLandingPage: (recordId: string, tour: ITour) => void
  recordId: string
}

const QRLandingPage: React.FC<IQRBackToStartProps> = props => {
  const { tour, selectCurrentStopForLandingPage, initialStop, recordId } = props

  useEffect(() => {
    selectCurrentStopForLandingPage(recordId, tour)
  }, [recordId, tour, selectCurrentStopForLandingPage])

  const handleKeepGoing = () => {
    const initialSlug = (initialStop && initialStop.slug) || null
    // TODO whatever stop user was at when scanned QR
    const url = `/tours/${tour.slug}/${initialSlug && initialSlug}`
    props.history.push(url)
  }

  const handleGoToStart = () => {
    const url = `/tours/${props.tour.slug}/${props.firstStop.slug}`
    props.history.push(url)
  }

  const handleSeeOtherTours = () => {
    const url = `/tours`
    props.history.push(url)
  }

  // TODO review if the .id property is the right way to match stop to stop
  const initialStopId = initialStop && initialStop.id

  // determines the index value for the stop the user is on at the time of scan
  // so that we can show "You are on stop N of M in the UI
  const stopIndex = tour.stopIds.reduce(
    (acc: number | null, stopId: string, idx: number): number | null => {
      return stopId === initialStopId ? idx : acc
    },
    null
  )

  const stopNumber = stopIndex && stopIndex + 1,
    totalStops = tour.stopIds.length,
    tourName = tour.title

  return (
    <Flex
      px={[3]}
      pt={[0, 4]}
      pb={[6]}
      mt={[0, 4]}
      mb={[4]}
      flex={['1 1 0']}
      as="main"
      //style={{ position: 'relative' }}
      flexDirection="column"
      justifyContent="center"
    >
      <Box>
        <Image
          display="block"
          mx={'auto'}
          width={[290]}
          maxWidth={'100%'}
          src={logoHorizontal}
        />

        <Heading textAlign="center" mt={[4]}>
          You are at stop {stopNumber} of {totalStops} on the {tourName} tour.
        </Heading>

        <Text
          fontSize="18px"
          lineHeight="27px"
          mt={[4]}
          mb={[4]}
          textAlign={'center'}
        >
          Would you like to continue the tour from where you are now?
        </Text>

        <Button
          variant="primary"
          mt={[4]}
          display="block"
          height="64px"
          width={['100%']}
          onClick={handleKeepGoing}
        >
          Yes
        </Button>

        <Button
          variant="outline"
          mt={[3]}
          display="block"
          width={['100%']}
          onClick={handleGoToStart}
        >
          Go to Start of Tour
        </Button>

        <Text mt={[4]} textAlign="center">
          or
        </Text>

        <Button
          variant="redOutline"
          mt={[4]}
          display="block"
          width={['100%']}
          onClick={handleSeeOtherTours}
        >
          View Other Tours
        </Button>
      </Box>
    </Flex>
  )
}

const mapStateToProps = (state: AppState) => {
  const firstStopId = state.landingPages.selectedTour.stopIds[0]

  return {
    tour: state.landingPages.selectedTour,
    firstStop: state.entities.stops.byId[firstStopId],
    initialStop: state.landingPages.initialStop,
    recordId: state.landingPages.recordId,
  }
}

const mapDispatchToProps = {
  selectCurrentStopForLandingPage,
}

export default connect(mapStateToProps, mapDispatchToProps)(QRLandingPage)
