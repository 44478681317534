import { ITour } from '../interfaces'
import uuid from 'uuid'

export default (): Partial<ITour> => {
  return {
    id: uuid.v4(),
    title: '',
    description: '',
    intro: '',
    outro: '',
    images: [],
    duration: 0,
    distance: 0,
    stopIds: [],
    map: '',
  }
}
