import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Box, Flex, Heading, Image, Text } from 'rebass'

import mainIcon from '../assets/icons/NashvilleSites_Final-high-res.png'
import walkPng from '../assets/images/walk-2.png'
// import historyPng from '../assets/images/history.png'
import mobilephonePng from '../assets/images/mobile-phone-22.png'
// import bookPng from '../assets/images/open-book.png'
import paymentPng from '../assets/images/payment.png'

import Button from './common/Button'
import TourSummary from './common/TourSummary'
import TourLocations from './common/TourLocations'
import Loading from './common/Loading'
import BigAlert from './common/BigAlert'
import TourCategoryCTA from './common/TourCategoryCTA'

import { IToursState } from '../interfaces'
import { AppState } from '../reducers/rootReducer'
import { getToursIfNeeded } from '../actions/tours'
import { getFeaturedToursIfNeeded } from '../actions/featuredAndSocial'

interface IAppLandingPageProps extends RouteComponentProps {
  tours?: IToursState
  getToursIfNeeded: () => void
  getFeaturedTours: () => void
  featuredTours?: string[]
}
const AppLandingPage: React.FC<IAppLandingPageProps> = ({
  tours,
  getToursIfNeeded,
  history,
  getFeaturedTours,
  featuredTours,
}) => {
  useEffect(() => {
    getToursIfNeeded()
    getFeaturedTours()
  }, [getToursIfNeeded, getFeaturedTours])

  const handleViewAllTours = () => {
    history.push('/tours')
  }

  if (!tours || !featuredTours) {
    return <Loading />
  }

  return (
    <>
      <Flex
        px={[3]}
        pt={[0, 4]}
        pb={[2]}
        mt={[0, 4]}
        flex={['1 1 0']}
        as="main"
        flexDirection="column"
        style={{ position: 'relative' }}
      >
        <Flex flexDirection={['column', 'row']} justifyContent={['center']}>
          <Image width={[105]} src={mainIcon} alignSelf="center" mx={[0, 3]} />

          <Text mt={[4]} mx={[0, 3]} width={[1, '475px']} fontSize={[2, 3]}>
            From Honky Tonk Row to our very own Parthenon, the Nashville story
            is in the palm of your hand. Visit our historic landmarks or explore
            a world hidden in plain sight. Let the journey begin.
          </Text>
        </Flex>

        <Flex mt={[4, 5]} mx="auto" maxWidth="689px">
          <BigAlert />
        </Flex>

        <Flex
          flexDirection={['column']}
          justifyContent={['center']}
          alignItems={['center']}
        >
          <Box width={[1]} mb={[4]} maxWidth={[860]}>
            <Heading mt={[5]} mb={[3]} fontSize={[4]}>
              Tours
            </Heading>

            {!tours.isLoading ? (
              <Flex
                flexWrap={'wrap'}
                flexDirection={'row'}
                justifyContent={['center', 'center', 'space-between']}
              >
                {[
                  {
                    title: 'Downtown Nashville Tours',
                    backgroundImage:
                      'https://firebasestorage.googleapis.com/v0/b/nashville-sites-prod.appspot.com/o/media%2Fb921e01c-54a7-4b1c-8fb5-3cf5ab58e81a_Fifth_Avenue_2019_Sydney_Whitten.jpg?alt=media',
                  },
                  {
                    title: 'Neighborhoods and Campuses',
                    backgroundImage:
                      'https://firebasestorage.googleapis.com/v0/b/nashville-sites-prod.appspot.com/o/media%2F84dd3bda-2b4c-40e5-b2f0-91b9f6a41dbd_Belmont%20Hillsboro%202019%20MHCF.jpeg?alt=media',
                  },
                  {
                    title: 'Driving Tours',
                    backgroundImage:
                      'https://firebasestorage.googleapis.com/v0/b/nashville-sites-prod.appspot.com/o/media%2Fc057eaa5-fdec-49ee-9a1c-f283e06be560_IMG_1352.jpg?alt=media',
                  },
                  {
                    title: 'Tours with Lesson Plans',
                    backgroundImage:
                      'https://firebasestorage.googleapis.com/v0/b/nashville-sites-prod.appspot.com/o/media%2F0bf1cc30-8dd1-482d-9ddc-00383b36768d_hume%20fogg%202.jpeg?alt=media',
                  },
                ].map((tourCategory, idx) => {
                  return (
                    <Box
                      p={['8px']}
                      key={idx}
                      mb={[4]}
                      sx={{
                        flex: '0 1 400px',
                      }}
                    >
                      <TourCategoryCTA
                        title={tourCategory.title}
                        backgroundImage={
                          tourCategory.backgroundImage ||
                          'https://picsum.photos/500/500?blur&grescale'
                        }
                        history={history}
                      />
                    </Box>
                  )
                })}
              </Flex>
            ) : (
              <Loading />
            )}
          </Box>

          <Box width={[1, 860]} mb={[4]}>
            <Heading mt={[5]} mb={[3]} fontSize={[4]}>
              Featured Tours
            </Heading>

            {!tours.isLoading ? (
              <Flex
                flexWrap="wrap"
                flexDirection={['row']}
                justifyContent={['center', 'space-between']}
              >
                {featuredTours.map(tourId => (
                  <TourSummary mb={4} tour={tours.byId[tourId]} key={tourId} />
                ))}
              </Flex>
            ) : (
              <Loading />
            )}

            <Heading mt={[4]} fontSize={[4]}>
              Want to see all tours?
            </Heading>

            <Button
              mt={[3, 4]}
              mb={[0, 4]}
              mx={[0]}
              width={[1]}
              variant="redPrimary"
              onClick={handleViewAllTours}
            >
              View All Tours
            </Button>

            <Heading mt={[4]} fontSize={[4]}>
              Why Nashville Sites?
            </Heading>

            <Flex
              flexDirection={['column', 'row']}
              justifyContent={['center']}
              my={[4]}
            >
              <Flex flexDirection={['column']} width={[1, 300]}>
                <Heading fontSize={[3]} fontWeight={600}>
                  Self-Guided &amp; Credibly Curated
                </Heading>
                <Text my={[2]} fontSize={[2, 1]}>
                  It's your tour. Skip stops, jump ahead. Hey, maybe you want to
                  switch tours mid-stop. Go for it! Each tour is written by a
                  scholar. Curators range from the county historian to PhD
                  candidates.
                </Text>
              </Flex>
              <Box width={[1, 300]} height={200}>
                <Box
                  style={{ height: '200px', overflow: 'hidden' }}
                  mt={[2]}
                  width={[1]}
                >
                  <Image
                    mx={'auto'}
                    sx={{
                      maxWidth: '170px',
                      width: '100%',
                      display: 'block',
                    }}
                    src={walkPng}
                    alt={'Icon of a book'}
                  />
                </Box>
              </Box>
            </Flex>

            <Flex
              flexDirection={['column', 'row']}
              justifyContent={['center']}
              mt={[4]}
            >
              <Flex flexDirection={['column']} width={[1, 300]}>
                <Heading fontSize={[3]} fontWeight={600}>
                  Mobile-Friendly &amp; Thematically Based
                </Heading>
                <Text my={[2]} fontSize={[2, 1]}>
                  Our platform is web-based. So, what? You don't have to
                  download anything. Family fun tour? Yep. Architecture tour?
                  Yep. Music tour? &hellip;like 5.
                </Text>
              </Flex>
              <Box width={[1, 300]} height={200}>
                <Box
                  style={{ height: '200px', overflow: 'hidden' }}
                  mt={[2]}
                  width={[1]}
                >
                  <Image
                    mx={'auto'}
                    sx={{
                      maxWidth: '170px',
                      width: '100%',
                      display: 'block',
                    }}
                    src={mobilephonePng}
                    alt={'Icon of a shoe'}
                  />
                </Box>
              </Box>
            </Flex>

            <Flex
              flexDirection={['column', 'row']}
              justifyContent={['center']}
              my={[4]}
            >
              <Flex flexDirection={['column']} width={[1, 300]}>
                <Heading fontSize={[3]} fontWeight={600}>
                  Ad Free &amp; Free of Charge
                </Heading>
                <Text my={[2]} fontSize={[2, 1]}>
                  Our goal is to make history and information accessible. What
                  better way to do that than to make all of our resources free
                  of charge?
                </Text>
              </Flex>
              <Box width={[1, 300]} height={200}>
                <Box
                  style={{ height: '200px', overflow: 'hidden' }}
                  mt={[2]}
                  width={[1]}
                >
                  <Image
                    mx={'auto'}
                    sx={{
                      maxWidth: '170px',
                      width: '100%',
                      display: 'block',
                    }}
                    src={paymentPng}
                    alt={'Icon of mobile phones'}
                  />
                </Box>
              </Box>
            </Flex>

            <Heading mt={[5]} mb={[2]} fontSize={[4]}>
              Map of tour locations
            </Heading>

            <TourLocations />
          </Box>
        </Flex>
      </Flex>
    </>
  )
}

const mapStateToProps = (
  state: AppState
): { tours: IToursState; featuredTours: string[] } => ({
  tours: state.entities.tours,
  featuredTours: state.entities.featuredAndSocial.featuredTours.featuredTours,
})

const mapDispatchToProps = {
  getToursIfNeeded,
  getFeaturedTours: getFeaturedToursIfNeeded,
}

export default connect(mapStateToProps, mapDispatchToProps)(AppLandingPage)
