import React from 'react'
import { Flex, Box, Heading, Image } from 'rebass'
import mainIcon from '../assets/icons/NashvilleSites_Final-high-res.png'

interface IBrand {
  image: any
  name: string
  link: string
}
const companies: IBrand[] = [
  {
    image: require('../assets/images/sponsorLogos/TurnerFoundationLogo.png'),
    name: 'James Stephen Turner Family Foundation Nashville, TN',
    link:
      'https://web.nashvillechamber.com/Associations-and-Organizations/James-Stephen-Turner-Family-Foundation-2001',
  },
  {
    image: require('../assets/images/sponsorLogos/MIKE CURB Foundation.jpg'),
    name: 'Mike Curb Foundation',
    link: 'https://www.mikecurbfoundation.com/',
  },
  {
    image: require('../assets/images/sponsorLogos/NashCVCLogo.png'),
    name: 'NashCVC',
    link: 'https://www.visitmusiccity.com/',
  },
  {
    image: require('../assets/images/sponsorLogos/HumanitiesTNLogo.jpg'),
    name: 'Humanities Tennessee',
    link: 'http://humanitiestennessee.org',
  },
  {
    image: require('../assets/images/sponsorLogos/The_District-white-map.JPG.jpg'),
    name: 'The District Nashville',
    link: 'http://thedistrictnashville.org/',
  },
  {
    image: require('../assets/images/sponsorLogos/BelmontUniversityLogo.png'),
    name: 'Belmont University',
    link: 'http://www.belmont.edu/isge',
  },
  {
    image: require('../assets/images/sponsorLogos/TNCivilWarNHALogo.png'),
    name: 'Tennessee Civil War National Heritage Area',
    link: 'http://www.tncivilwar.org',
  },
  {
    image: require('../assets/images/sponsorLogos/CHPNewLogo2014.png'),
    name: 'MTSU Center for Historic Preservation',
    link: 'http://www.mtsuhistpres.org/',
  },
  {
    image: require('../assets/images/sponsorLogos/MHC Logo.jpg'),
    name: 'Metropolitan Historical Commision',
    link: '',
  },
  {
    image: require('../assets/images/sponsorLogos/PLALogoLong.jpg'),
    name: 'PLA Media',
    link: 'https://www.plamedia.com/',
  },
  {
    image: require('../assets/images/sponsorLogos/VU with tiles.jpg'),
    name: 'Center For Digital Humanities Logo',
    link: 'https://www.vanderbilt.edu/digitalhumanities',
  },
  {
    image: require('../assets/images/sponsorLogos/AubreyPrestonMHC.png'),
    name: 'Aubrey Preson',
    link: '',
  },
  {
    image: require('../assets/images/sponsorLogos/Nashville Public Library logo.png'),
    name: 'Nashville Public Library',
    link: '',
  },
  {
    image: require('../assets/images/sponsorLogos/cmhof-banner.png'),
    name: 'Country Music Hall of Fame and Museum',
    link: '',
  },
  {
    image: require('../assets/images/sponsorLogos/waller-law.jpg'),
    name: 'Waller Law',
    link: 'https://www.wallerlaw.com/',
  },
  {
    image: require('../assets/images/sponsorLogos/Horton Advertising Logo.jpg'),
    name: 'Horton Group',
    link: '',
  },
]

// VU with tiles.jpg
// TurnerFoundationLogo.png
// TNCivilWarNHALogo.png
// TheDistrictNashvilleLogo.png
// the-district.jpg
// PLALogoLong.jpg
//
// NashCVCLogo.png
// MHC Logo.jpg
// HumanitiesTNLogo.jpg
// Horton Advertising Logo.jpg
// CHPNewLogo2014.png
// CenterForDigitalHumanitiesLogo.png
// BelmontUniversityLogo.png
// AubreyPrestonMHC.png

const Logo = ({ brand }: { brand: IBrand }) => {
  const link = brand.link

  return (
    <a href={link}>
      <Image height="auto" width="100%" src={brand.image} />
    </a>
  )
}

const Sponsors = () => {
  return (
    <Box
      pt={[0, 4]}
      pb={5}
      px={[3, 0]}
      width={[1, 600]}
      mt={[0, 4]}
      mx={'auto'}
      // sx={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}
    >
      <Flex flexDirection={['column']} justifyContent={['center']}>
        <Image width={[105]} src={mainIcon} alignSelf={['center']} />
      </Flex>

      <Heading py={3} textAlign={['left', 'center']}>
        Our Sponsors
      </Heading>

      {companies.map((brand, i) => (
        <Box sx={{ margin: '0 auto' }} maxWidth={'400px'} key={i} mt={4} px={4}>
          <Logo brand={brand} />
        </Box>
      ))}
    </Box>
  )
}

export default Sponsors
