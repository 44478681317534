import config from '../config'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { ThunkDispatch } from 'redux-thunk'
import { AppState } from '../reducers/rootReducer'
import { AnyAction } from 'redux'
import { addNewError } from './ui'

import {
  GET_ABOUT_US_CONTENT_FAILURE,
  GET_ABOUT_US_CONTENT_REQUEST,
  GET_ABOUT_US_CONTENT_SUCCESS,
} from '../constants/actionTypes'

import { IAboutUsContent } from '../interfaces'

const API_URL = config.API_URL

export const getAboutUsContentRequest = () => ({
  type: GET_ABOUT_US_CONTENT_REQUEST,
})

export const getAboutUsContentSuccess = (aboutUsContent: IAboutUsContent) => ({
  type: GET_ABOUT_US_CONTENT_SUCCESS,
  aboutUsContent,
})

export const getAboutUsContentFailure = (error: AxiosError) => ({
  type: GET_ABOUT_US_CONTENT_FAILURE,
  error,
})

export const getAboutUsContent = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(getAboutUsContentRequest())

  axios
    .get(`${API_URL}/content/about-us`)
    .then((res: AxiosResponse<IAboutUsContent>) => {
      const aboutUsContent = res.data || {}
      dispatch(getAboutUsContentSuccess(aboutUsContent))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(getAboutUsContentFailure(err))
    })
}
