import React, { FC, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'

import { cmsPostStaffMember } from '../../../actions/cms/cmsContent'
import { AppState } from '../../../reducers/rootReducer'
import { Box, Image, Heading } from 'rebass'
import { TextField } from '@material-ui/core'

import Button from '../../common/Button'
//import MediaList from '../common/MediaList'
import FileUploader from '../common/FileUploader'
import { IUploadedFile, IStaffer, IStafferNew } from '../../../interfaces'
import firebaseFileUpload from '../../../helpers/firebaseFileUpload'

interface AdminStaffMemberProps extends RouteComponentProps<{ id: string }> {
  id: string
  cmsPostStaffMember: (staff: IStaffer|IStafferNew) => Promise<any>
  staff: IStaffer[]
}

const AdminStaffMember: FC<AdminStaffMemberProps> = ({
  cmsPostStaffMember,
  history,
  match,
  staff,
}) => {
  const staffId: string | null = match.params.id || null
  
  const defaultStaffMember: IStafferNew = {
    titleLine: '',
    bio: '',
    sortOrder: 0,
    image: {
      src: '',
      alt: '',
    },
  }

  // todo fire this logic elsewhere so this component is dumber
  const _staffMember =
    staffId &&
    staff.reduce((acc: IStaffer | null, s: IStaffer) => {
      if (staffId === s.id) {
        return s
      } else {
        return acc
      }
    }, null)

  // use the one we found or fall back to a default
  const [staffMember, setStaffMember] = useState<IStaffer|IStafferNew>(
    _staffMember || defaultStaffMember
  )

  const staffImageSrc =
    staffMember && staffMember.image && staffMember.image.src

  const handleSave = (staffMember: IStaffer|IStafferNew) => {    
    cmsPostStaffMember(staffMember).then(() => {
      history.push('/admin/content')
    })
  }

  // use FileUploader to get a file object
  // pass the file object to the firebaseFileUpload function
  // get the path of the file as a result
  // set the path on the image.src property
  const onUpload = (file: IUploadedFile) => {
    //console.info(file)
    firebaseFileUpload(file, 'headshots')
      .then(({ type, path, url }) => {
        //console.info(type, path, url)
        updateStaffImage(url)
      })
      .catch(err => {
        console.error(err)
      })
  }

  const updateStaffImage = (path?: string) => {
    const staffUpdate = {
      ...staffMember,
      image: {
        src: path,
        alt: '',
      },
    }

    setStaffMember(staffUpdate)
  }

  const updateTitle = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setStaffMember({
      ...staffMember,
      titleLine: ev.target.value,
    })
  }

  const updateSortOrder = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setStaffMember({
      ...staffMember,
      sortOrder: parseInt(ev.target.value),
    })
  }

  const updateBio = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    setStaffMember({
      ...staffMember,
      bio: ev.target.value,
    })
  }

  return (
    <Box
      sx={{
        margin: '16px 0',
        padding: '16px 0',
      }}
    >
      <Heading fontSize={2} fontWeight={600}>
        Title
      </Heading>

      <TextField
        multiline={false}
        rows={5}
        margin="dense"
        variant="outlined"
        fullWidth={true}
        value={staffMember.titleLine}
        onChange={updateTitle}
      />

      <Heading fontSize={2} fontWeight={600}>
        Sort Order
      </Heading>

      <TextField
        multiline={false}
        rows={5}
        margin="dense"
        variant="outlined"
        fullWidth={true}
        value={staffMember.sortOrder}
        onChange={updateSortOrder}
        type="number"
      />

      <Heading mt={3} fontSize={2} fontWeight={600}>
        Image
      </Heading>

      {staffImageSrc && (
        <Image src={staffMember.image && staffMember.image.src} />
      )}

      <FileUploader onUpload={onUpload}></FileUploader>

      <Heading mt={3} fontSize={2} fontWeight={600}>
        Bio
      </Heading>

      <TextField
        multiline={true}
        rows={5}
        margin="dense"
        variant="outlined"
        fullWidth={true}
        value={staffMember.bio}
        onChange={updateBio}
      />

      <Button
        onClick={() => {
          handleSave(staffMember)
        }}
      >
        Save
      </Button>
    </Box>
  )
}

const mapStateToProps = (state: AppState) => ({
  staff: state.content.aboutUs.staff,
})

const mapDispatchToProps = {
  cmsPostStaffMember,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminStaffMember)
