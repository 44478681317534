import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { Box, BoxProps } from 'rebass'
import { IUploadedFile } from '../../../interfaces'

interface IFileUploaderProps {
  onUpload: (x: IUploadedFile) => void
}
const FileUploader: React.FC<IFileUploaderProps> = ({ onUpload }) => {
  const onDrop = useCallback(
    acceptedFiles => {
      acceptedFiles.forEach((file: File) => onUpload({ file }))
    },
    [onUpload]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <Box
      p={3}
      sx={{ height: '250px', width: '350px', backgroundColor: '#eee' }}
      {...(getRootProps() as BoxProps)}
    >
      <input {...getInputProps()} />

      {isDragActive ? (
        <Box>Drop the files here ...</Box>
      ) : (
        <Box>Drop files here or click to upload</Box>
      )}
    </Box>
  )
}

export default FileUploader
