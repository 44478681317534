import React, { FC, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
//import FileUploader from '../common/FileUploader'
import {
  cmsGetAboutUsContent,
  cmsDeleteStaffMember,
  cmsPostAboutUsContent,
} from '../../../actions/cms/cmsContent'
import { AppState } from '../../../reducers/rootReducer'
import { Box, Image, Heading } from 'rebass'
import { TextField } from '@material-ui/core'

import Button from '../../common/Button'

import {
  ICurriculumTeamMember,
  IStaffer,
  IContactInfo,
} from '../../../interfaces'

interface IAdminAboutUsProps extends RouteComponentProps {
  staff: IStaffer[]
  curriculumTeam: ICurriculumTeamMember[]
  contactInfo: IContactInfo
  cmsGetAboutUsContent: () => void
  cmsDeleteStaffMember: (id: string) => Promise<any>
  cmsPostAboutUsContent: (aboutUsContent: any) => void
}

const AdminAboutUs: FC<IAdminAboutUsProps> = ({
  contactInfo,
  staff,
  curriculumTeam,
  cmsGetAboutUsContent,
  cmsDeleteStaffMember,
  cmsPostAboutUsContent,
  history,
}) => {
  useEffect(() => {
    cmsGetAboutUsContent()
  }, [cmsGetAboutUsContent])

  const handleEditStaff = (id: string) => () => {
    console.info('AdminAboutUs handleEditStaff')
    history.push(`/admin/content/about-us/staff/edit/${id}`)
  }

  const handleDeleteStaff = (id: string) => () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete that staffer?')) {
      cmsDeleteStaffMember(id).then(() => {
        console.info('AdminAboutUs handleDeleteStaff')
        //history.push('/admin/content')
        cmsGetAboutUsContent()
      })
    }
  }

  const handleAddStaff = () => {
    history.push(`/admin/content/about-us/staff/new`)
  }

  return (
    <Box>
      <Box>
        <Heading fontSize={4} fontWeight={600}>
          About Us
        </Heading>

        <Box mt={3}>
          <Heading fontSize={3} fontWeight={600}>
            Contact Info
          </Heading>

          <Box mt={3}>
            <Heading fontSize={2} fontWeight={600}>
              Address
            </Heading>

            <TextField
              multiline={false}
              rows={5}
              margin="dense"
              variant="outlined"
              fullWidth={true}
              disabled
              value={contactInfo.address[0]}
              onChange={() => {}}
            />
            <TextField
              multiline={false}
              rows={5}
              margin="dense"
              variant="outlined"
              fullWidth={true}
              disabled
              value={contactInfo.address[1]}
              onChange={() => {}}
            />
            <TextField
              multiline={false}
              rows={5}
              margin="dense"
              variant="outlined"
              fullWidth={true}
              disabled
              value={contactInfo.address[2]}
              onChange={() => {}}
            />
          </Box>

          <Box mt={3}>
            <Heading fontSize={2} fontWeight={600}>
              Email
            </Heading>

            <TextField
              multiline={false}
              rows={5}
              margin="dense"
              variant="outlined"
              fullWidth={true}
              disabled
              value={contactInfo.email}
              onChange={() => {}}
            />

            <Heading fontSize={2} fontWeight={600}>
              Phone
            </Heading>

            <TextField
              multiline={false}
              rows={5}
              margin="dense"
              variant="outlined"
              fullWidth={true}
              disabled
              value={contactInfo.phone}
              onChange={() => {}}
            />
          </Box>
        </Box>

        <Box mt={5}>
          <Heading mb={3} fontSize={3} fontWeight={600}>
            Staff
          </Heading>

          <Button
            mb={3}
            onClick={() => {
              handleAddStaff()
            }}
          >
            Add Staff
          </Button>

          {staff
            .sort((a, b) => a.sortOrder - b.sortOrder)
            .map(staffMember => {
              return (
                <Box mb={3} key={staffMember.id}>
                  <Heading fontSize={1}>
                    {staffMember.sortOrder} - {staffMember.titleLine}
                  </Heading>

                  <Box maxWidth={200}>
                    <Image src={staffMember.image && staffMember.image.src} />
                  </Box>

                  <Button
                    onClick={handleEditStaff(staffMember.id)}
                    variant="outline"
                    color="blue"
                  >
                    Edit
                  </Button>

                  <Button
                    ml={2}
                    onClick={handleDeleteStaff(staffMember.id)}
                    variant="redOutline"
                    color="blue"
                  >
                    Delete
                  </Button>
                </Box>
              )
            })}
        </Box>

        <Box mt={5} mb={5}>
          <Heading fontSize={3} fontWeight={600}>
            Curriculum Team
          </Heading>

          {curriculumTeam.map(teamMember => {
            return (
              <Box key={teamMember.titleLine}>
                <TextField
                  multiline={false}
                  rows={5}
                  margin="dense"
                  variant="outlined"
                  fullWidth={true}
                  disabled
                  value={teamMember.titleLine}
                  onChange={() => {}}
                />
              </Box>
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: AppState) => ({
  contactInfo: state.content.aboutUs.contactInfo,
  staff: state.content.aboutUs.staff,
  curriculumTeam: state.content.aboutUs.curriculumTeam,
})

const mapDispatchToProps = {
  cmsGetAboutUsContent,
  cmsDeleteStaffMember,
  cmsPostAboutUsContent,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminAboutUs)
