import React from 'react'
import { Box } from 'rebass'

export default () => (
  <Box
    as="span"
    color="red"
    ml={1}
    fontSize={3}
    sx={{ position: 'relative', bottom: '2px' }}
  >
    *
  </Box>
)
