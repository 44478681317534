import React, { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { getStopsByKeywordIfNeeded } from '../../actions/stops'
import { AppState } from '../../reducers/rootReducer'
import { IStop, IStopsArrayDict } from '../../interfaces'
import { Box } from 'rebass'
import { Link } from 'react-router-dom'
import { event, EventAction, EventCategory } from '../../tracking'

interface ISearchProps extends RouteComponentProps {
  stopsByKeyword: IStopsArrayDict
  getStopsByKeywordIfNeeded: (keyword: string) => void
}
const Search: React.FC<ISearchProps> = ({
  getStopsByKeywordIfNeeded,
  location,
  stopsByKeyword,
}) => {
  const params = new URLSearchParams(location.search)
  const keyword = params.get('keyword')
  const stopsForThisKeyword = stopsByKeyword[keyword || ''] || []

  useEffect(() => {
    if (keyword) {
      getStopsByKeywordIfNeeded(keyword)

      event({
        category: EventCategory.Search,
        action: EventAction.SearchExecuted,
        label: keyword,
      })
    }
  }, [keyword, getStopsByKeywordIfNeeded])

  if (keyword === null) {
    return <div>No search keyword</div>
  }

  return (
    <Box px={3} pb={[3]}>
      <h1>Search Results</h1>
      <h2>Tour Stops</h2>
      <ul>
        {stopsForThisKeyword.map((stop: IStop) => {
          return (
            <li key={stop.id}>
              <Link to={`/tours/${stop.tourId}/${stop.id}`}>
                {stop.siteName}
              </Link>
            </li>
          )
        })}
      </ul>
    </Box>
  )
}

export default connect(
  (state: AppState) => ({
    stopsByKeyword: state.entities.stops.byKeyword,
  }),
  {
    getStopsByKeywordIfNeeded,
  }
)(withRouter(Search))
