import React from 'react'
import { Box, Heading, Image } from 'rebass'

import Button from '../../common/Button'
import mainIcon from '../../../assets/icons/NashvilleSites_Final-high-res.png'

const Legal: React.FC<{ onAccept: () => void }> = ({ onAccept }) => {
  return (
    <Box>
      <Box sx={{ textAlign: 'center' }}>
        <Image width={[105]} src={mainIcon} />
      </Box>

      <Heading fontSize={[4]} mt={3}>
        Terms of Use
      </Heading>

      <Box as="p" mt={3}>
        All Nashville Sites content including, but not limited to, tours, text,
        audio, video, and images are protected by U.S. and International
        copyright laws. Reproduction, reuse, or distribution of content, as
        stated above, is prohibited without written permission of the sponsor.
      </Box>

      <Box as="p" mt={3}>
        When taken in person, users should be aware of the surrounding urban
        environment. While we have made every effort to ensure safe and
        accessible tours, it is the user's responsibility to obey all traffic
        signs and signals and use pedestrian sidewalks where present. When
        crossing a street or alley at any point other than a marked crosswalk,
        yield the right of way to all vehicles on the roadway.
      </Box>

      <Box as="p" mt={3}>
        It is the duty of pedestrians, for their own safety, to look before
        crossing a road, to exercise reasonable care and caution, and to keep a
        timely lookout for approaching traffic. Nashville Sites is not
        responsible for any unforeseen injury, illness, or economic loss
        associated with participating in this tour.
      </Box>

      <Box sx={{ textAlign: 'center' }}>
        <Button
          width={[1, 150]}
          mt={4}
          onClick={onAccept}
          variant="outline"
          color="blue"
        >
          I Accept
        </Button>
      </Box>
    </Box>
  )
}

export default Legal
