import { IStop } from '../interfaces'
import uuid from 'uuid'

export default (tourId: string): Partial<IStop> => {
  return {
    id: uuid.v4(),
    siteName: '',
    narrativeText: '',
    tourId,
    recordId: '',
    images: [],
    content: [],
  }
}
