import React from 'react'
import { Flex, Heading, Image, Text, Box } from 'rebass'
import mainIcon from '../assets/icons/NashvilleSites_Final-high-res.png'

const AboutUs: React.FC<{}> = props => (
  <Flex
    flexDirection="column"
    px={[3]}
    pb={[0, 4]}
    pt={[0, 4]}
    mt={[0, 4]}
    width={[1, 1]}
    alignItems={['center']}
    as="main"
  >
    <Box width={[1, 600]} pb={3}>
      <Flex flexDirection={['column']} justifyContent={['center']}>
        <Image width={[105, 0]} src={mainIcon} alignSelf={['center']} />
      </Flex>

      <Heading fontSize={4}>FAQ</Heading>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        What is Nashville Sites?
      </Heading>
      <Text>
        Nashville Sites is a web based platform that offers self-guided,
        credibly curated, mobile-friendly, free, thematically-based tours of
        Nashville. It is a website, so nothing to download.
      </Text>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        How do I take a tour on my phone?
      </Heading>
      <Text>
        Open your browser, go to nashvillesites.org, select a tour, click "Start
        Tour," agree to the Terms of Use, and begin! You can listen to the
        audio, read the narrative (using "View More" option) or read and listen
        at the same time. Click on "Next Stop" and click "Allow" when prompted
        to share your location, this will trigger the built-in GPS to help you
        get to your next stop. When you get to the next stop, click "Arrived" on
        the map.
      </Text>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        Do I need to have a certain type of phone or internet browser?
      </Heading>
      <Text>
        Nope! But Google and Safari are the most often used. If you are having
        trouble with tour audio and images loading, you might try turning off
        the wi-fi in your settings or refreshing the webpage.
      </Text>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        Do I have to pay to take a tour?
      </Heading>
      <Text>No. All of our tours are free of cost and advertisement free.</Text>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        Is there parking near the starting point of my tour?
      </Heading>
      <Text>
        If your tour begins downtown, we suggest checking out the Music City
        Center and Nashville Public Library garages. Users can find metered
        parking along Korean Veterans Boulevard. There are many other
        pay-to-park lots and decks in downtown. If you are staying in or around
        downtown, we recommend exploring ride-share and taxi options or walking
        to your starting point. For public transit information in English and
        Spanish, visit Nashville MTA Maps and Schedules on their website.
      </Text>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        How do I get to the starting point of my tour?
      </Heading>
      <Text>
        You can use the main map at the bottom of the homepage. You can also
        click "Get Directions" on the main screen for individual tours. In
        addition, at the end of each tour introduction there are directions
        about where to start the tour.
      </Text>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        What if the audio does not load or my device seems to be stuck?
      </Heading>
      <Text>
        Because Nashville Sites is a web-based program, sometimes loading can
        take a while. If your are having issues with audio, images, or tour
        stops loading or advancing first try to refresh the webpage using the
        refresh button in your web broswer (web address bar at the top). Also,
        phones can be slowed by wi-fi hotspots, which can slow things down. You
        might also try turning off your wi-fi in your settings to improve speed,
        although it may affect your GPS capability.
      </Text>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        Do I have to be in Nashville? Can I take a tour on my desktop, tablet,
        or laptop computer?
      </Heading>
      <Text>
        You can take a Nashville Sites anytime, anywhere, on any device! Tip: If
        you take a tour remotely, do not allow your browser to use your
        location.
      </Text>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        Why do you need my location?
      </Heading>
      <Text>
        We can only provide GPS mapping once you have given us permission to use
        your location. If you don't want us to have access while you are on our
        site, you can navigate your way through the tour using the written
        directions located at the bottom of each tour stop.
      </Text>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        Are there places to eat or go to the restroom along your tour routes?
      </Heading>
      <Text>
        Usually! While each tour route differs, most routes pass at least one
        food establishment. Tip: Research the tour area before departure. Public
        restrooms can be found in the Music City Center, Country Music Hall of
        Fame complex, and the Nashville Public Library.
      </Text>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        Can the tours be taken on any day at any time?
      </Heading>
      <Text>
        Yep! Our tours are self-guided, so it's all up to you! Tip: Look up
        business hours before taking a tour if you plan on entering, visiting,
        or touring sites that operate as a place of business.
      </Text>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        Do I need headphones to take the tour?
      </Heading>
      <Text>
        Not necessarily, but it's easier to listen to the narration with
        headphones. Whether you blast it from your phone or wear headphones,
        remember: our tour routes are on some of the busiest roads in Nashville,
        so pay attention.
      </Text>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        I have my kids with me, are these good tours for families?
      </Heading>
      <Text>
        Of course! Nearly all of our tours are family-friendly. We even have a
        family fun tour! Tip: Look up age restrictions before taking a tour if
        you plan on entering, visiting, or touring sites that operate as a place
        of business.
      </Text>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        Can a group take a tour?
      </Heading>
      <Text>
        Our tours are self-guided, so it's up to you! You can take it alone or
        get the whole gang together.
      </Text>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        Do the tours take me inside buildings?
      </Heading>
      <Text>
        Not often. However, many of our tour stops are functioning businesses.
        If they are open, we highly reccommend popping in to get the full Music
        City experience.
      </Text>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        Are tours handicapped accessible?
      </Heading>
      <Text>
        We aim to improve all forms of accessibility and are constantly working
        on our plaform to make it available to anybody and everybody.
        WHEELCHAIRS: The majority of our tours are handicapped and wheelchair
        accessible. It should be noted that nearly all of our tours take place
        on public sidewalks, which may be subjected to unexpected construction.
        Tip: You can access the Tennessee State Capitol through a wheelchair
        accessible entrance, but you must call ahead to arrange your visit.
        VISUAL AND AUDIO IMPAIRMENTS: All of our tours feature audio narration
        and written narration. It should be noted that nearly all of our tours
        take place on public sidewalks.
      </Text>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        Do you have Nashville Sites merchandise?
      </Heading>
      <Text>
        Yes. Email info@nashvillesites.org for info on merch like stickers,
        magnets, t-shirts, and tote bags.
      </Text>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        Do you offer guided tours?
      </Heading>
      <Text>
        The beauty of Nashville Sites is that it is free, ad-free, and
        self-guided, although from time to time we may offer live tours for
        special events. Email info@nashvillesites.org for more information.
      </Text>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        How do I get in contact with Nashville Sites?
      </Heading>
      <Text>
        For general questions, please email info@nashvillesites.org. For press
        and media inquiries, please email Ali Humbrecht at
        humbrecht@nashvillesites.org. For tour-specific inquiries, please email
        Jessica Reeves at reeves@nashvillesites.org. Or you can reach out to us
        on social media: Facebook, Instagram, and Twitter.
      </Text>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        Can I reuse your images?
      </Heading>
      <Text>
        Maybe! Any image that credits MHC (Metro Historical Commission), MHCF
        (Metro Historical Commission Foundation), can be used if appropriate
        credit is given. In order to use any other image on our site, please
        refer to the image's site of origin for copyright and usage information.
      </Text>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        How do I cite your information for school projects, blogs, or other
        publications?
      </Heading>
      <Text>
        Our tours are academic and scholarly. We provide metadata, meaning we
        provide the information needed to accurately cite our tour narrative. If
        you click on any "Full Record" you will see the "Cite As" field that you
        can copy and paste into your bibliography.
      </Text>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        Who writes your tours? Can I suggest a topic or write a tour?
      </Heading>
      <Text>
        Maybe! Tours must be written by qualified scholars with expertise in
        their tour topic. If interested in contributing, please contact Jessica
        Reeves at reeves@nashvillesites.org.
      </Text>

      <Heading fontSize={2} color={['#555']} mt={4} pb={1}>
        What do I do if I find a typo or accuracy error?
      </Heading>
      <Text>
        We are committed to providing accurate information. Our tours are all
        credibly-curated, but sometimes we miss mistakes. Send corrections to
        Jessica Reeves at reeves@nashvillesites.org.
      </Text>
    </Box>
  </Flex>
)

export default AboutUs
