import config from '../../config'
import {
  CMS_GET_ABOUT_US_CONTENT_FAILURE,
  CMS_GET_ABOUT_US_CONTENT_REQUEST,
  CMS_GET_ABOUT_US_CONTENT_SUCCESS,
  CMS_POST_ABOUT_US_CONTENT_FAILURE,
  CMS_POST_ABOUT_US_CONTENT_REQUEST,
  CMS_POST_ABOUT_US_CONTENT_SUCCESS,
  CMS_POST_STAFF_MEMBER_FAILURE,
  CMS_POST_STAFF_MEMBER_REQUEST,
  CMS_POST_STAFF_MEMBER_SUCCESS,
  CMS_DELETE_STAFF_MEMBER_FAILURE,
  CMS_DELETE_STAFF_MEMBER_REQUEST,
  CMS_DELETE_STAFF_MEMBER_SUCCESS,
} from '../../constants/actionTypes'

// import interaces

import { AxiosError, AxiosResponse } from 'axios'
import cmsAxios from './cmsNet'

import { AppState } from '../../reducers/rootReducer'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { addNewError } from '../ui'

interface IAboutUsContent {
  id: string
}

interface IStaffMember {
  id: string
  titleLine: string
  bio: string
  image: {
    src: string
    alt: string
  }
}

// GET About Us Content
/////////////////////////////////////////////////////////////////////////////////
export const cmsGetAboutUsContentRequest = () => ({
  type: CMS_GET_ABOUT_US_CONTENT_REQUEST,
})

export const cmsGetAboutUsContentSuccess = (aboutUsContent: any) => ({
  type: CMS_GET_ABOUT_US_CONTENT_SUCCESS,
  aboutUsContent,
})

export const cmsGetAboutUsContentFailure = (error: AxiosError) => ({
  type: CMS_GET_ABOUT_US_CONTENT_FAILURE,
  error,
})

export const cmsGetAboutUsContent = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(cmsGetAboutUsContentRequest())

  cmsAxios
    .get(`${config.CMS_API_URL}/content/about-us`)
    .then((res: AxiosResponse<IAboutUsContent>) => {
      const aboutUsContent = res.data || []
      dispatch(cmsGetAboutUsContentSuccess(aboutUsContent))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(cmsGetAboutUsContentFailure(err))
    })
}

// POST About Us Content
/////////////////////////////////////////////////////////////////////////////////
export const cmsPostAboutUsContentRequest = () => ({
  type: CMS_POST_ABOUT_US_CONTENT_REQUEST,
})

export const cmsPostAboutUsContentSuccess = (aboutUsContent: any) => ({
  type: CMS_POST_ABOUT_US_CONTENT_SUCCESS,
  aboutUsContent,
})

export const cmsPostAboutUsContentFailure = (error: AxiosError) => ({
  type: CMS_POST_ABOUT_US_CONTENT_FAILURE,
  error,
})

export const cmsPostAboutUsContent = (aboutUsContent: any) => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(cmsPostAboutUsContentRequest())

  cmsAxios
    .post(`${config.CMS_API_URL}/content/about-us`, aboutUsContent)
    .then((res: AxiosResponse<IAboutUsContent>) => {
      const aboutUsContent = res.data || []
      dispatch(cmsPostAboutUsContentSuccess(aboutUsContent))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(cmsPostAboutUsContentFailure(err))
    })
}

// POST Staff Member
/////////////////////////////////////////////////////////////////////////////////
export const cmsPostStaffMemberRequest = () => ({
  type: CMS_POST_STAFF_MEMBER_REQUEST,
})

export const cmsPostStaffMemberSuccess = (aboutUsContent: any) => ({
  type: CMS_POST_STAFF_MEMBER_SUCCESS,
  aboutUsContent,
})

export const cmsPostStaffMemberFailure = (error: AxiosError) => ({
  type: CMS_POST_STAFF_MEMBER_FAILURE,
  error,
})

export const cmsPostStaffMember = (staffMember: any) => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(cmsPostStaffMemberRequest())

  if (staffMember.id) {
    return cmsAxios
      .post(
        `${config.CMS_API_URL}/content/staff/${staffMember.id}`,
        staffMember
      )
      .then((res: AxiosResponse<IStaffMember>) => {
        const staffMember = res.data || {}
        dispatch(cmsPostStaffMemberSuccess(staffMember))
      })
      .catch((err: AxiosError) => {
        dispatch(addNewError(err))
        dispatch(cmsPostStaffMemberFailure(err))
      })
  } else {
    return cmsAxios
      .post(`${config.CMS_API_URL}/content/staff`, staffMember)
      .then((res: AxiosResponse<IStaffMember>) => {
        const staffMember = res.data || []
        dispatch(cmsPostStaffMemberSuccess(staffMember))
      })
      .catch((err: AxiosError) => {
        dispatch(addNewError(err))
        dispatch(cmsPostStaffMemberFailure(err))
      })
  }
}

// DELETE Staff Member
/////////////////////////////////////////////////////////////////////////////////
export const cmsDeleteStaffMemberRequest = () => ({
  type: CMS_DELETE_STAFF_MEMBER_REQUEST,
})

export const cmsDeleteStaffMemberSuccess = (id: string) => ({
  type: CMS_DELETE_STAFF_MEMBER_SUCCESS,
  id,
})

export const cmsDeleteStaffMemberFailure = (error: AxiosError) => ({
  type: CMS_DELETE_STAFF_MEMBER_FAILURE,
  error,
})

export const cmsDeleteStaffMember = (id: string) => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(cmsDeleteStaffMemberRequest())

  return cmsAxios
    .delete(`${config.CMS_API_URL}/content/staff/${id}`)
    .then((res: AxiosResponse<IStaffMember>) => {
      dispatch(cmsDeleteStaffMemberSuccess(id))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(cmsDeleteStaffMemberFailure(err))
    })
}
