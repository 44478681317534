import {
  CMS_DELETE_RECORD_FAILURE,
  CMS_DELETE_RECORD_REQUEST,
  CMS_DELETE_RECORD_SUCCESS,
  CMS_GET_RECORDS_FAILURE,
  CMS_GET_RECORDS_REQUEST,
  CMS_GET_RECORDS_SUCCESS,
  CMS_SAVE_RECORD_FAILURE,
  CMS_SAVE_RECORD_REQUEST,
  CMS_SAVE_RECORD_SUCCESS,
  CMS_SET_EDITED_RECORD,
  CMS_SET_SELECTED_RECORD_TYPE,
} from '../../constants/actionTypes'
import { IEditedItem, IRecord } from '../../interfaces'
import { AxiosError, AxiosResponse } from 'axios'
import cmsAxios from './cmsNet'
import { ThunkDispatch } from 'redux-thunk'
import { AppState } from '../../reducers/rootReducer'
import { AnyAction } from 'redux'
import config from '../../config'
import { addNewError } from '../ui'

const CMS_API_URL = config.CMS_API_URL

export const cmsGetRecordsRequest = () => ({
  type: CMS_GET_RECORDS_REQUEST,
})

export const cmsGetRecordsSuccess = (records: IRecord[]) => ({
  type: CMS_GET_RECORDS_SUCCESS,
  records,
})

export const cmsGetRecordsFailure = (error: AxiosError) => ({
  type: CMS_GET_RECORDS_FAILURE,
  error,
})

export const cmsGetRecords = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(cmsGetRecordsRequest())

  cmsAxios
    .get(`${CMS_API_URL}/records`)
    .then((res: AxiosResponse<IRecord[]>) => {
      const records = res.data || []
      dispatch(cmsGetRecordsSuccess(records))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(cmsGetRecordsFailure(err))
      throw err
    })
}

export const cmsGetRecordsIfNeeded = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>,
  getState: () => AppState
) => {
  const { cms } = getState()

  if (!cms.records.isCached.cmsGetRecords) {
    dispatch(cmsGetRecords())
  }
}

export const cmsSetEditedRecord = (record: IEditedItem<IRecord> | null) => ({
  type: CMS_SET_EDITED_RECORD,
  record,
})

export const cmsSaveRecordRequest = () => ({
  type: CMS_SAVE_RECORD_REQUEST,
})

export const cmsSaveRecordSuccess = (record: IRecord) => ({
  type: CMS_SAVE_RECORD_SUCCESS,
  record,
})

export const cmsSaveRecordFailure = (error: AxiosError) => ({
  type: CMS_SAVE_RECORD_FAILURE,
  error,
})

export const cmsSaveRecord = (record: IRecord, isNew: boolean) => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(cmsSaveRecordRequest())

  const method = isNew ? 'post' : 'put'
  const url = isNew
    ? `${CMS_API_URL}/records`
    : `${CMS_API_URL}/records/${record.id}`

  return cmsAxios[method](url, record)
    .then((res: AxiosResponse<IRecord>) => {
      dispatch(cmsSaveRecordSuccess(res.data))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(cmsSaveRecordFailure(err))
      throw err
    })
}

export const cmsDeleteRecordRequest = () => ({
  type: CMS_DELETE_RECORD_REQUEST,
})

export const cmsDeleteRecordSuccess = (record: Partial<IRecord>) => ({
  type: CMS_DELETE_RECORD_SUCCESS,
  record,
})

export const cmsDeleteRecordFailure = (error: AxiosError) => ({
  type: CMS_DELETE_RECORD_FAILURE,
  error,
})

export const cmsDeleteRecord = (record: Partial<IRecord>) => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(cmsDeleteRecordRequest())

  return cmsAxios
    .delete(`${CMS_API_URL}/records/${record.id}`)
    .then(() => {
      dispatch(cmsDeleteRecordSuccess(record))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(cmsDeleteRecordFailure(err))
      throw err
    })
}

export const cmsSetSelectedRecordtype = (recordType: string) => ({
  type: CMS_SET_SELECTED_RECORD_TYPE,
  recordType,
})
