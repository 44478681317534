export interface IToursState {
  byId: IToursDict
  bySlug: IToursDict
  allIds: string[]
  isLoading: boolean
  isCached: {
    getTours?: boolean
    cmsGetTours?: boolean
  }
}

interface IToursDict {
  [Key: string]: ITour
}

export interface ITour {
  id: string
  slug: string
  title: string
  categories: string[]
  description: string
  intro: string
  outro: string
  images: IImage[]
  directions?: IDirectionStep[]
  duration: number
  distance: number
  stopIds: string[]
  notes: string[]
  map: string // Mapbox style url
  tourMapZoomLevel: number
  nextStopMapZoomLevel: number
  parking?: string
  introAudio: IAudio
  outroAudio: IAudio
  parkingAudio?: IAudio
}

export type MediaType = 'All' | 'Image' | 'Audio' | 'Video'

export interface IImage {
  path: string
  caption: string
}
export interface IAudio {
  path: string
}
export interface IVideo {
  path: string
}

export type IMedia = IImage | IAudio | IVideo

export function isImage(media: IMedia | IImage): media is IImage {
  return !!(media as IImage).caption != null
}

interface IDirectionStep {
  id: string
  text: string
  start: string
  stop: string
  audio: IAudio
}
