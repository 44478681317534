import { combineReducers } from 'redux'
import uiReducer from './uiReducer'
import toursReducer from './toursReducer'
import stopsReducer from './stopsReducer'
import recordsReducer from './recordsReducer'
import landingPageReducer from './landingPageReducer'
import cmsReducer from './cms'
import { featuredAndSocialReducer } from './featuredAndSocialReducer'
import contentReducer from './contentReducer'

const rootReducer = combineReducers({
  ui: uiReducer,
  landingPages: landingPageReducer,
  entities: combineReducers({
    tours: toursReducer,
    records: recordsReducer,
    stops: stopsReducer,
    featuredAndSocial: featuredAndSocialReducer,
  }),
  cms: cmsReducer,
  content: contentReducer,
})

export type AppState = ReturnType<typeof rootReducer>
export default rootReducer
