import { AnyAction } from 'redux'

import { ITour } from '../interfaces/tourInterfaces'
import { IStop } from '../interfaces/stopInterfaces'

interface ILandingPageState {
  walkingTours: any
  selectedTour: ITour | null
  initialStop: IStop | null
  recordId: string | null
}

const initialState: ILandingPageState = {
  walkingTours: {},
  selectedTour: null,
  initialStop: null,
  recordId: null,
}

const landingPagesReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case 'SET_LANDING_TOURS':
      return {
        ...state,
        walkingTours: {
          tours: action.tours,
        },
      }

    case 'SET_SELECTED_TOUR':
      return {
        ...state,
        selectedTour: action.selectedTour,
      }

    case 'SET_INITIAL_STOP':
      return {
        ...state,
        initialStop: action.initialStop,
      }

    case 'SET_RECORD_ID':
      return {
        ...state,
        recordId: action.recordId,
      }

    default:
      return state
  }
}

export default landingPagesReducer
