import React from 'react'
import { History } from 'history'
import { Flex, Heading } from 'rebass'
import Button from './Button'

interface ITourCategoryCTAProps {
  title: string
  backgroundImage: string
  //tour: ITour
  history: History
}

const TourCategoryCTA: React.FC<ITourCategoryCTAProps> = ({
  history,
  title,
  backgroundImage,
  //tour
}) => {
  const params = new URLSearchParams()
  params.set('cat', title)

  const onCTAClick = () => {
    history.push(`/tours?${params.toString()}`)
  }

  return (
    <Flex
      flexDirection={'column'}
      justifyContent={'flex-end'}
      sx={{
        minHeight: '365px',
        padding: '0 24px 24px 24px',
        backgroundSize: 'cover',
        backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%), url(${backgroundImage})`,
      }}
    >
      <Heading color={'white'} mb={[3]}>
        {title}
      </Heading>

      <Button
        sx={{
          width: '100%',
          height: '44px',
          cursor: 'pointer',
        }}
        onClick={onCTAClick}
        //width={[1]}
        variant="redPrimary"
      >
        View Tours
      </Button>
    </Flex>
  )
}

export default TourCategoryCTA
