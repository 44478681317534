import React from 'react'
import { Heading, Text, Box, Flex } from 'rebass'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { IStopWithRecord, ITour } from '../../../interfaces'
import { blue } from '../../../theme'

const NumberBadge: any = styled(Text)`
  width: 22px;
  height: 22px;
  border-radius: 100%;
  flex-shrink: 0;
  text-align: center;
  background-color: ${(props: any) =>
    props.isCurrentStop ? blue : 'transparent'};
  color: ${(props: any) => (props.isCurrentStop ? 'white' : 'black')};
`

const StyledLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`

interface ITourStopListItemProps extends IStopWithRecord {
  isCurrentStop: boolean
  numberBadge: number
  tour: ITour
  isVirtual: boolean
}

const TourStopListItem: React.FC<ITourStopListItemProps> = props => {
  // inline ternary
  const stopHref = `/tours/${props.tour.slug}${props.isVirtual ? '/v' : ''}/${
    props.slug
  }`

  const stopLink = props.isCurrentStop ? (
    <Text color="blue">You are on this stop</Text>
  ) : (
    <StyledLink to={stopHref}>
      <Text color="blue" fontWeight="bold">
        View Stop
      </Text>
    </StyledLink>
  )

  return (
    <Box mb={[3]}>
      <Flex>
        <NumberBadge isCurrentStop={props.isCurrentStop}>
          {props.numberBadge}.
        </NumberBadge>
        <Box ml={2} flex="1 1 0">
          <Heading fontSize={[2]} mb={[1]} fontWeight={600}>
            {props.siteName}
          </Heading>

          {stopLink}
        </Box>
      </Flex>
    </Box>
  )
}

export default TourStopListItem
