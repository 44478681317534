import React, { useEffect } from 'react'
import { Box, Flex, Image, Link } from 'rebass'
import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import facebook from '../assets/icons/facebook.png'
import twitter from '../assets/icons/twitter.png'
import instagram from '../assets/icons/instagram.png'
import tiktok from '../assets/icons/tiktok.png'
//import linkedIn from '../assets/icons/linkedin-icon.png'
import spotify from '../assets/icons/spotify-icon.png'
import footerIcon from '../assets/icons/MHCFLogoWHITEBackground.png'
import fogLogo from '../assets/images/fog-logo-white.svg'
import { INavLink, ISocialLinks } from '../interfaces'
import { AppState } from '../reducers/rootReducer'
import { getSocialLinksIfNeeded } from '../actions/featuredAndSocial'
import { MailOutline } from '@material-ui/icons'

const StyledLink = styled(RouterLink)`
  text-decoration: none;
  cursor: pointer;
  display: block;
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 15px;
  color: white;
`

const renderLink = (link: INavLink, i: number) => (
  <Box mx={[0, 3]} key={i}>
    <StyledLink to={link.path}>{link.name}</StyledLink>
  </Box>
)

interface IFooterProps extends RouteComponentProps {
  links: INavLink[]
  socialLinks: ISocialLinks | null
  getSocialLinks: () => void
}
const Footer: React.FC<IFooterProps> = ({
  getSocialLinks,
  links,
  socialLinks,
  location,
}) => {
  useEffect(() => {
    getSocialLinks()
  }, [getSocialLinks])

  const inTour = !!location.pathname.match(/\/tours\/.*/)

  return (
    <Flex
      width={[1]}
      bg="darkGrey"
      color="white"
      px={[3]}
      pt={[2, 4]}
      pb={[inTour ? 150 : 4]}
      flexDirection={['column', 'column', 'row']}
      alignItems={['center']}
      justifyContent={['center']}
      flex={['0 0 0']}
      as="footer"
      style={{ position: 'relative' }}
    >
      <Link
        href="https://www.nashville.gov/Historical-Commission.aspx"
        target={'_blank'}
      >
        <Image
          src={footerIcon}
          width={[80]}
          alignSelf="center"
          mt={[3]}
          mb={[2]}
          mr={[0, 2]}
        />
      </Link>

      {links.map(renderLink)}

      {socialLinks && (
        <Flex alignItems="center" pt={[4, 4, 0]} flex="0 0 auto">
          {socialLinks.instagram && (
            <Link href={socialLinks.instagram} target="_blank">
              <Image src={instagram} width={[26]} ml={[0, 0, 4]} />
            </Link>
          )}

          {socialLinks.twitter && (
            <Link href={socialLinks.twitter} target="_blank">
              <Image src={twitter} width={[26]} ml={4} />
            </Link>
          )}
          {socialLinks.facebook && (
            <Link href={socialLinks.facebook} target="_blank">
              <Image src={facebook} width={[26]} ml={[4]} />
            </Link>
          )}

          {socialLinks.tiktok && (
            <Link href={socialLinks.tiktok} target="_blank">
              <Image src={tiktok} width={[26]} ml={[0, 0, 4]} />
            </Link>
          )}

          <Link
            href="https://open.spotify.com/user/cayynnu6pwkgkmyu3sfzwvatb?si=8uml0l5XQF2SJJVUy-sBoA"
            target="_blank"
          >
            <Image src={spotify} width={[26]} mx={4} />
          </Link>

          <Link href="mailto:info@nashvillesites.org">
            <MailOutline
              fontSize="large"
              style={{ color: 'white', display: 'inline' }}
            />
          </Link>

          {/* <Text> Built from the past with an eye to the future by</Text> */}
          <Link href="https://fog.haus" target="_blank">
            <Image height={'auto'} width={'50px'} ml={4} src={fogLogo} />
          </Link>
        </Flex>
      )}
    </Flex>
  )
}
const mapStateToProps = (state: AppState) => ({
  links: state.ui.links,
  socialLinks: state.entities.featuredAndSocial.socialLinks,
})

const mapDispatchToProps = {
  getSocialLinks: getSocialLinksIfNeeded,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer))
