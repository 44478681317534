import preset from '@rebass/preset'

export const blue = '#1F4695'
export const blueAlpha = 'rgba(31,70,149,0.1)'
export const red = '#ea242a'
export const white = 'white'
export const darkGrey = '#333333'
export const lightGrey = '#666666'
export const lighterGrey = '#e6e6e6'
export const whiteGrey = '#F5F7F9'
export const transparent = 'transparent'
export const gold = '#FFBB02'

export const bodyFontStack = 'Gotham, sans-serif'
export const headingFontStack = 'Neutra Text, sans-serif'

const customTheme = {
  colors: {
    blue,
    white,
    darkGrey,
    lightGrey,
    lighterGrey,
    red,
  },
  fonts: {
    body: bodyFontStack,
    heading: headingFontStack,
  },
  text: {
    color: darkGrey,
  },
  buttons: {
    primary: {
      color: white,
      backgroundColor: blue,
    },
    redPrimary: {
      color: white,
      backgroundColor: red,
    },
    redOutline: {
      color: red,
      backgroundColor: white,
      boxShadow: 'inset 0 0 0 1px',
    },
    outline: {
      backgroundColor: transparent,
      boxShadow: 'inset 0 0 0 1px',
      color: blue,
    },
    gold: {
      color: darkGrey,
      backgroundColor: gold,
    },
  },
  variants: {
    navLink: {
      color: blue,
      textDecoration: 'none',
    },
    goldButton: {
      textDecoration: 'none',
      backgroundColor: gold,
      padding: '8px 16px',
      color: darkGrey,
      borderRadius: '4px',
      display: 'inline',
      fontFamily: 'Open Sans, sans-serif',
    },
    topBand: {
      backgroundColor: whiteGrey,
    },
    bigAlert: {
      blue: {
        backgroundColor: blueAlpha,
        borderColor: blue,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '2px',
        padding: '16px',
      },
    },
  },
}

const theme = {
  ...preset,
  ...customTheme,
}

export type Theme = typeof theme
export default theme
