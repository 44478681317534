import React, { useState, useEffect, ChangeEvent } from 'react'
import { useLocation } from 'react-router-dom'
import { Flex, Heading, Box } from 'rebass'
import { connect } from 'react-redux'
import TourSummary from '../common/TourSummary'
import TourLocations from '../common/TourLocations'
import { getToursIfNeeded } from '../../actions/tours'
import { AppState } from '../../reducers/rootReducer'
import { IToursState } from '../../interfaces'

import { Select, MenuItem } from '@material-ui/core'

interface IToursProps {
  getToursIfNeeded: () => void
  tours: IToursState
}
const Tours: React.FC<IToursProps> = ({ getToursIfNeeded, tours }) => {
  useEffect(() => {
    getToursIfNeeded()
  }, [getToursIfNeeded])

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const filterParam = queryParams.get('cat')
  console.info('qs filter param', filterParam)

  const [categoryFilter, setCategoryFilter] = useState<string>(
    filterParam || 'All Tours'
  )

  const toursToRender = tours.allIds
    .map(tourId => tours.byId[tourId])
    .filter(tour => {
      return categoryFilter === 'All Tours'
        ? true
        : !!tour.categories &&
            tour.categories.some(cat => cat === categoryFilter)
    })
    .sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1))

  const onSelectCategoryFilter = (ev: ChangeEvent<{ value: unknown }>) => {
    const value = ev.target.value as string
    setCategoryFilter(value)
  }

  return (
    <Flex
      mt={[0, 4]}
      px={[3]}
      pt={[0, 4]}
      pb={[4]}
      width={[1]}
      as="main"
      flexDirection="column"
      alignItems={['center']}
    >
      <Box width={[1, 860]}>
        <Heading mb={[3]} fontSize={[4]}>
          Tours
        </Heading>

        <Select
          margin="dense"
          variant="outlined"
          fullWidth={true}
          value={categoryFilter}
          onChange={onSelectCategoryFilter}
        >
          <MenuItem value={'All Tours'}>All Tours</MenuItem>

          <MenuItem value={'Downtown Nashville Tours'}>
            Downtown Nashville Tours
          </MenuItem>

          <MenuItem value={'Neighborhoods and Campuses'}>
            Neighborhoods and Campuses
          </MenuItem>

          <MenuItem value={'Driving Tours'}>Driving Tours</MenuItem>

          <MenuItem value={'Tours with Lesson Plans'}>
            Tours with Lesson Plans
          </MenuItem>
        </Select>

        <Flex
          mt={[4]}
          flexWrap="wrap"
          flexDirection={['row']}
          justifyContent={['center', 'space-between']}
        >
          {tours.isLoading ? (
            <div>Loading...</div>
          ) : (
            toursToRender.map(tour => (
              <TourSummary
                key={tour.id}
                tour={tour}
                clickToStartTour={true}
                mb={[4]}
              />
            ))
          )}
        </Flex>

        <Heading mt={[5]} mb={[2]} fontSize={[4]}>
          Map of tour locations
        </Heading>
        <TourLocations />
      </Box>
    </Flex>
  )
}

const mapStateToProps = (state: AppState): { tours: IToursState } => ({
  tours: state.entities.tours,
})

const mapDispatchToProps = {
  getToursIfNeeded,
}

export default connect(mapStateToProps, mapDispatchToProps)(Tours)
