import _ from 'lodash'
import { IQRCode, IRecord, IStop, IStopWithRecord, ITour } from '../interfaces'

type NormalizeEntities = <E extends IRecord | IQRCode>(
  e: E[]
) => { byId: Record<string, E>; allIds: string[] }

const normalizeEntities: NormalizeEntities = e => {
  const allIds = e.map(t => t.id)
  const byId = _.keyBy(e, 'id')

  return { byId, allIds }
}

type NormalizeEntitiesWithSlug = <
  E extends ITour | IStop | IStopWithRecord | IRecord
>(
  e: E[]
) => { byId: Record<string, E>; bySlug: Record<string, E>; allIds: string[] }

export const normalizeEntitiesWithSlug: NormalizeEntitiesWithSlug = e => {
  const allIds = e.map(t => t.id)
  const byId = _.keyBy(e, 'id')
  const bySlug = _.keyBy(e, 'slug')
  // keyBy merges values onto a given key
  // if any value in the collection does not have a slug property, it gets written to key `undefiend`
  // this is a stopgap until validation can occur
  // @ts-ignore
  delete bySlug[undefined]

  return { byId, allIds, bySlug }
}

export default normalizeEntities
