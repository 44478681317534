import { isStop, isTour } from '../components/cms/helpers/validators'
import { IEditedItem, IStop, ITour } from '../interfaces'
import { cmsDeleteStop, cmsSaveStop, cmsSaveTour } from '../actions/cms'
import { ThunkDispatch } from 'redux-thunk'
import { AppState } from '../reducers/rootReducer'
import { AnyAction } from 'redux'
import { setUiIsLoading } from '../actions/ui'

export default (
  editedStops: IEditedItem<IStop>[],
  editedTour: IEditedItem<ITour> | null,
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(setUiIsLoading(true))

  return new Promise<void>((resolve, reject) => {
    if (!editedTour) {
      return reject(new Error('Error saving Tour'))
    }
    if (!isTour(editedTour.item)) {
      return reject(new Error('Tour validation error'))
    }
    if (editedStops.some(editedStop => !isStop(editedStop.item))) {
      return reject(new Error('Stop validation error'))
    }

    // Any stops on the tour that are NOT in our list of editedStops are to be deleted
    const stopsToDelete: string[] = []
    editedTour.item.stopIds.forEach(stopId => {
      if (!editedStops.some(editedStop => editedStop.item.id === stopId)) {
        stopsToDelete.push(stopId)
      }
    })

    // Replace the stopIds field on the tour with all the IDs from these stops
    const tourWithStopIds: ITour = {
      ...editedTour.item,
      stopIds: editedStops.map(stop => {
        // We know this item is an IStop because of the above validation.
        return (stop.item as IStop).id
      }),
    }

    const deleteStopDispatches = stopsToDelete.map(id =>
      dispatch(cmsDeleteStop(id))
    )

    const saveStopDispatches = editedStops.map(stop =>
      // We know this item is an IStop because of the above validation.
      dispatch(cmsSaveStop(stop.item as IStop, stop.meta.isNew))
    )

    Promise.all([...deleteStopDispatches])
      .then(() => Promise.all([...saveStopDispatches]))
      .then(() => dispatch(cmsSaveTour(tourWithStopIds, editedTour.meta.isNew)))
      .then(() => dispatch(setUiIsLoading(false)))
      .then(() => {
        resolve()
      })
  })
}
