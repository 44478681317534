import { ICMSState, IEditedItem, IQRCode, IStop, ITour } from '../../interfaces'
import { AnyAction, combineReducers } from 'redux'
import {
  CMS_SET_EDITED_RECORD,
  CMS_SET_EDITED_TOUR,
  CMS_SET_EDITED_STOPS,
  CMS_SET_EDITED_QR_CODE,
} from '../../constants/actionTypes'
import { IRecord } from '../../interfaces'
import { cmsRecordsReducer } from './cmsRecordsReducer'
import { cmsToursReducer } from './cmsToursReducer'
import { cmsQRCodesReducer } from './cmsQRCodesReducer'
import { cmsStopsReducer } from './cmsStopsReducer'
import { cmsFeaturedAndSocialReducer } from './cmsFeaturedAndSocialReducer'
import { userSessionReducer } from './cmsSessionReducer'

const editedRecordReducer = (
  state: IEditedItem<IRecord> | null = null,
  action: AnyAction
) => {
  switch (action.type) {
    case CMS_SET_EDITED_RECORD:
      return (
        action.record && {
          ...action.record,
        }
      )
    default:
      return state
  }
}

const editedTourReducer = (
  state: IEditedItem<ITour> | null = null,
  action: AnyAction
) => {
  switch (action.type) {
    case CMS_SET_EDITED_TOUR:
      return (
        action.tour && {
          ...action.tour,
        }
      )
    default:
      return state
  }
}

const editedStopsReducer = (
  state: IEditedItem<IStop>[] = [],
  action: AnyAction
) => {
  switch (action.type) {
    case CMS_SET_EDITED_STOPS:
      return action.stops || []
    default:
      return state
  }
}

const editedQRCodeReducer = (
  state: IEditedItem<IQRCode> | null = null,
  action: AnyAction
) => {
  switch (action.type) {
    case CMS_SET_EDITED_QR_CODE:
      return action.qrCode || []
    default:
      return state
  }
}

export default combineReducers<ICMSState>({
  session: userSessionReducer,
  records: cmsRecordsReducer,
  tours: cmsToursReducer,
  qrCodes: cmsQRCodesReducer,
  stops: cmsStopsReducer,
  featuredAndSocial: cmsFeaturedAndSocialReducer,
  editedRecord: editedRecordReducer,
  editedTour: editedTourReducer,
  editedStops: editedStopsReducer,
  editedQRCode: editedQRCodeReducer,
})
