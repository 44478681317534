import React from 'react'
import { Box, Flex, Image, Text } from 'rebass'
import icon from '../../assets/icons/nashville-sites-icon.png'
import styled, { withTheme } from 'styled-components'
import { NavLink } from 'react-router-dom'
import { Theme } from '../../theme'
import { AxiosError } from 'axios'
import { connect } from 'react-redux'
import { AppState } from '../../reducers/rootReducer'
import Banner from '../common/Banner'
import { clearAllErrors } from '../../actions/ui'
import { blue } from '../../theme'

import store from '../../store'
import { logOut } from '../../actions/cms/session'
import firebase from 'firebase/app'
import history from '../../history'

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
  margin-right: 24px;
  border-bottom: solid 2px transparent;
`

interface IAdminHeaderProps {
  theme: Theme
  errors: (Error | AxiosError)[]
  clearErrors: () => void
}
const AdminHeader: React.FC<IAdminHeaderProps> = props => {
  const handleClose = () => {
    props.clearErrors()
  }

  return (
    <>
      {props.errors.length ? (
        <Banner
          flexDirection="column"
          p={3}
          alignItems="center"
          onClose={handleClose}
          type="error"
        >
          {props.errors.map((e: Error | AxiosError, i: number) => (
            <Box key={i}>{e.message}</Box>
          ))}
        </Banner>
      ) : null}
      <Flex
        p={3}
        sx={{ maxWidth: 960 }}
        mx="auto"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex alignItems="center">
          <Image src={icon} width={20} mr={4} />
          <StyledNavLink
            to="/admin/home"
            activeStyle={{
              borderColor: blue,
            }}
          >
            Home
          </StyledNavLink>
          <StyledNavLink
            to="/admin/records"
            activeStyle={{
              borderColor: blue,
            }}
          >
            Records
          </StyledNavLink>
          <StyledNavLink
            to="/admin/tours"
            activeStyle={{
              borderColor: blue,
            }}
          >
            Tours
          </StyledNavLink>
          <StyledNavLink
            to="/admin/qr-codes"
            activeStyle={{
              borderColor: blue,
            }}
          >
            QR Codes
          </StyledNavLink>
          <StyledNavLink
            to="/admin/content"
            activeStyle={{
              borderColor: blue,
            }}
          >
            Content
          </StyledNavLink>
        </Flex>
        <Text
          sx={{
            cursor: 'pointer',
          }}
          onClick={ev => {
            // dispatch the logout first because the auth observer won't redirect to /
            // if there's an existing session
            store.dispatch(logOut())
            firebase.auth().signOut()
            history.push('/')
          }}
        >
          Log Out
        </Text>
      </Flex>
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  errors: state.ui.errors,
})

const mapDispatchToProps = {
  clearErrors: clearAllErrors,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(AdminHeader))
