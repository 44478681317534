import config from '../../config'
import {
  CMS_DELETE_QR_CODE_FAILURE,
  CMS_DELETE_QR_CODE_REQUEST,
  CMS_DELETE_QR_CODE_SUCCESS,
  CMS_GET_QR_CODES_FAILURE,
  CMS_GET_QR_CODES_REQUEST,
  CMS_GET_QR_CODES_SUCCESS,
  CMS_SAVE_QR_CODE_FAILURE,
  CMS_SAVE_QR_CODE_REQUEST,
  CMS_SAVE_QR_CODE_SUCCESS,
  CMS_SET_EDITED_QR_CODE,
} from '../../constants/actionTypes'
import { IEditedItem, IQRCode } from '../../interfaces'
import { AxiosError, AxiosResponse } from 'axios'
import cmsAxios from './cmsNet'
import { ThunkDispatch } from 'redux-thunk'
import { AppState } from '../../reducers/rootReducer'
import { AnyAction } from 'redux'
import { addNewError } from '../ui'

const CMS_API_URL = config.CMS_API_URL

export const cmsGetQRCodesRequest = () => ({
  type: CMS_GET_QR_CODES_REQUEST,
})

export const cmsGetQRCodesSuccess = (qrCodes: IQRCode[]) => ({
  type: CMS_GET_QR_CODES_SUCCESS,
  qrCodes,
})

export const cmsGetQRCodesFailure = (error: AxiosError) => ({
  type: CMS_GET_QR_CODES_FAILURE,
  error,
})

export const cmsGetQRCodes = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(cmsGetQRCodesRequest())

  cmsAxios
    .get(`${CMS_API_URL}/codes`)
    .then((res: AxiosResponse<IQRCode[]>) => {
      const qrCodes = res.data || []
      dispatch(cmsGetQRCodesSuccess(qrCodes))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(cmsGetQRCodesFailure(err))
      throw err
    })
}

export const cmsGetQRCodesIfNeeded = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>,
  getState: () => AppState
) => {
  const { cms } = getState()

  if (!cms.qrCodes.isCached.cmsGetQRCodes) {
    dispatch(cmsGetQRCodes())
  }
}

export const cmsSetEditedQRCode = (qrCode: IEditedItem<IQRCode> | null) => ({
  type: CMS_SET_EDITED_QR_CODE,
  qrCode,
})

export const cmsSaveQRCodeRequest = () => ({
  type: CMS_SAVE_QR_CODE_REQUEST,
})

export const cmsSaveQRCodeSuccess = (qrCode: IQRCode) => ({
  type: CMS_SAVE_QR_CODE_SUCCESS,
  qrCode,
})

export const cmsSaveQRCodeFailure = (error: AxiosError) => ({
  type: CMS_SAVE_QR_CODE_FAILURE,
  error,
})

export const cmsSaveQRCode = (qrCode: IQRCode, isNew: boolean) => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(cmsSaveQRCodeRequest())

  const method = isNew ? 'post' : 'put'
  const url = isNew
    ? `${CMS_API_URL}/codes`
    : `${CMS_API_URL}/codes/${qrCode.id}`

  return cmsAxios[method](url, qrCode)
    .then((res: AxiosResponse<IQRCode>) => {
      dispatch(cmsSaveQRCodeSuccess(res.data))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(cmsSaveQRCodeFailure(err))
      throw err
    })
}

export const cmsDeleteQRCodeRequest = () => ({
  type: CMS_DELETE_QR_CODE_REQUEST,
})

export const cmsDeleteQRCodeSuccess = (qrCode: Partial<IQRCode>) => ({
  type: CMS_DELETE_QR_CODE_SUCCESS,
  qrCode,
})

export const cmsDeleteQRCodeFailure = (error: AxiosError) => ({
  type: CMS_DELETE_QR_CODE_FAILURE,
  error,
})

export const cmsDeleteQRCode = (qrCode: Partial<IQRCode>) => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(cmsDeleteQRCodeRequest())

  return cmsAxios
    .delete(`${CMS_API_URL}/codes/${qrCode.id}`)
    .then(() => {
      dispatch(cmsDeleteQRCodeSuccess(qrCode))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(cmsDeleteQRCodeFailure(err))
      throw err
    })
}
