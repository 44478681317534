import React, { useEffect, useRef } from 'react'
import { Box, Flex } from 'rebass'
import mapboxgl from 'mapbox-gl'
import styled from 'styled-components'
import { blue } from '../../theme'

import HISTORICAL_MARKERS from '../../assets/MHCHomepage.geojson'
const MAP_STYLE = process.env.REACT_APP_MAP_OF_TOUR_LOCATIONS

const MapboxContainer = styled(Flex)`
  position: relative;
  height: 350px;
`

const TourLocations = () => {
  const mapContainer = useRef(null)

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || ''

    // to be used to display site name when user hovers over pin
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    })

    const map = new mapboxgl.Map({
      container: mapContainer.current || '',
      style: MAP_STYLE,
      zoom: 12,
      center: [-86.7816, 36.1627],
    })

    map.on('load', function() {
      map.addSource('historicalMarkers', {
        type: 'geojson',
        data: HISTORICAL_MARKERS,
        cluster: true,
        clusterMaxZoom: 22, // Max zoom to cluster points on
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
      })

      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'historicalMarkers',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            blue,
            100,
            blue,
            750,
            blue,
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      })

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'historicalMarkers',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
        paint: {
          'text-color': '#ffffff',
        },
      })

      // This is unneeded as the points are coming from the map style. Leaving
      // here because it aids debugging of point locations.
      // map.addLayer({
      //   id: "unclustered-point",
      //   type: "circle",
      //   source: "historicalMarkers",
      //   filter: ["!", ["has", "point_count"]],
      //   paint: {
      //     "circle-color": 'red',
      //     "circle-radius": 7,
      //     "circle-stroke-width": 1,
      //     "circle-stroke-color": "#fff"
      //   }
      // });

      // popup over any point on the homepage-map layer
      // to show name of site
      map.on('mouseenter', 'homepage-map', function(ev) {
        // @ts-ignore
        const siteName = ev.features[0] && ev.features[0].properties.Name
        // @ts-ignore
        const xy = ev.features[0].geometry.coordinates.slice()

        popup
          .setLngLat(xy)
          .setHTML(siteName)
          .addTo(map)

        map.getCanvas().style.cursor = 'pointer'
      })

      map.on('mouseleave', 'homepage-map', function(ev) {
        popup.remove()
        map.getCanvas().style.cursor = ''
      })

      // for mobile users who don't trigger mouseenter/mouseleave events
      // toggle on tap
      map.on('click', 'homepage-map', function(ev) {
        if (popup.isOpen()) {
          popup.remove()
        } else {
          // @ts-ignore
          const siteName = ev.features[0] && ev.features[0].properties.Name
          // @ts-ignore
          const xy = ev.features[0].geometry.coordinates.slice()

          popup
            .setLngLat(xy)
            .setHTML(siteName)
            .addTo(map)
        }
      })

      // inspect a cluster on click
      map.on('click', 'clusters', function(e) {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ['clusters'],
        })
        const clusterId =
          features[0].properties && features[0].properties.cluster_id
        map
          .getSource('historicalMarkers')
          // @ts-ignore
          .getClusterExpansionZoom(clusterId, function(err, zoom) {
            if (err) return

            map.easeTo({
              // @ts-ignore
              center: features[0].geometry.coordinates,
              zoom: zoom,
            })
          })
      })

      map.on('mouseenter', 'clusters', function() {
        map.getCanvas().style.cursor = 'pointer'
      })

      map.on('mouseleave', 'clusters', function() {
        map.getCanvas().style.cursor = ''
      })
    })
  }, [])

  return (
    <MapboxContainer flex={[1]} width={[1]}>
      <Box ref={mapContainer} width={['100%']} />
    </MapboxContainer>
  )
}

export default TourLocations
