import React from 'react'
import AdminHeader from './AdminHeader'
import { Box } from 'rebass'
import { Redirect, Route, Switch, RouteProps } from 'react-router'
import AdminHome from './AdminHome'
import AdminRecords from './records/AdminRecords'
import AdminTours from './tours/AdminTours'
import AdminQRCodes from './qrCodes/AdminQRCodes'
import AdminAboutUs from './content/AdminAboutUs'
import AdminStaffMember from './content/AdminStaffMember'
import GetRecordData from './records/GetRecordData'
import GetTourData from './tours/GetTourData'
import GetQRCodeData from './qrCodes/GetQRCodeData'
import LoadingSpinner from '../common/LoadingSpinner'
import LogIn from './LogIn'
import { AppState } from '../../reducers/rootReducer'
import { connect } from 'react-redux'

interface ProtectedRouteProps extends RouteProps {
  hasSession: boolean
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  hasSession,
  ...rest
}) => {
  console.info('protected route', 'render', 'hasSession', hasSession)
  return hasSession ? <Route {...rest} /> : <Redirect to="/admin/login" />
}

const Admin: React.FC<{
  isLoading: boolean
  hasSession: boolean
}> = ({ isLoading, hasSession }) => {
  return (
    <>
      <LoadingSpinner isLoading={isLoading} />

      <AdminHeader />

      <Box p={3} sx={{ maxWidth: 960 }} mx="auto">
        <Switch>
          <Route exact path="/admin">
            <Redirect to="/admin/home" />
          </Route>

          <Route exact path="/admin/login" component={LogIn} />

          <ProtectedRoute
            hasSession={hasSession}
            path="/admin/home"
            component={AdminHome}
          />

          <ProtectedRoute
            hasSession={hasSession}
            path={['/admin/records/new', '/admin/records/edit/:id']}
            component={GetRecordData}
          />
          <ProtectedRoute
            hasSession={hasSession}
            path="/admin/records"
            component={AdminRecords}
          />
          <ProtectedRoute
            hasSession={hasSession}
            path={['/admin/tours/new', '/admin/tours/edit/:id']}
            component={GetTourData}
          />
          <ProtectedRoute
            hasSession={hasSession}
            path="/admin/tours"
            component={AdminTours}
          />
          <ProtectedRoute
            hasSession={hasSession}
            path={['/admin/qr-codes/new', '/admin/qr-codes/edit/:id']}
            component={GetQRCodeData}
          />
          <ProtectedRoute
            hasSession={hasSession}
            path="/admin/qr-codes"
            component={AdminQRCodes}
          />
          <ProtectedRoute
            exact
            hasSession={hasSession}
            path="/admin/content"
            component={AdminAboutUs}
          />
          <ProtectedRoute
            exact
            hasSession={hasSession}
            path={[
              '/admin/content/about-us/staff/new',
              '/admin/content/about-us/staff/edit/:id',
            ]}
            component={AdminStaffMember}
          />
        </Switch>
      </Box>
    </>
  )
}

const mapStateToProps = (
  state: AppState
): {
  isLoading: boolean
  hasSession: boolean
} => ({
  isLoading:
    state.cms.featuredAndSocial.isLoading ||
    state.cms.records.isLoading ||
    state.cms.tours.isLoading ||
    state.cms.stops.isLoading ||
    state.cms.qrCodes.isLoading ||
    state.ui.isLoading,
  hasSession: state.cms.session.userHasSession,
})

export default connect(mapStateToProps)(Admin)
