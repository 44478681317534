import { IStopsWithRecordState } from '../interfaces'
import { AnyAction } from 'redux'
import {
  GET_KEYWORDS_FAILURE,
  GET_KEYWORDS_REQUEST,
  GET_KEYWORDS_SUCCESS,
  GET_STOPS_BY_KEYWORD_FAILURE,
  GET_STOPS_BY_KEYWORD_REQUEST,
  GET_STOPS_BY_KEYWORD_SUCCESS,
  GET_STOPS_FOR_TOUR_FAILURE,
  GET_STOPS_FOR_TOUR_REQUEST,
  GET_STOPS_FOR_TOUR_SUCCESS,
} from '../constants/actionTypes'
import { normalizeEntitiesWithSlug } from '../helpers/normalizeEntities'

const initialState: IStopsWithRecordState = {
  byId: {},
  bySlug: {},
  byTourId: {},
  byKeyword: {},
  allIds: [],
  isLoading: false,
  isCached: {
    getStopsForTour: {},
    getStopsByKeyword: {},
  },
  keywords: [],
}

const stopsReducer = (
  state = initialState,
  action: AnyAction
): IStopsWithRecordState => {
  switch (action.type) {
    case GET_STOPS_FOR_TOUR_REQUEST:
      return { ...state, isLoading: true }
    case GET_STOPS_FOR_TOUR_SUCCESS:
      return {
        ...state,
        ...normalizeEntitiesWithSlug(action.stops),
        byTourId: {
          ...state.byTourId,
          ...{ [action.tourId]: action.stops },
        },
        isLoading: false,
        isCached: {
          ...state.isCached,
          getStopsForTour: {
            ...state.isCached.getStopsForTour,
            [action.tourId]: true,
          },
        },
      }
    case GET_STOPS_FOR_TOUR_FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    // GET KEYWORDS
    case GET_KEYWORDS_REQUEST:
      return { ...state, isLoading: true }
    case GET_KEYWORDS_SUCCESS:
      return {
        ...state,
        keywords: action.keywords,
      }
    case GET_KEYWORDS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    // GET STOPS BY KEYWORD
    case GET_STOPS_BY_KEYWORD_REQUEST:
      return { ...state, isLoading: true }
    case GET_STOPS_BY_KEYWORD_SUCCESS:
      return {
        ...state,
        ...normalizeEntitiesWithSlug(action.stops),
        byKeyword: {
          ...state.byKeyword,
          ...{ [action.keyword]: action.stops },
        },
        isLoading: false,
        isCached: {
          ...state.isCached,
          getStopsByKeyword: {
            ...state.isCached.getStopsByKeyword,
            [action.keyword]: true,
          },
        },
      }
    case GET_STOPS_BY_KEYWORD_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default stopsReducer
