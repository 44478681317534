import React, { MouseEvent, useEffect, useMemo, useState } from 'react'
import { Box, Flex, Heading } from 'rebass'
import { Close } from '@material-ui/icons'
import Select from 'react-select'
import Button from '../../common/Button'
import { connect } from 'react-redux'
import { AppState } from '../../../reducers/rootReducer'
import { cmsGetRecordsIfNeeded } from '../../../actions/cms'
import { IRecord, ISelectOption, MediaType } from '../../../interfaces'
import Loading from '../../common/Loading'
import { ValueType } from 'react-select/src/types'
import GetRecordData from '../records/GetRecordData'

interface IRecordPickerProps {
  onClose: () => void
  recordsById?: { [Key: string]: IRecord }
  getRecords: () => void
  isLoading: boolean
  typeToPick: MediaType
  onChooseRecord: (record: IRecord) => void
}
const RecordPicker: React.FC<IRecordPickerProps> = ({
  onClose,
  recordsById,
  getRecords,
  isLoading,
  onChooseRecord,
  typeToPick,
}) => {
  const [showRecordForm, setShowRecordForm] = useState(false)
  const [chosenRecordId, setChosenRecordId] = useState('')
  const chosenRecord = recordsById ? recordsById[chosenRecordId] : null

  useEffect(() => {
    getRecords()
  }, [getRecords])

  const stopPropagation = (e: MouseEvent) => {
    e.stopPropagation()
  }

  const recordOptions: ISelectOption[] = useMemo(() => {
    const records = recordsById ? Object.values(recordsById) : []
    return records
      .filter(r => (typeToPick === 'All' ? true : typeToPick === r.Type))
      .map(r => ({ value: r.id, label: r.Title }))
      .sort((a, b) => {
        return a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      })
  }, [recordsById, typeToPick])

  const chosenOption: ISelectOption | null = useMemo(() => {
    if (chosenRecord) {
      return {
        value: chosenRecord.id,
        label: chosenRecord.Title,
      }
    } else {
      return null
    }
  }, [chosenRecord])

  const handleRecordSelect = (recordOption: ValueType<ISelectOption>) => {
    // react-select makes it hard to check that the type is ISelectOption, as
    // opposed to ISelectOption[], so I have to assert the type.
    const chosenOption = recordOption as ISelectOption
    const chosenRecord = recordsById && recordsById[chosenOption.value]

    setChosenRecordId(chosenRecord ? chosenRecord.id : '')
  }

  const onUploadClick = () => {
    setShowRecordForm(true)
  }

  const handleSaveCallback = (record: IRecord | null) => {
    setChosenRecordId(record ? record.id : '')
    setShowRecordForm(false)
  }

  const handleChoose = () => {
    if (chosenRecord) {
      onChooseRecord(chosenRecord)
    }

    onClose()
  }

  return (
    <>
      {showRecordForm ? (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 3,
            overflow: 'auto',
          }}
        >
          <Box
            my={3}
            mx="auto"
            p={3}
            sx={{
              width: 960,
              maxWidth: '100%',
              borderRadius: '2px',
              backgroundColor: '#ffffff',
              boxShadow: '0 6px 12px 0 #666666',
            }}
            onClick={stopPropagation}
          >
            <GetRecordData
              saveCallback={handleSaveCallback}
              setType={typeToPick}
            />
          </Box>
        </Box>
      ) : (
        <Box
          onClick={onClose}
          sx={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 3,
            overflow: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            px={4}
            py={3}
            sx={{
              width: '450px',
              borderRadius: '2px',
              backgroundColor: '#ffffff',
              boxShadow: '0 6px 12px 0 #666666',
            }}
            onClick={stopPropagation}
          >
            {isLoading ? (
              <Loading />
            ) : (
              <>
                <Flex justifyContent="space-between" mb={3}>
                  <Heading fontSize={2} fontWeight={600}>
                    Choose a record
                  </Heading>
                  <Close onClick={onClose} />
                </Flex>

                <Flex mb={3}>
                  <Box width="100%">
                    <Select
                      options={recordOptions}
                      value={chosenOption}
                      placeholder="Select existing"
                      onChange={handleRecordSelect}
                    />
                  </Box>

                  <Box sx={{ flexShrink: 0 }}>
                    <Button ml={3} variant="primary" onClick={onUploadClick}>
                      Upload New
                    </Button>
                  </Box>
                </Flex>

                <Button mt={3} variant="primary" onClick={handleChoose}>
                  Choose Record
                </Button>
              </>
            )}
          </Box>
        </Box>
      )}
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  recordsById: state.cms.records.byId,
  isLoading: state.cms.records.isLoading,
})

const mapDispatchToProps = {
  getRecords: cmsGetRecordsIfNeeded,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordPicker)
