import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Flex, Image, Heading, Text, Box } from 'rebass'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

import Button from './Button'
import ImageCarousel from './ImageCarousel'
import { SpaceProps } from 'styled-system'

import stopwatch from '../../assets/icons/ic_timer.svg'
import walking from '../../assets/icons/ic_directions_walk.svg'
import landscape from '../../assets/icons/ic_siteamount.svg'
import { ITour } from '../../interfaces'

const StyledLink = styled(RouterLink)`
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  border-radius: 11px;
  background-color: #ea242b;
  color: white;
  padding: 4px 8px;
  margin-bottom: 16px;
  margin-right: 4px;
`

interface ITourSummaryProps extends RouteComponentProps, SpaceProps {
  tour?: ITour
  clickToStartTour?: boolean
  viewTourClickHandler?: () => void
}

const TourSummary = (props: ITourSummaryProps) => {
  const tour = props.tour

  if (!tour) {
    return <div>No tour found</div>
  }

  const handleViewTour = () => {
    if (props.viewTourClickHandler) {
      props.viewTourClickHandler()
    } else {
      props.history.push(`/tours/${tour.slug}`)
    }
  }

  return (
    <Flex
      {...props}
      flexWrap="wrap"
      sx={{
        border: '1px solid #979797',
        width: '400px',
      }}
    >
      <Box>
        <ImageCarousel
          images={tour.images}
          onClick={props.clickToStartTour ? handleViewTour : undefined}
        />
      </Box>

      <Flex pb={[3]} pl={[3]} pr={[3]} flexDirection={['column']}>
        <Heading fontSize={['22px']} fontWeight={600} mb={['16px']}>
          {tour.title}
        </Heading>

        <Box>
          {tour.categories &&
            tour.categories.map(category => {
              return (
                <StyledLink key={category} to={'/tours'}>
                  {category}
                </StyledLink>
              )
            })}
        </Box>

        <Flex alignItems="center" justifyContent="space-between" mb={['16px']}>
          <Flex alignItems="center">
            <Image src={stopwatch} width={[15, 13]} mr={2} />
            <Text fontSize={[2, 1]}>
              {tour.duration > 1
                ? tour.duration + ' hours'
                : tour.duration + ' hour'}
            </Text>
          </Flex>

          <Flex alignItems="center">
            <Image src={walking} width={[13, 11]} mr={2} />
            <Text fontSize={[2, 1]}>
              {tour.distance > 1
                ? tour.distance + ' miles'
                : tour.distance + ' mile'}
            </Text>
          </Flex>

          <Flex alignItems="center">
            <Image src={landscape} width={[14, 12]} mr={2} />
            <Text fontSize={[2, 1]}>
              {tour.stopIds.length > 1
                ? tour.stopIds.length + ' sites'
                : tour.stopIds.length + ' site'}
            </Text>
          </Flex>
        </Flex>

        <Text fontSize={[2, 1]} mb={['24px']}>
          {tour.description}
        </Text>

        <Button
          alignSelf={'flex-end'}
          onClick={handleViewTour}
          variant={'outline'}
          sx={{
            fontWeight: 'bold',
            cursor: 'pointer',
            width: '100%',
            height: '44px',
            oTransition: '.25s',
            msTransition: '.25s',
            mozTransition: '.25s',
            webkitTransition: '.25s',
            transition: '.5s',
            boxShadow: 'inset 0 0 0 2px',
            '&:hover': {
              boxShadow: 'inset 0 0 0 3px',
            },
          }}
        >
          View Tour
        </Button>
      </Flex>
    </Flex>
  )
}

export default withRouter(TourSummary)
