// To ensure we have promises and fetch in IE11.
// We won't support older browsers than IE11.
import 'react-app-polyfill/ie11'
import 'url-search-params-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as StoreProvider } from 'react-redux'
import { Route, Router, Switch } from 'react-router'
import App from './components/App'
import Admin from './components/cms/Admin'
import * as serviceWorker from './serviceWorker'
import theme from './theme'
import ScrollToTop from './components/ScrollToTop'
import firebase from 'firebase/app'
import 'react-image-lightbox/style.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './css/fonts.css'
import './css/custom-carousel.css'
import { ThemeProvider } from 'emotion-theming'
import { Box } from 'rebass'
import { logIn, logOut } from './actions/cms/session'
import store from './store'
import history from './history'

import config from './config'

console.info(`app version - ${config.VERSION}`)

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

console.info('index', 'firebaseConfig', process.env)

firebase.initializeApp(firebaseConfig)

firebase
  .auth()
  .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  .then(() => {
    console.info('index', 'setPersistence', 'persistence set to LOCAL')
  })
  .catch(err => {
    console.error('index', 'setPersistence', 'error', err)
  })

// NOTE auth state observer used to change redux state and send user to /
//firebase.auth().onIdTokenChanged(user => {
firebase.auth().onAuthStateChanged(user => {
  // User is signed in.
  if (user) {
    console.info(
      'index',
      'onIdTokenChanged',
      'user is already logged in, updating localStorage'
    )

    store.dispatch(logIn())

    //user
    //.getIdToken()
    //.then(token => {
    //console.info('index', 'onIdTokenChanged', 'new token receieved')

    //window.localStorage.setItem('userToken', JSON.stringify(token))
    //})

    //.catch(err => {
    //window.localStorage.removeItem('userToken')
    //console.error(
    //'index',
    //'onIdTokenChanged',
    //'error getting token to set in localStorage'
    //)
    //})

    // User is signed out.
  } else {
    console.info('index', 'onIdTokenChanged', 'user is logged out')
    store.dispatch(logOut())
    //history.push('/')

    // check if they're logging out (i.e. have a session currently)
    //const userTokenVal = window.localStorage.getItem('userToken')

    // token assumed to be valid if exists and parse-able
    //const hasSession = userTokenVal && !!JSON.parse(userTokenVal)

    // clear the session token out of localstorage
    //window.localStorage.removeItem('userToken')

    // update app state

    // send them to the home screen if they just logged out
    //if (hasSession) {
    //}
  }
})

ReactDOM.render(
  <StoreProvider store={store}>
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <ScrollToTop>
          <Box sx={{ fontFamily: 'body' }}>
            <Switch>
              <Route path="/admin" component={Admin} />
              <Route path="/" component={App} />
            </Switch>
          </Box>
        </ScrollToTop>
      </Router>
    </ThemeProvider>
  </StoreProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
