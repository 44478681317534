import { IMedia, IRecord, MediaType } from '../interfaces'

export const extractMediaFromRecord = (type: MediaType) => (
  record: IRecord
): IMedia[] => {
  switch (type) {
    case 'Audio':
      return record.audios
    case 'Image':
      return record.images
    case 'Video':
      return record.videos
    default:
      return []
  }
}

export const changeMediaField = (
  mediaExtractor: (record: IRecord) => IMedia[],
  changeFunction: (media: IMedia[]) => void
) => (record: IRecord) => {
  const media = mediaExtractor(record)

  changeFunction(media)
}
