import React, { ChangeEvent, useState } from 'react'
import { Box, Flex, Heading } from 'rebass'
import { TextField, Select, MenuItem, FormHelperText } from '@material-ui/core'
import { IMedia, IRecord, ITour, MediaType } from '../../../interfaces'
import MediaList from '../common/MediaList'
import Button from '../../common/Button'
import StopsEditor from '../stops/StopsEditor'
import { isTour } from '../helpers/validators'
import RecordPicker from './RecordPicker'
import {
  changeMediaField,
  extractMediaFromRecord,
} from '../../../helpers/mediaRecordHelpers'
import RequiredIndicator from '../common/RequiredIndicator'
import ReactQuill from 'react-quill'

interface IAdminTour {
  tour: Partial<ITour>
  onDelete: () => void
  onSave: () => void
  onCancel: () => void
  onTourUpdate: (tour: Partial<ITour>) => void
  isLoading: boolean
}
const AdminTour: React.FC<IAdminTour> = ({
  tour,
  onDelete,
  onSave,
  onCancel,
  onTourUpdate,
  isLoading,
}) => {
  // recordPickerConfig contains the function that will be called when the record
  // is chosen.
  const [recordPickerConfig, setRecordPickerConfig] = useState<null | {
    callback: (record: IRecord) => void
    type: MediaType
  }>(null)

  const [allStopsValid, setAllStopsValid] = useState(false)
  const isTourValid = allStopsValid && isTour(tour)

  const tourCategories = [
    'Downtown Nashville Tours',
    'Neighborhoods and Campuses',
    'Driving Tours',
    'Tours with Lesson Plans',
  ]

  const updateTour = (field: Partial<ITour>) => {
    onTourUpdate(Object.assign(tour, field))
  }

  const changeStringField = (fieldName: keyof ITour) => (
    e: ChangeEvent<{ value: unknown }>
  ) => {
    updateTour({ [fieldName]: e.target.value })
  }

  const changeArrayField = (fieldName: keyof ITour) => (
    e: ChangeEvent<{ value: unknown }>
  ) => {
    updateTour({ [fieldName]: e.target.value })
  }

  const changeNumberField = (fieldName: keyof ITour) => (
    e: ChangeEvent<{ value: unknown }>
  ) => {
    const number = parseFloat(e.target.value as string)
    updateTour({ [fieldName]: number })
  }

  const changeImageField = (fieldName: keyof ITour) => (image: IMedia[]) => {
    updateTour({ [fieldName]: image })
  }

  const addToImageList = (media: IMedia[]) => {
    if (tour) {
      const newList = [...(tour.images || []), ...media]
      changeImageField('images')(newList)
    }
  }

  const changeAudioField = (fieldName: keyof ITour) => (audio: IMedia[]) => {
    updateTour({ [fieldName]: audio[0] })
  }

  const handleAddMedia = (
    type: MediaType,
    mediaExtractor: (record: IRecord) => IMedia[],
    changeFunction: (media: IMedia[]) => void
  ) => () => {
    setRecordPickerConfig({
      callback: changeMediaField(mediaExtractor, changeFunction),
      type,
    })
  }

  const changeQuillField = (fieldName: keyof ITour) => (content: string) => {
    updateTour({ [fieldName]: content })
  }

  return (
    <Box sx={{ position: 'relative' }}>
      {!!recordPickerConfig && (
        <RecordPicker
          typeToPick={recordPickerConfig.type}
          onClose={() => setRecordPickerConfig(null)}
          onChooseRecord={recordPickerConfig.callback}
        />
      )}
      <Box mt={4}>
        <Flex justifyContent="space-between" mt={3} mb={1}>
          <Box width={[2 / 3, 1 / 2]}>
            <Flex>
              <Heading fontSize={2} fontWeight={600}>
                Tour Title
              </Heading>
              <RequiredIndicator />
            </Flex>

            <TextField
              margin="dense"
              variant="outlined"
              fullWidth={true}
              value={tour.title || ''}
              onChange={changeStringField('title')}
            />

            <Flex>
              <Heading fontSize={2} fontWeight={600}>
                URL Slug
              </Heading>
              <RequiredIndicator />
            </Flex>

            <TextField
              margin="dense"
              variant="outlined"
              fullWidth={true}
              value={tour.slug || ''}
              onChange={changeStringField('slug')}
            />

            <Flex mt={4} mb={1}>
              <Heading fontSize={2} fontWeight={600}>
                Tour Categories
              </Heading>
              <RequiredIndicator />
            </Flex>

            <Select
              multiple
              margin="dense"
              variant="outlined"
              fullWidth={true}
              value={tour.categories || []}
              onChange={changeArrayField('categories')}
            >
              {tourCategories.map(category => {
                return <MenuItem value={category}> {category} </MenuItem>
              })}
            </Select>
            <FormHelperText>
              Used to group the tours on the homepage
            </FormHelperText>
          </Box>

          <Button
            ml={3}
            bg="red"
            variant="primary"
            onClick={onDelete}
            flex="0 0 auto"
            confirm={true}
          >
            Delete Tour
          </Button>
        </Flex>

        <Flex mt={3} mb={1} alignItems="center">
          <Heading fontSize={2} fontWeight={600}>
            Tour Images
          </Heading>
          <Box ml={3} fontSize={0} sx={{ fontStyle: 'italic' }}>
            The first image will be used as the cover image
          </Box>
        </Flex>
        <MediaList
          type="Image"
          media={tour.images || []}
          onChange={changeImageField('images')}
          onAddMedia={handleAddMedia(
            'Image',
            extractMediaFromRecord('Image'),
            addToImageList
          )}
        />

        <Heading fontSize={2} fontWeight={600} mt={4} mb={2}>
          Intro Audio
        </Heading>
        <MediaList
          type="Audio"
          media={tour.introAudio ? [tour.introAudio] : []}
          onChange={changeAudioField('introAudio')}
          onAddMedia={handleAddMedia(
            'Audio',
            extractMediaFromRecord('Audio'),
            changeAudioField('introAudio')
          )}
          hideOrderingControls={true}
        />

        <Heading fontSize={2} fontWeight={600} mt={4} mb={2}>
          Outro Audio
        </Heading>
        <MediaList
          type="Audio"
          media={tour.outroAudio ? [tour.outroAudio] : []}
          onChange={changeAudioField('outroAudio')}
          onAddMedia={handleAddMedia(
            'Audio',
            extractMediaFromRecord('Audio'),
            changeAudioField('outroAudio')
          )}
          hideOrderingControls={true}
        />

        <Box width={[2 / 3, 1 / 2]}>
          <Flex mt={4} mb={1}>
            <Heading fontSize={2} fontWeight={600}>
              Tour Summary
            </Heading>
            <RequiredIndicator />
          </Flex>

          <TextField
            multiline={true}
            rows={5}
            margin="dense"
            variant="outlined"
            fullWidth={true}
            value={tour.description || ''}
            onChange={changeStringField('description')}
          />
        </Box>

        <Box width={[2 / 3, 1 / 2]}>
          <Flex mt={4} mb={1}>
            <Heading fontSize={2} fontWeight={600}>
              Intro
            </Heading>
            <RequiredIndicator />
          </Flex>

          <ReactQuill
            value={tour.intro || ''}
            onChange={changeQuillField('intro')}
          />
        </Box>

        <Box width={[2 / 3, 1 / 2]}>
          <Flex mt={4} mb={1}>
            <Heading fontSize={2} fontWeight={600}>
              Outro
            </Heading>
            <RequiredIndicator />
          </Flex>

          <ReactQuill
            value={tour.outro || ''}
            onChange={changeQuillField('outro')}
          />
        </Box>

        <Box width={[2 / 3, 1 / 2]}>
          <Flex mt={4} mb={1}>
            <Heading fontSize={2} fontWeight={600}>
              Duration
            </Heading>
            <RequiredIndicator />
          </Flex>

          <TextField
            type="number"
            margin="dense"
            variant="outlined"
            fullWidth={true}
            value={tour.duration || ''}
            onChange={changeNumberField('duration')}
          />
        </Box>

        <Box width={[2 / 3, 1 / 2]}>
          <Flex mt={4} mb={1}>
            <Heading fontSize={2} fontWeight={600}>
              Distance
            </Heading>
            <RequiredIndicator />
          </Flex>

          <TextField
            type="number"
            margin="dense"
            variant="outlined"
            fullWidth={true}
            value={tour.distance || ''}
            onChange={changeNumberField('distance')}
          />
        </Box>

        <Box width={[2 / 3, 1 / 2]}>
          <Flex mt={4} mb={1}>
            <Heading fontSize={2} fontWeight={600}>
              Map (Mapbox style url)
            </Heading>
            <RequiredIndicator />
          </Flex>

          <TextField
            margin="dense"
            variant="outlined"
            fullWidth={true}
            value={tour.map}
            onChange={changeStringField('map')}
          />

          <Flex mt={4} mb={1}>
            <Heading fontSize={2} fontWeight={600}>
              All Stops Map (default zoom level)
            </Heading>
            <RequiredIndicator />
          </Flex>

          <Select
            margin="dense"
            variant="outlined"
            fullWidth={true}
            value={tour.tourMapZoomLevel}
            onChange={changeNumberField('tourMapZoomLevel')}
          >
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={11}>11</MenuItem>
            <MenuItem value={12}>12</MenuItem>
            <MenuItem value={13}>13</MenuItem>
            <MenuItem value={14}>14</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={16}>16</MenuItem>
            <MenuItem value={17}>17</MenuItem>
            <MenuItem value={18}>18</MenuItem>
            <MenuItem value={19}>19</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={21}>21</MenuItem>
            <MenuItem value={22}>22</MenuItem>
          </Select>
          <FormHelperText>
            {' '}
            0 is all the way out, maximum visible territory{' '}
          </FormHelperText>

          <Flex mt={4} mb={1}>
            <Heading fontSize={2} fontWeight={600}>
              Next Stop Map (default zoom level)
            </Heading>
            <RequiredIndicator />
          </Flex>

          <Select
            margin="dense"
            variant="outlined"
            fullWidth={true}
            value={tour.nextStopMapZoomLevel}
            onChange={changeNumberField('nextStopMapZoomLevel')}
          >
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={11}>11</MenuItem>
            <MenuItem value={12}>12</MenuItem>
            <MenuItem value={13}>13</MenuItem>
            <MenuItem value={14}>14</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={16}>16</MenuItem>
            <MenuItem value={17}>17</MenuItem>
            <MenuItem value={18}>18</MenuItem>
            <MenuItem value={19}>19</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={21}>21</MenuItem>
            <MenuItem value={22}>22</MenuItem>
          </Select>
          <FormHelperText>
            {' '}
            0 is all the way out, maximum visible territory{' '}
          </FormHelperText>
        </Box>

        <Box my={4}>
          <Heading fontSize={2} fontWeight={600} mt={4} mb={3}>
            Stops
          </Heading>
          <StopsEditor tour={tour} areAllStopsValid={setAllStopsValid} />
        </Box>

        <Flex mt={4}>
          <Button onClick={onCancel} variant="outline" color="blue">
            Cancel
          </Button>
          <Button
            ml={3}
            variant="primary"
            onClick={onSave}
            disabled={isLoading || !isTourValid}
          >
            Save
          </Button>

          {isLoading && <p> loading </p>}
        </Flex>
      </Box>
    </Box>
  )
}

export default AdminTour
