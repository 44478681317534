import React, { useState } from 'react'
import { Box, Flex, Heading, Text } from 'rebass'
import { IRecord } from '../../../interfaces'
import moment from 'moment'
import copy from 'clipboard-copy'

const FullRecordAndCitation = ({ record }: { record: IRecord }) => {
  const [copySuccess, setCopySuccess] = useState<boolean | undefined>(undefined)

  const handleCopyClick = () => {
    const date = moment().format('LL')
    const url = window.location
    const textToCopy = `${record.Creator}, "${record.Title}", Nashville Sites, accessed ${date}, ${url}`

    copy(textToCopy)
      .then(() => {
        setCopySuccess(true)
      })
      .catch(() => {
        setCopySuccess(false)
      })
  }

  return (
    <Flex p={[3]} flexDirection="column" width={[1]}>
      <Box mb={4}>
        <Box
          as="span"
          onClick={handleCopyClick}
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline',
            color: 'blue',
          }}
        >
          Copy citation
        </Box>

        {copySuccess !== undefined && (
          <Box
            as="span"
            fontSize={1}
            ml={1}
            sx={{
              fontStyle: 'italic',
              textDecoration: 'none',
              color: copySuccess === false ? 'red' : 'black',
            }}
          >
            {copySuccess ? 'Copied to clipboard' : 'Copy error'}
          </Box>
        )}
      </Box>

      <Box mb={4}>
        <Heading mb={1} fontSize={[2]} fontWeight={600} color="lightGrey">
          Title
        </Heading>

        <Text>{record.Title}</Text>
      </Box>
      <Box mb={4}>
        <Heading mb={1} fontSize={[2]} fontWeight={600} color="lightGrey">
          Description
        </Heading>

        <Text>{record.Description}</Text>
      </Box>
      <Box mb={4}>
        <Heading mb={1} fontSize={[2]} fontWeight={600} color="lightGrey">
          Source
        </Heading>

        <Text>{record.Source}</Text>
      </Box>
      <Box mb={4}>
        <Heading mb={1} fontSize={[2]} fontWeight={600} color="lightGrey">
          Contributor
        </Heading>

        <Text>{record.Contributor.join(', ')}</Text>
      </Box>
      <Box mb={4}>
        <Heading mb={1} fontSize={[2]} fontWeight={600} color="lightGrey">
          Creator
        </Heading>

        <Text>{record.Creator}</Text>
      </Box>
      <Box mb={4}>
        <Heading mb={1} fontSize={[2]} fontWeight={600} color="lightGrey">
          Author
        </Heading>

        <Text>{record.Author}</Text>
      </Box>
      <Box mb={4}>
        <Heading mb={1} fontSize={[2]} fontWeight={600} color="lightGrey">
          Relation
        </Heading>

        <Text>{record.Relation}</Text>
      </Box>
      <Box mb={4}>
        <Heading mb={1} fontSize={[2]} fontWeight={600} color="lightGrey">
          Type
        </Heading>

        <Text>{record.Type}</Text>
      </Box>
      <Box mb={4}>
        <Heading mb={1} fontSize={[2]} fontWeight={600} color="lightGrey">
          Subject
        </Heading>

        <Text>{record.Subject.join(', ')}</Text>
      </Box>
      <Box mb={4}>
        <Heading mb={1} fontSize={[2]} fontWeight={600} color="lightGrey">
          Keyword
        </Heading>

        <Text>{record.Keyword.join(', ')}</Text>
      </Box>
    </Flex>
  )
}

export default FullRecordAndCitation
