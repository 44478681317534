import { AnyAction } from 'redux'
import { IFeaturedAndSocialState } from '../../interfaces'
import {
  CMS_GET_FEATURED_TOURS_FAILURE,
  CMS_GET_FEATURED_TOURS_REQUEST,
  CMS_GET_FEATURED_TOURS_SUCCESS,
  CMS_GET_SOCIAL_LINKS_FAILURE,
  CMS_GET_SOCIAL_LINKS_REQUEST,
  CMS_GET_SOCIAL_LINKS_SUCCESS,
  CMS_SAVE_FEATURED_TOURS_FAILURE,
  CMS_SAVE_FEATURED_TOURS_REQUEST,
  CMS_SAVE_FEATURED_TOURS_SUCCESS,
  CMS_SAVE_SOCIAL_LINKS_FAILURE,
  CMS_SAVE_SOCIAL_LINKS_REQUEST,
  CMS_SAVE_SOCIAL_LINKS_SUCCESS,
} from '../../constants/actionTypes'

const initialState: IFeaturedAndSocialState = {
  featuredTours: { featuredTours: [] },
  socialLinks: null,
  isLoading: false,
  isCached: {},
}

export const cmsFeaturedAndSocialReducer = (
  state = initialState,
  action: AnyAction
): IFeaturedAndSocialState => {
  switch (action.type) {
    // FEATURED TOURS
    case CMS_GET_FEATURED_TOURS_REQUEST:
      return { ...state, isLoading: true }
    case CMS_GET_FEATURED_TOURS_SUCCESS:
      return {
        ...state,
        featuredTours: action.featuredTours,
        isLoading: false,
        isCached: { ...state.isCached, getFeaturedTours: true },
      }
    case CMS_GET_FEATURED_TOURS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CMS_SAVE_FEATURED_TOURS_REQUEST:
      return { ...state, isLoading: true }
    case CMS_SAVE_FEATURED_TOURS_SUCCESS:
      return {
        ...state,
        featuredTours: action.featuredTours,
        isLoading: false,
      }
    case CMS_SAVE_FEATURED_TOURS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    // SOCIAL LINKS
    case CMS_GET_SOCIAL_LINKS_REQUEST:
      return { ...state, isLoading: true }
    case CMS_GET_SOCIAL_LINKS_SUCCESS:
      return {
        ...state,
        socialLinks: action.socialLinks,
        isLoading: false,
        isCached: { ...state.isCached, getSocialLinks: true },
      }
    case CMS_GET_SOCIAL_LINKS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CMS_SAVE_SOCIAL_LINKS_REQUEST:
      return { ...state, isLoading: true }
    case CMS_SAVE_SOCIAL_LINKS_SUCCESS:
      return {
        ...state,
        socialLinks: action.socialLinks,
        isLoading: false,
      }
    case CMS_SAVE_SOCIAL_LINKS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
