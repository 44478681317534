import React from 'react'
import { Box } from 'rebass'
import { CircularProgress } from '@material-ui/core'

const LoadingSpinner: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
  return (
    <Box sx={{ position: 'fixed', right: 3, bottom: 3, color: 'blue' }}>
      {isLoading && <CircularProgress />}
    </Box>
  )
}

export default LoadingSpinner
