import config from '../config'
import {
  GET_FEATURED_TOURS_FAILURE,
  GET_FEATURED_TOURS_REQUEST,
  GET_FEATURED_TOURS_SUCCESS,
  SAVE_FEATURED_TOURS_FAILURE,
  SAVE_FEATURED_TOURS_REQUEST,
  SAVE_FEATURED_TOURS_SUCCESS,
  GET_SOCIAL_LINKS_FAILURE,
  GET_SOCIAL_LINKS_REQUEST,
  GET_SOCIAL_LINKS_SUCCESS,
  SAVE_SOCIAL_LINKS_FAILURE,
  SAVE_SOCIAL_LINKS_REQUEST,
  SAVE_SOCIAL_LINKS_SUCCESS,
} from '../constants/actionTypes'
import { IFeaturedTours, ISocialLinks } from '../interfaces'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { ThunkDispatch } from 'redux-thunk'
import { AppState } from '../reducers/rootReducer'
import { AnyAction } from 'redux'
import { addNewError } from './ui'

const API_URL = config.API_URL

export const getFeaturedToursRequest = () => ({
  type: GET_FEATURED_TOURS_REQUEST,
})

export const getFeaturedToursSuccess = (featuredTours: IFeaturedTours) => ({
  type: GET_FEATURED_TOURS_SUCCESS,
  featuredTours,
})

export const getFeaturedToursFailure = (error: AxiosError) => ({
  type: GET_FEATURED_TOURS_FAILURE,
  error,
})

export const getFeaturedTours = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(getFeaturedToursRequest())

  axios
    .get(`${API_URL}/featuredTours`)
    .then((res: AxiosResponse<IFeaturedTours>) => {
      const featuredTours = res.data || []
      dispatch(getFeaturedToursSuccess(featuredTours))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(getFeaturedToursFailure(err))
      throw err
    })
}

export const getFeaturedToursIfNeeded = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>,
  getState: () => AppState
) => {
  const { entities } = getState()

  if (!entities.featuredAndSocial.isCached.getFeaturedTours) {
    dispatch(getFeaturedTours())
  }
}

export const saveFeaturedToursRequest = () => ({
  type: SAVE_FEATURED_TOURS_REQUEST,
})

export const saveFeaturedToursSuccess = (featuredTours: IFeaturedTours) => ({
  type: SAVE_FEATURED_TOURS_SUCCESS,
  featuredTours,
})

export const saveFeaturedToursFailure = (error: AxiosError) => ({
  type: SAVE_FEATURED_TOURS_FAILURE,
  error,
})

export const saveFeaturedTours = (featuredTours: IFeaturedTours) => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(saveFeaturedToursRequest())

  axios
    .post(`${API_URL}/featuredTours`, featuredTours)
    .then((res: AxiosResponse<IFeaturedTours>) => {
      dispatch(saveFeaturedToursSuccess(res.data))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(saveFeaturedToursFailure(err))
      throw err
    })
}

// SOCIAL LINKS

export const getSocialLinksRequest = () => ({
  type: GET_SOCIAL_LINKS_REQUEST,
})

export const getSocialLinksSuccess = (socialLinks: ISocialLinks) => ({
  type: GET_SOCIAL_LINKS_SUCCESS,
  socialLinks,
})

export const getSocialLinksFailure = (error: AxiosError) => ({
  type: GET_SOCIAL_LINKS_FAILURE,
  error,
})

export const getSocialLinks = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(getSocialLinksRequest())

  axios
    .get(`${API_URL}/socialLinks`)
    .then((res: AxiosResponse<ISocialLinks>) => {
      const socialLinks = res.data || []
      dispatch(getSocialLinksSuccess(socialLinks))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(getSocialLinksFailure(err))
      throw err
    })
}

export const getSocialLinksIfNeeded = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>,
  getState: () => AppState
) => {
  const { entities } = getState()

  if (!entities.featuredAndSocial.isCached.getSocialLinks) {
    dispatch(getSocialLinks())
  }
}

export const saveSocialLinksRequest = () => ({
  type: SAVE_SOCIAL_LINKS_REQUEST,
})

export const saveSocialLinksSuccess = (socialLinks: ISocialLinks) => ({
  type: SAVE_SOCIAL_LINKS_SUCCESS,
  socialLinks,
})

export const saveSocialLinksFailure = (error: AxiosError) => ({
  type: SAVE_SOCIAL_LINKS_FAILURE,
  error,
})

export const saveSocialLinks = (socialLinks: ISocialLinks) => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(saveSocialLinksRequest())
  axios
    .post(`${API_URL}/socialLinks`, socialLinks)
    .then((res: AxiosResponse<ISocialLinks>) => {
      dispatch(saveSocialLinksSuccess(res.data))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(saveSocialLinksFailure(err))
      throw err
    })
}
