import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, withRouter, RouteComponentProps } from 'react-router'
import { Flex } from 'rebass'

import 'mapbox-gl/dist/mapbox-gl.css'

import { AppState } from '../reducers/rootReducer'
import TopBand from './common/TopBand'

import SideDrawer from './header/SideDrawer'
import Header from './header/Header'
import AppLandingPage from './AppLandingPage'
import Footer from './Footer'
import AboutUs from './AboutUs'
import FAQ from './FAQ'
import ToursLandingPage from './tours/ToursLandingPage'
import TourContainerData from './tours/tour/TourContainerData'
//import TourComplete from './tours/tour/TourComplete'
import Sponsors from './Sponsors'
import RecordsLandingPage from './records/RecordsLandingPage'
import ClassResources from './ClassResources'
import Record from './records/Record'
import Search from './search/Search'

import QRLandingPage from './QRLandingPage'
import QRTourSelectionPage from './QRTourSelectionPage'
import QRLandingPageAudioInterstitial from './QRLandingPageAudioInterstitial'
import QRLandingPageBackToStart from './QRLandingPageBackToStart'

interface IAppProps extends RouteComponentProps {
  isSideDrawerOpen: boolean
  isMapOpen: boolean
}
const App: React.FC<IAppProps> = props => {
  return (
    <>
      {props.isSideDrawerOpen && <SideDrawer />}

      <Flex
        flexDirection={['column']}
        sx={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
      >
        <TopBand></TopBand>

        <div
          style={{
            position: 'relative',
            paddingTop: 15,
            paddingBottom: 15,
            flexGrow: 0,
          }}
        >
          <Header />
        </div>
        <div style={{ position: 'relative', flexGrow: 1 }}>
          <Switch>
            <Route exact={true} path="/" component={AppLandingPage} />
            <Route exact={true} path="/tours" component={ToursLandingPage} />

            <Route path="/lesson-plans" component={ClassResources} />

            {/*
            <Route path="/tours/:tourSlug/tour-complete" component={TourComplete} />
            <Route path="/tours/:tourSlug/v/tour-complete" component={TourComplete} />
            */}

            <Route
              path="/tours/:tourSlug/v/:stopSlug?"
              render={props => (
                <TourContainerData
                  virtual={true}
                  isLanding={false}
                  {...props}
                />
              )}
            />

            <Route
              path="/tours/:tourSlug/:stopSlug?"
              render={props => (
                <TourContainerData
                  virtual={false}
                  isLanding={false}
                  {...props}
                />
              )}
            />

            <Route exact={true} path="/about-us" component={AboutUs} />
            <Route exact={true} path="/faq" component={FAQ} />
            <Route exact={true} path="/our-sponsors" component={Sponsors} />
            <Route
              exact={true}
              path="/records"
              component={RecordsLandingPage}
            />
            <Route exact={true} path="/records/:slug" component={Record} />
            <Route exact={true} path="/search" component={Search} />

            <Route
              path="/landing/walking-tours/tours/:tourSlug/:stopSlug?"
              render={props => (
                <TourContainerData
                  virtual={false}
                  isLanding={true}
                  {...props}
                />
              )}
            />

            <Route
              exact={true}
              path="/landing/walking-tours/back-to-start"
              component={QRLandingPageBackToStart}
            />

            <Route
              exact={true}
              path="/landing/walking-tours/audio-cue"
              component={QRLandingPageAudioInterstitial}
            />

            <Route
              exact={true}
              path="/landing/walking-tours/:recordId"
              component={QRLandingPage}
            />

            <Route
              exact={true}
              path="/landing/walking-tours/:recordId/select-tour"
              component={QRTourSelectionPage}
            />
          </Switch>
        </div>
        <div style={{ position: 'relative', flexGrow: 0 }}>
          <Footer />
        </div>
      </Flex>
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  isSideDrawerOpen: state.ui.isSideDrawerOpen,
  isMapOpen: state.ui.isMapOpen,
})

export default withRouter(connect(mapStateToProps)(App))
