import React from 'react'
import { Box, Text, Flex, Image } from 'rebass'
import { blue } from '../../theme'
import iconAlertBlue from '../../assets/icons/ic_alert_blue.svg'

const BigAlert: React.FC<{}> = () => {
  return (
    <>
      <Box variant="bigAlert.blue">
        <Flex flexDirection={['column', 'row']} alignItems={['center']}>
          <Image
            width={[33, 53]}
            height={[33, 53]}
            display={['none', 'block']}
            mx={[2]}
            src={iconAlertBlue}
          />

          <Text fontWeight="bold" mx={[2]} color={blue} lineHeight="1.3">
            Pro Tip: <br />
            Select our virtual tour option to take a tour from anywhere on any device. 
            It is a great way to preview or take tours if you are not in Nashville.
          </Text>
        </Flex>
      </Box>
    </>
  )
}

export default BigAlert
