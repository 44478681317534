import React, { useEffect, useMemo, useState } from 'react'
import { IEditedItem, IRecord, IStop, ITour } from '../../../interfaces'
import { connect } from 'react-redux'
import { AppState } from '../../../reducers/rootReducer'
import Loading from '../../common/Loading'
import {
  cmsGetRecordsIfNeeded,
  cmsSetEditedStops,
  getStopsIfNeeded,
} from '../../../actions/cms'
import { Box, Flex } from 'rebass'
import withCollapse from '../../common/withCollapse'
import StopEditor from './StopEditor'
import Button from '../../common/Button'
import initNewStop from '../../../helpers/initNewStop'
import reorderArray, { Direction } from '../../../helpers/reorderArray'
import { isStop } from '../helpers/validators'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'

const StopEditorWithCollapse = withCollapse(StopEditor)

interface IStopsEditor {
  tour: Partial<ITour>
  allStops?: Record<string, IStop>
  allRecords?: Record<string, IRecord>
  getStopsIfNeeded: () => void
  cmsGetRecordsIfNeeded: () => void
  isLoading: boolean
  cmsSetEditedStops: (x: IEditedItem<IStop>[]) => void
  editedStops: IEditedItem<IStop>[]
  areAllStopsValid: (bool: boolean) => void
}
const StopsEditor: React.FC<IStopsEditor> = ({
  tour,
  allStops,
  allRecords,
  getStopsIfNeeded: getStops,
  cmsGetRecordsIfNeeded: getRecords,
  isLoading,
  cmsSetEditedStops: setEditedStops,
  editedStops,
  areAllStopsValid,
}) => {
  const [selectedStopId, setSelectedStopId] = useState()
  const { stopIds } = tour
  const allRecordsArray = useMemo(
    () => (allRecords ? Object.values(allRecords) : []),
    [allRecords]
  )

  useEffect(() => {
    getStops()
    getRecords()
  }, [getStops, getRecords])

  // If the stops on the tour changes, replace the stops being edited with those
  // on the tour.
  useEffect(() => {
    if (!stopIds || !stopIds.length || !allStops) {
      return
    }

    const stopsForTour: IEditedItem<IStop>[] = stopIds
      .map(stopId => {
        const stop = allStops[stopId] || null
        return { item: stop, meta: { isNew: false } }
      })
      .filter(s => !!s.item)

    setEditedStops(stopsForTour)
  }, [stopIds, allStops, setEditedStops])

  const handleReorderStops = (
    fromIndex: number,
    direction: Direction
  ) => () => {
    setEditedStops(reorderArray(editedStops, fromIndex, direction))
  }

  const handleEditStop = (editedStop: IEditedItem<IStop>) => {
    const newStops = editedStops.map(s =>
      s.item.id === editedStop.item.id ? editedStop : s
    )

    setEditedStops(newStops)
  }

  const handleAddStop = () => {
    setEditedStops([
      ...editedStops,
      // There will always be a tour id here. TypeScript just doesn't know it.
      { item: initNewStop(tour.id || ''), meta: { isNew: true } },
    ])
  }

  const handleDeleteStop = (stopToDelete: IEditedItem<IStop>) => {
    setEditedStops(
      editedStops.filter(stop => stop.item.id !== stopToDelete.item.id)
    )
  }

  areAllStopsValid(editedStops.every(editedStop => isStop(editedStop.item)))

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {editedStops.map((editedStop: IEditedItem<IStop>, i: number) => {
        if (!editedStop.item) {
          return null
        }

        const recordForThisStop = allRecordsArray.find(
          record => record.id === editedStop.item.recordId
        )

        const isOpen = selectedStopId === editedStop.item.id

        return (
          <Flex key={editedStop.item.id}>
            <StopEditorWithCollapse
              flex="1 1 auto"
              editedStop={editedStop}
              record={recordForThisStop}
              onStopEdit={handleEditStop}
              onStopDelete={handleDeleteStop}
              title={editedStop.item.siteName || ''}
              isOpen={isOpen}
              onArrowClick={() => {
                if (isOpen) {
                  setSelectedStopId('')
                } else {
                  setSelectedStopId(editedStop.item.id)
                }
              }}
            />

            <Box
              flex="0 0 auto"
              ml={1}
              sx={{ position: 'relative', top: '-2px' }}
            >
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={handleReorderStops(i, Direction.Up)}
              >
                <ArrowDropUp fontSize="large" />
              </Box>
              <Box
                onClick={handleReorderStops(i, Direction.Down)}
                sx={{
                  cursor: 'pointer',
                  position: 'absolute',
                  bottom: '-10px',
                }}
              >
                <ArrowDropDown fontSize="large" />
              </Box>
            </Box>
          </Flex>
        )
      })}

      <Button mt={4} variant="primary" onClick={handleAddStop} flex="0 0 auto">
        Add Stop
      </Button>
    </>
  )
}

const mapStateToProps = (state: AppState) => ({
  allTours: state.cms.tours.byId,
  allStops: state.cms.stops.byId,
  allRecords: state.cms.records.byId,
  isLoading: state.cms.stops.isLoading,
  editedStops: state.cms.editedStops,
})

const mapDispatchToProps = {
  getStopsIfNeeded,
  cmsGetRecordsIfNeeded,
  cmsSetEditedStops,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StopsEditor)
