import config from '../../config'
import {
  CMS_GET_FEATURED_TOURS_FAILURE,
  CMS_GET_FEATURED_TOURS_REQUEST,
  CMS_GET_FEATURED_TOURS_SUCCESS,
  CMS_SAVE_FEATURED_TOURS_FAILURE,
  CMS_SAVE_FEATURED_TOURS_REQUEST,
  CMS_SAVE_FEATURED_TOURS_SUCCESS,
  CMS_GET_SOCIAL_LINKS_FAILURE,
  CMS_GET_SOCIAL_LINKS_REQUEST,
  CMS_GET_SOCIAL_LINKS_SUCCESS,
  CMS_SAVE_SOCIAL_LINKS_FAILURE,
  CMS_SAVE_SOCIAL_LINKS_REQUEST,
  CMS_SAVE_SOCIAL_LINKS_SUCCESS,
} from '../../constants/actionTypes'
import { IFeaturedTours, ISocialLinks } from '../../interfaces'
import { AxiosError, AxiosResponse } from 'axios'
import cmsAxios from './cmsNet'
import { ThunkDispatch } from 'redux-thunk'
import { AppState } from '../../reducers/rootReducer'
import { AnyAction } from 'redux'
import { addNewError } from '../ui'

const CMS_API_URL = config.CMS_API_URL

export const cmsGetFeaturedToursRequest = () => ({
  type: CMS_GET_FEATURED_TOURS_REQUEST,
})

export const cmsGetFeaturedToursSuccess = (featuredTours: IFeaturedTours) => ({
  type: CMS_GET_FEATURED_TOURS_SUCCESS,
  featuredTours,
})

export const cmsGetFeaturedToursFailure = (error: AxiosError) => ({
  type: CMS_GET_FEATURED_TOURS_FAILURE,
  error,
})

export const cmsGetFeaturedTours = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(cmsGetFeaturedToursRequest())

  cmsAxios
    .get(`${CMS_API_URL}/featuredTours`)
    .then((res: AxiosResponse<IFeaturedTours>) => {
      const featuredTours = res.data || []
      dispatch(cmsGetFeaturedToursSuccess(featuredTours))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(cmsGetFeaturedToursFailure(err))
    })
}

export const cmsGetFeaturedToursIfNeeded = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>,
  getState: () => AppState
) => {
  console.info('cms', 'cmsFeaturedAndSocial', 'getFeaturedToursIfNeeded')

  const { cms } = getState()

  if (!cms.featuredAndSocial.isCached.getFeaturedTours) {
    dispatch(cmsGetFeaturedTours())
  }
}

export const cmsSaveFeaturedToursRequest = () => ({
  type: CMS_SAVE_FEATURED_TOURS_REQUEST,
})

export const cmsSaveFeaturedToursSuccess = (featuredTours: IFeaturedTours) => ({
  type: CMS_SAVE_FEATURED_TOURS_SUCCESS,
  featuredTours,
})

export const cmsSaveFeaturedToursFailure = (error: AxiosError) => ({
  type: CMS_SAVE_FEATURED_TOURS_FAILURE,
  error,
})

export const cmsSaveFeaturedTours = (featuredTours: IFeaturedTours) => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(cmsSaveFeaturedToursRequest())

  cmsAxios
    .post(`${CMS_API_URL}/featuredTours`, featuredTours)
    .then((res: AxiosResponse<IFeaturedTours>) => {
      dispatch(cmsSaveFeaturedToursSuccess(res.data))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(cmsSaveFeaturedToursFailure(err))
      throw err
    })
}

// SOCIAL LINKS

export const cmsGetSocialLinksRequest = () => ({
  type: CMS_GET_SOCIAL_LINKS_REQUEST,
})

export const cmsGetSocialLinksSuccess = (socialLinks: ISocialLinks) => ({
  type: CMS_GET_SOCIAL_LINKS_SUCCESS,
  socialLinks,
})

export const cmsGetSocialLinksFailure = (error: AxiosError) => ({
  type: CMS_GET_SOCIAL_LINKS_FAILURE,
  error,
})

export const cmsGetSocialLinks = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(cmsGetSocialLinksRequest())

  cmsAxios
    .get(`${CMS_API_URL}/socialLinks`)
    .then((res: AxiosResponse<ISocialLinks>) => {
      const socialLinks = res.data || []
      dispatch(cmsGetSocialLinksSuccess(socialLinks))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(cmsGetSocialLinksFailure(err))
      // throw err
    })
}

export const cmsGetSocialLinksIfNeeded = () => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>,
  getState: () => AppState
) => {
  const { cms } = getState()

  if (!cms.featuredAndSocial.isCached.getSocialLinks) {
    dispatch(cmsGetSocialLinks())
  }
}

export const cmsSaveSocialLinksRequest = () => ({
  type: CMS_SAVE_SOCIAL_LINKS_REQUEST,
})

export const cmsSaveSocialLinksSuccess = (socialLinks: ISocialLinks) => ({
  type: CMS_SAVE_SOCIAL_LINKS_SUCCESS,
  socialLinks,
})

export const cmsSaveSocialLinksFailure = (error: AxiosError) => ({
  type: CMS_SAVE_SOCIAL_LINKS_FAILURE,
  error,
})

export const cmsSaveSocialLinks = (socialLinks: ISocialLinks) => (
  dispatch: ThunkDispatch<AppState, null, AnyAction>
) => {
  dispatch(cmsSaveSocialLinksRequest())
  cmsAxios
    .post(`${CMS_API_URL}/socialLinks`, socialLinks)
    .then((res: AxiosResponse<ISocialLinks>) => {
      dispatch(cmsSaveSocialLinksSuccess(res.data))
    })
    .catch((err: AxiosError) => {
      dispatch(addNewError(err))
      dispatch(cmsSaveSocialLinksFailure(err))
      throw err
    })
}
