import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Text, Image, Flex, Box } from 'rebass'
import { connect } from 'react-redux'

import { AppState } from '../reducers/rootReducer'
import { ITour } from '../interfaces/tourInterfaces'

import Button from './common/Button'

import logoHorizontal from '../assets/icons/nashville-sites-logo-horizontal.png'
import guyWalking from '../assets/images/audio-cue-guy-walking.png'

interface IQRLandingPageProps
  extends RouteComponentProps<{
    recordId: string
  }> {
  tour: ITour
}

const QRLandingPage: React.FC<IQRLandingPageProps> = props => {
  const handleOnClick = () => {
    const url = `/landing/walking-tours/tours/${props.tour.slug}`
    props.history.push(url)
  }

  //const recordId = props.match.params.recordId

  return (
    <Flex
      px={[3]}
      pt={[0, 4]}
      pb={[6]}
      mt={[0, 4]}
      mb={[4]}
      flex={['1 1 0']}
      as="main"
      //style={{ position: 'relative' }}
      flexDirection="column"
      justifyContent="center"
    >
      <Box>
        <Image
          display="block"
          mx={'auto'}
          width={[290]}
          maxWidth={'100%'}
          src={logoHorizontal}
        />

        <Image display="block" mx={'auto'} maxWidth={[290]} src={guyWalking} />

        <Text
          fontSize="18px"
          lineHeight="27px"
          mt={[4]}
          mb={[4]}
          textAlign={'center'}
        >
          You can use your phone's speakers or headphones to listen to the audio
          featured on the tours.
        </Text>

        <Button
          variant="primary"
          mt={[4]}
          display="block"
          height="64px"
          width={['100%']}
          onClick={handleOnClick}
        >
          Continue
        </Button>
      </Box>
    </Flex>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    tour: state.landingPages.selectedTour,
  }
}

export default connect(mapStateToProps, null)(QRLandingPage)
