import React, { ComponentType, useState } from 'react'
import { Box, Flex } from 'rebass'
import styled from 'styled-components'
import Button from './Button'

const StyledBox = styled(Box)`
  height: 175px;
  overflow: hidden;
  position: relative;
`

const MaskingBox = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  ); /* Standard syntax (must be last) */
  z-index: 1;
`

const StyledButton = styled(Button)`
  z-index: 2;
`

export default (
  WrappedComponent: ComponentType,
  onOpen?: () => void
): ComponentType => props => {
  const [isViewMore, setIsViewMore] = useState(false)

  const handleClick = () => {
    setIsViewMore(!isViewMore)

    // If isViewMore is being toggled on, run the onOpen function
    if (!isViewMore && onOpen) {
      onOpen()
    }
  }

  return (
    <Flex flexDirection="column">
      {isViewMore ? (
        <WrappedComponent {...props} />
      ) : (
        <StyledBox>
          <MaskingBox />
          <WrappedComponent {...props} />
        </StyledBox>
      )}
      <StyledButton
        alignSelf="center"
        mt={isViewMore ? 2 : -4}
        onClick={handleClick}
        variant="outline"
        color="blue"
      >
        View {isViewMore ? 'Less' : 'More'}
      </StyledButton>
    </Flex>
  )
}
