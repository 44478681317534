import React from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import { Box, Text } from 'rebass'
import { TextField } from '@material-ui/core'

import { logIn } from '../../actions/cms/session'

import store from '../../store'
import history from '../../history'
import Button from '../common/Button'

const signIn = (email: string, password: string) => {
  return firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(credential => {
      console.info('cms', 'LogIn', 'user signed in', credential)
    })
    .catch(err => {
      console.error('user failed to sign in', err)
      throw err
    })
}

class LogIn extends React.Component<
  {},
  { email: string; password: string; errorMessage: string | null }
> {
  constructor(props: any) {
    super(props)

    this.state = {
      email: '',
      password: '',
      errorMessage: null,
    }
  }

  onSubmit(email: string, password: string) {
    signIn(email, password)
      .then(() => {
        this.setState({
          email: '',
          password: '',
          errorMessage: null,
        })

        store.dispatch(logIn())
        history.push('/admin/home')
      })
      .catch(err => {
        this.setState({
          errorMessage: err.message,
        })
      })
  }

  onEmailChange(email: string) {
    this.setState({
      email,
    })
  }

  onPasswordChange(password: string) {
    this.setState({
      password,
    })
  }

  render() {
    return (
      <Box maxWidth={240} margin={'0 auto'}>
        <TextField
          type="email"
          margin="dense"
          variant="outlined"
          fullWidth={true}
          onChange={ev => {
            this.onEmailChange(ev.target.value)
          }}
          value={this.state.email}
        />

        <TextField
          type="password"
          margin="dense"
          variant="outlined"
          fullWidth={true}
          onChange={ev => {
            this.onPasswordChange(ev.target.value)
          }}
          value={this.state.password}
        />

        <Button
          mt={3}
          onClick={() => {
            this.onSubmit(this.state.email, this.state.password)
          }}
        >
          Log In
        </Button>

        {this.state.errorMessage && (
          <Box>
            <Text mt={3}>{this.state.errorMessage}</Text>
          </Box>
        )}
      </Box>
    )
  }
}

export default LogIn
