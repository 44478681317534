import React from 'react'
import { Box } from 'rebass'

import TourStopListItem from './TourStopListItem'
import { IStopWithRecord, ITour } from '../../../interfaces'

interface ITourStopsListProps {
  tour: ITour
  stopsForTour: IStopWithRecord[]
  currentStopId?: string
  isVirtual: boolean
}

const TourStopsList: React.FC<ITourStopsListProps> = props => (
  <Box p={[3]} width={[1]}>
    {props.stopsForTour.map((stop, i: number) => (
      <TourStopListItem
        key={stop.id}
        isVirtual={props.isVirtual}
        numberBadge={i + 1}
        isCurrentStop={props.currentStopId === stop.id}
        tour={props.tour}
        {...stop}
      />
    ))}
  </Box>
)

export default TourStopsList
