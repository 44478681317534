import { AnyAction } from 'redux'
import { IToursState } from '../../interfaces'
import {
  CMS_DELETE_TOUR_FAILURE,
  CMS_DELETE_TOUR_REQUEST,
  CMS_DELETE_TOUR_SUCCESS,
  CMS_GET_TOURS_FAILURE,
  CMS_GET_TOURS_REQUEST,
  CMS_GET_TOURS_SUCCESS,
  CMS_SAVE_TOUR_FAILURE,
  CMS_SAVE_TOUR_REQUEST,
  CMS_SAVE_TOUR_SUCCESS,
} from '../../constants/actionTypes'
import normalizeEntities from '../../helpers/normalizeEntities'
import { normalizeEntitiesWithSlug } from '../../helpers/normalizeEntities'

const initialState: IToursState = {
  byId: {},
  bySlug: {},
  allIds: [],
  isLoading: false,
  isCached: {},
}

export const cmsToursReducer = (
  state = initialState,
  action: AnyAction
): IToursState => {
  switch (action.type) {
    case CMS_GET_TOURS_REQUEST:
      return { ...state, isLoading: true }
    case CMS_GET_TOURS_SUCCESS:
      return {
        ...state,
        ...normalizeEntitiesWithSlug(action.tours),
        isLoading: false,
        isCached: { ...state.isCached, cmsGetTours: true },
      }
    case CMS_GET_TOURS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CMS_SAVE_TOUR_REQUEST:
      return { ...state, isLoading: true }
    case CMS_SAVE_TOUR_SUCCESS:
      const toursAfterPost = [...Object.values(state.byId), action.tour]
      return {
        ...state,
        ...normalizeEntities(toursAfterPost),
        isLoading: false,
      }
    case CMS_SAVE_TOUR_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CMS_DELETE_TOUR_REQUEST:
      return { ...state, isLoading: true }
    case CMS_DELETE_TOUR_SUCCESS:
      const toursAfterDelete = [...Object.values(state.byId)].filter(
        r => r.id !== action.tour.id
      )
      return {
        ...state,
        ...normalizeEntitiesWithSlug(toursAfterDelete),
        isLoading: false,
      }
    case CMS_DELETE_TOUR_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}
