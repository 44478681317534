import React, { useCallback, useState } from 'react'
import AudioUI from './AudioUI'
import ReactAudioPlayer from 'react-audio-player'

interface IAudioProps {
  path: string
}
const Audio: React.FC<IAudioProps> = props => {
  const [audioEl, setAudioEl] = useState<any>(null)

  // useCallback instead of useRef because we need to be able to respond to the
  // ref changing.
  const audioRef = useCallback(node => {
    if (node !== null && node.audioEl) {
      setAudioEl(node.audioEl)
    }
  }, [])

  return (
    <>
      {props.path && (
        <ReactAudioPlayer
          src={props.path}
          controls={false}
          ref={audioRef}
          preload="auto"
        />
      )}

      {audioEl && <AudioUI audioEl={audioEl} />}
    </>
  )
}

export default Audio
