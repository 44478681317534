import React from 'react'
import { Box, Flex, FlexProps } from 'rebass'

interface IBannerProps extends FlexProps {
  type: 'success' | 'error'
  onClose: () => void
}
const Banner: React.FC<IBannerProps> = props => {
  return (
    <Flex
      type={props.type}
      color="white"
      bg={props.type === 'success' ? '#4CB050' : '#d73b36'}
      {...props}
    >
      {props.children}
      <Box
        sx={{
          position: 'absolute',
          top: '16px',
          right: '24px',
          cursor: 'pointer',
        }}
        onClick={props.onClose}
      >
        X
      </Box>
    </Flex>
  )
}

export default Banner
