import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Heading, Image, Flex, Box } from 'rebass'
import { connect } from 'react-redux'

import { AppState } from '../reducers/rootReducer'
import { ITour } from '../interfaces/tourInterfaces'
import { getTours } from '../actions/tours'

import store from '../store'

import Button from './common/Button'
import TourSummary from './common/TourSummary'

import logoHorizontal from '../assets/icons/nashville-sites-logo-horizontal.png'

interface IQRLandingPageProps
  extends RouteComponentProps<{
    recordId: string
  }> {
  tours?: ITour[]
  getTours: () => void
}

const QRLandingPage: React.FC<IQRLandingPageProps> = ({ tours, history }) => {
  const toursLength = tours && tours.length

  const handleOtherToursClick = () => {
    const url = `/tours`
    history.push(url)
  }

  return (
    <Flex
      px={[3]}
      pt={[0, 4]}
      pb={[6]}
      mt={[0, 4]}
      mb={[4]}
      flex={['1 1 0']}
      as="main"
      flexDirection="column"
      justifyContent="center"
    >
      <Box>
        <Image
          display="block"
          mx={'auto'}
          maxWidth={[290]}
          src={logoHorizontal}
        />

        <Heading mt={[4]} mb={[4]} textAlign={'center'}>
          This tour stop is on {`${toursLength}`} tours. Select which tour you
          would like to take.
        </Heading>

        {tours &&
          tours.map(tour => {
            const handleOnClick = () => {
              store.dispatch({
                type: 'SET_SELECTED_TOUR',
                selectedTour: tour,
              })

              const url = `/landing/walking-tours/tours/${tour.slug}`
              history.push(url)
            }

            return (
              <TourSummary
                mb={4}
                tour={tour}
                key={tour.id}
                viewTourClickHandler={handleOnClick}
              />
            )
          })}

        <Button
          onClick={handleOtherToursClick}
          variant="redOutline"
          mt={[4]}
          display="block"
          width={['100%']}
        >
          View Other Tours
        </Button>
      </Box>
    </Flex>
  )
}

const mapStateToProps = (state: AppState) => {
  const tourIds = state.landingPages.walkingTours.tours || null

  const toursById = state.entities.tours.byId || null

  // will be null if toursIds and toursById aren't present
  const tours =
    tourIds &&
    toursById &&
    tourIds.map((tourId: string) => {
      return toursById[tourId]
    })

  return {
    tourIds: state.landingPages.walkingTours.tours,
    tours: tours,
  }
}

const mapDispatchToProps = {
  getTours,
}

export default connect(mapStateToProps, mapDispatchToProps)(QRLandingPage)
