import React, { MouseEvent } from 'react'
import { Box, Flex } from 'rebass'

const stopPropagation = (e: MouseEvent) => {
  e.stopPropagation()
}

const NSModal: React.FC<{ onClose: () => void; closeOnClickOut?: boolean }> = ({
  onClose,
  closeOnClickOut,
  children,
}) => {
  return (
    <>
      <Flex
        onClick={closeOnClickOut ? onClose : undefined}
        p={[0, 4]}
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 4,
          overflow: 'auto',
          justifyContent: 'safe center',
        }}
      >
        <Box
          p={4}
          width={[1, 450]}
          my="auto"
          sx={{
            borderRadius: 2,
            backgroundColor: '#ffffff',
            boxShadow: '3px 3px 10px 0px #333',
          }}
          onClick={stopPropagation}
        >
          {children}
        </Box>
      </Flex>
    </>
  )
}

export default NSModal
