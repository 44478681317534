import React from 'react'
import { Box, Flex, Heading } from 'rebass'
import styled from 'styled-components/macro'
import ArrowDown from '@material-ui/icons/ExpandMore'
import ArrowUp from '@material-ui/icons/ExpandLess'

const StyledArrowDown = styled(ArrowDown)`
  width: 32px;
  height: 32px;
  cursor: pointer;
`
const StyledArrowUp = styled(ArrowUp)`
  width: 32px;
  height: 32px;
  cursor: pointer;
`

const TitleAndControl = styled(Flex)`
  height: 56px;
  border-top: solid 1px #cccccc;
  cursor: pointer;
`

// In an ideal world I would remove the "any" here and make use of this interface.

// interface IWithCollapseConfig {
//   title: string
//   isOpen: boolean
//   onArrowClick: () => void
// }
const withCollapse = (WrappedComponent: any) => (props: any) => {
  const { title, isOpen, onArrowClick } = props
  const handleArrowClick = () => {
    onArrowClick()
  }

  return (
    <Box
      sx={{
        borderBottom: 'solid 1px #cccccc',
        marginBottom: '-1px',
        width: '100%',
      }}
    >
      <TitleAndControl
        px={3}
        justifyContent="space-between"
        alignItems="center"
        onClick={handleArrowClick}
        width={['100%']}
      >
        <Heading fontSize={[2]} fontWeight={600}>
          {title}
        </Heading>

        {!isOpen ? <StyledArrowDown /> : <StyledArrowUp />}
      </TitleAndControl>

      {!!isOpen && <WrappedComponent {...props} />}
    </Box>
  )
}

export default withCollapse
