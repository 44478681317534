import { ISelectOption } from '../interfaces'

const formatKeywords = (keywords: string[]): ISelectOption[] => {
  return keywords.map(keyword => ({
    value: keyword,
    label: keyword,
  }))
}

export default formatKeywords
