import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import config from '../../config'
import history from '../../history'

import firebase from 'firebase/app'

const authRefreshInterceptorOptions = {
  statusCodes: [401, 403], // our api returns 403
}

const refreshFirebaseToken = async (originalRequest: any) => {
  console.info(
    'cmsNet',
    'refreshFirebaseToken',
    'original request',
    originalRequest
  )

  const password = prompt('Session expired. Please enter password.') // eslint-disable-line no-restricted-globals

  const currentUser = firebase.auth().currentUser
  const email = currentUser && currentUser.email

  if (currentUser && email && password) {
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    )

    // collect the user's password
    // reauthenticate with firebase
    // get the new JWT and store it
    return currentUser
      .reauthenticateWithCredential(credential)
      .then(newCredential => {
        console.info('cmsNet', 'refreshFirebaseToken', 'user authenticated')
        return (
          newCredential && newCredential.user && newCredential.user.getIdToken()
        )
      })

      .then(idToken => {
        console.info('cmsNet', 'refreshFirebaseToken', 'new token fetched')
        window.localStorage.setItem('userToken', JSON.stringify(idToken))
        originalRequest.config.headers['x-firebase-token'] = idToken
      })

      .catch(error => {
        console.error(
          'cmsNet',
          'refreshFirebaseToken',
          'user authentication failed',
          error
        )
        window.localStorage.removeItem('userToken')
      })
  } else {
    return Promise.reject()
  }
}

const cmsAxios = axios.create({})

if (config.USE_INTERCEPTOR_TO_REFRESH_TOKEN) {
  console.info('USE_INTERCEPTOR_TO_REFRESH_TOKEN === true')
  createAuthRefreshInterceptor(
    cmsAxios,
    refreshFirebaseToken,
    authRefreshInterceptorOptions
  )
}

// firebase provides a syncrhonous way to get the current user
// this is unreliable as the initilization phase for the user may not be complete
// so we have to do this goofy shit
function getCurrentUser(): Promise<any> {
  return new Promise((resolve, reject) => {
    const currentUser = firebase.auth().currentUser

    if (currentUser) {
      resolve(currentUser)
    } else {
      const unsubscribe = firebase.auth().onAuthStateChanged(user => {
        unsubscribe()
        resolve(user)
      }, reject)
    }
  })
}

// all CMS network IO requires a token from a signed in user
cmsAxios.interceptors.request.use(config => {
  return new Promise((res, rej) => {
    getCurrentUser()
      .then(currentUser => {
        if (!!currentUser) {
          return currentUser
        } else {
          console.warn('currentUser is null, seems like nobody is logged in')
          rej(new Error('No logged in user. Please log in to continue.'))
        }
      })
      .then(currentUser => currentUser.getIdToken())
      .then(idToken => {
        console.info(
          'cmsNet',
          'cmsAxios.interceptors.request',
          'getIdToken.then',
          idToken
        )

        config.headers.common['x-firebase-token'] = idToken
        res(config)
      })
      .catch(error => {
        console.error(
          'cmsNet',
          'cmsAxios.interceptors.request',
          'getIdToken.then',
          error
        )

        history.push('/admin/login')
        rej(error)
      })
  })
})

export default cmsAxios
