import { IQRCode } from '../interfaces'
import uuid from 'uuid'

export default (): Partial<IQRCode> => {
  return {
    id: uuid.v4(),
    redirectUrl: '',
    locationName: '',
    locationDescription: '',
  }
}
