import { AnyAction } from 'redux'
import { IRecord, IRecordsState } from '../../interfaces'
import {
  CMS_DELETE_RECORD_FAILURE,
  CMS_DELETE_RECORD_REQUEST,
  CMS_DELETE_RECORD_SUCCESS,
  CMS_GET_RECORDS_FAILURE,
  CMS_GET_RECORDS_REQUEST,
  CMS_GET_RECORDS_SUCCESS,
  CMS_SAVE_RECORD_FAILURE,
  CMS_SAVE_RECORD_REQUEST,
  CMS_SAVE_RECORD_SUCCESS,
  CMS_SET_SELECTED_RECORD_TYPE,
} from '../../constants/actionTypes'
import normalizeEntities from '../../helpers/normalizeEntities'
import { compileOptionsFromRecords } from '../../helpers/compileSelectOptions'

const initialState: IRecordsState = {
  byId: {},
  bySlug: {},
  byStopId: {},
  allIds: [],
  isLoading: false,
  isCached: {},
  options: null,
  selectedRecordType: 'All',
}

export const cmsRecordsReducer = (
  state = initialState,
  action: AnyAction
): IRecordsState => {
  switch (action.type) {
    case CMS_GET_RECORDS_REQUEST:
      return { ...state, isLoading: true }
    case CMS_GET_RECORDS_SUCCESS:
      const normalizedEntities = normalizeEntities(action.records as IRecord[])
      return {
        ...state,
        ...normalizedEntities,
        byStopId: {
          ...state.byStopId,
          ...{ [action.stopId]: action.tours },
        },
        options: compileOptionsFromRecords(normalizedEntities.byId),
        isLoading: false,
        isCached: { ...state.isCached, cmsGetRecords: true },
      }
    case CMS_GET_RECORDS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CMS_SAVE_RECORD_REQUEST:
      return { ...state, isLoading: true }
    case CMS_SAVE_RECORD_SUCCESS:
      const newRecordsById = { ...state.byId }

      // Add or update the record that was saved
      newRecordsById[action.record.id] = action.record

      const normalizedRecordsAfterSave = normalizeEntities([
        ...Object.values(newRecordsById),
      ])

      return {
        ...state,
        ...normalizedRecordsAfterSave,
        options: compileOptionsFromRecords(normalizedRecordsAfterSave.byId),
        isLoading: false,
      }
    case CMS_SAVE_RECORD_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CMS_DELETE_RECORD_REQUEST:
      return { ...state, isLoading: true }
    case CMS_DELETE_RECORD_SUCCESS:
      const recordsAfterDelete = [...Object.values(state.byId)].filter(
        r => r.id !== action.record.id
      )
      return {
        ...state,
        ...normalizeEntities(recordsAfterDelete),
        isLoading: false,
      }
    case CMS_DELETE_RECORD_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CMS_SET_SELECTED_RECORD_TYPE:
      return {
        ...state,
        selectedRecordType: action.recordType,
      }
    default:
      return state
  }
}
